import React, { useState } from 'react';
import "./Dropdown.scss";

const Dropdown = ({ value, options, onChange, placeholder, icon }) => {

    const [isOpened, setIsOpened] = useState(false);

    React.useEffect(() => {
        window.addEventListener("click", (e) => {
            const dropdown = document.getElementById("dropdown");
            if((dropdown && e) && !dropdown.contains(e.target)) {
                setIsOpened(false);
            }
        });
    }, []);

    const current_value = () => {
        const selected_option = options.find(option => option.value === value);
        return selected_option && selected_option.label ? selected_option.label : "";
    }

    const handleOptionClick = (value) => {
        onChange(value);
        setIsOpened(false);
    }

    const isOptionSelectedClass = (option) => {
        return value === option ? "selected" : "";
    }

    return (
        <div className={`dropdown ${isOpened ? "opened" : ""}`} id="dropdown">
            <div className="dropdown-top"
                 onClick={() => setIsOpened(!isOpened)}>
                {/*{ current_value() || <span className="dropdown-placeholder">{ placeholder }</span> }*/}
                {icon && icon}
                <span className="dropdown-placeholder">{ placeholder }</span>
            </div>
            <div className="dropdown-options">
                {options.map(option => (
                    <div className={`dropdown-option ${isOptionSelectedClass(option.value)}`}
                         onClick={() => handleOptionClick(option.value)}>
                        { option.label }
                    </div>
                ))}
            </div>

        </div>
    );
}

export default Dropdown;
