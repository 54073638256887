import React from "react";
import styles from "./PostRow.module.scss";
import {ReactComponent as EditIcon} from "../../../assets/icons/edit.svg";
import {ReactComponent as DeleteIcon} from "../../../assets/icons/trash.svg";
import {BLANK_IMAGE} from "../../../utils/constants";
import Button, {ButtonTypes} from "../../Button/Button";
import {formatTime} from "../../../utils";
import {useTranslation} from "react-i18next";

const ProductRow = ({post, onEditClick, onDeleteClick}) => {
    const {t} = useTranslation();
    return (
        <div className={styles.postRow}>
            <div className={styles.image}
                 style={{backgroundImage: "url('" + (post.pictureUrl || BLANK_IMAGE) + "')"}}/>
            <div className={styles.content}>
                <p className={styles.title}>{post.title}</p>
                <p className={styles.subtitle}>{formatTime(post.startTime)} - {formatTime(post.endTime)}</p>
            </div>
            <div className={styles.middleContent}>
                {post.description}
            </div>
            <div className={styles.actions}>
                <div data-tip={t("Edit")}>
                    <Button
                        type={ButtonTypes.Icon}
                        onClick={onEditClick}>
                        <EditIcon/>
                    </Button>
                </div>
                <div data-tip={t("Delete")}>
                    <Button
                        icon={<DeleteIcon/>}
                        type={ButtonTypes.Icon}
                        onClick={onDeleteClick}>
                        <DeleteIcon/>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ProductRow;
