import React, {useState} from "react";
import "./Footer.scss";
import {ReactComponent as IconFacebook} from "../../../assets/icons/white/facebook.svg";
import {ReactComponent as IconInstagram} from "../../../assets/icons/white/instagram.svg";
import {ReactComponent as IconLinkedin} from "../../../assets/icons/white/linkedin.svg";
import {useTranslation} from "react-i18next";
import ReactModal from "react-modal";
import Privacy from "../Privacy/Privacy";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import * as PropTypes from "prop-types";

class FontAwesomeIcon extends React.Component {
    render() {
        return null;
    }
}

FontAwesomeIcon.propTypes = {
    icon: PropTypes.any,
    color: PropTypes.string
};
const Footer = () => {
    
    const { t, i18n } = useTranslation();
    const [isModalOpen, setModal] = useState(false);

    const handleClose = () => {
        setModal(false);
    }
    
    return (
        <div className="footer">
            <div className="d-flex justify-content-between">
                <div className="copyrights">
                    <strong>Copyright &copy; {(new Date().getFullYear())} MENYJA.</strong> All rights reserved.
                </div>
                <div className="social">
                    <a href="https://www.facebook.com/Menyjaco-108469744304161"><IconFacebook/></a>
                    <a href="https://www.instagram.com/menyja.co/"><IconInstagram/></a>
                    <a href="https://linkedin.com/company/menyja"><IconLinkedin/></a>
                </div>
            </div>
            <div className="text-center mt-4 privacy-policies-text"
                onClick={() => setModal(true)}>
                <b>Privacy Policies</b>
            </div>

            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={handleClose}
                shouldCloseOnOverlayClick={false}
                className="landing-page-modal"
                overlayClassName="landing-page-modal-overlay">

                <div className="close-landing-page-modal-button"
                    onClick={() => setModal(false)}>
                    &#10006;
                </div>

                <Privacy/>

            </ReactModal>

        </div>
    )
}

export default Footer;
