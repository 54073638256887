import React, {Component} from "react";
import "./Dailies.scss";
import {connect} from "react-redux";
import Modal from "../../../components/Modal/Modal";
import {Link, withRouter} from "react-router-dom";
import {askForConfirmation, editingTitle, getLanguageLabel, isTimeBefore, search} from "../../../utils";
import DragAndDrop, {DraggableTypes} from "../../../components/Draggable/DragAndDrop";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import * as constants from "../../../utils/constants";
import {withTranslation} from "react-i18next";
import {
    activateDailyMenu,
    createDailyMenu,
    deactivateDailyMenu,
    deleteDailyMenu,
    getDailyMenus,
    updateDailyMenu
} from "../../../actions/daily";
import {uploadMediaImage, uploadMediaVideo} from "../../../actions/media";
import moment from "moment";
import {TimePicker} from "antd";
import {setLoading} from "../../../actions";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../../components/Button/Button";
import Input, {InputStyles} from "../../../components/Input/Input";
import {inputValidations} from "../../../utils/validation/configurations";
import {validate} from "../../../utils/validation";
import {DAILY_MENUS_SIDEBAR_CONTENT} from "../../../utils/constants";
import Drawer from "../../../components/Drawer/Drawer";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";

class Dailies extends Component {
    popUpQuestion = "A jeni të sigurt të vazhdoni?";

    constructor(props) {
        super(props);

        this.state = {
            showValidation: false,
            isModalOpen: false,
            editing: false,
            file: null,
            sidebar_opened: false,
            sidebar_content: DAILY_MENUS_SIDEBAR_CONTENT.CREATE_DAILY_MENU,
            search_text: "",
            daily_menu: {
                title: "",
                titleEn: "",
                description: "",
                descriptionEn: "",
                videoUrl: "",
                pictureUrl: "",
                totalPrice: "",
                startTime: moment("2020-10-10 00:00:00").format("HH:mm") + ":00",
                endTime: moment("2020-10-10 00:00:00").format("HH:mm") + ":00"
            }
        }
    }

    componentDidMount() {
        this.props.getDailyMenus();
    }

    openModal = () => {
        this.setState({isModalOpen: true});
    }

    closeModal = () => {
        this.setState({
            showValidation: false,
            isModalOpen: false,
            editing: false,
            file: null,
            sidebar_opened: false,
            daily_menu: constants.EMPTY_DAILY_MENU_OBJECT
        });
    }

    clearDailyMenu = () => {
        this.setState({
            daily_menu: constants.EMPTY_DAILY_MENU_OBJECT
        })
    }

    validateTime = () => {
        if (isTimeBefore(this.state.daily_menu.endTime, this.state.daily_menu.startTime)) {
            alert("Please enter correct dates");
            return false;
        } else {
            return true
        }
    }

    handleMediaUpload = async (file) => {
        const type = file.type.split("/")[0];
        if (type === "image") {
            return this.props.uploadMediaImage(file).then(res => {
                return {
                    pictureUrl: res.data,
                    videoUrl: null
                }
            })
        } else {
            return this.props.uploadMediaVideo(file).then(res => {
                return {
                    pictureUrl: null,
                    videoUrl: res.data
                }
            })
        }
    }

    handleCreateDailyMenu = async() => {
        const {t} = this.props;
        let {daily_menu, file} = this.state;

        if(this.formIsInvalid()) {
            this.setState({showValidation: true});
            return;
        }

        if (!this.validateTime()) {
            return;
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        if (file) {
            this.handleMediaUpload(file).then(res => {
                daily_menu = {...daily_menu, ...res};
                this.props.createDailyMenu(daily_menu).then(res => {
                    this.props.getDailyMenus();
                    this.closeModal();
                    this.props.setLoading(false);
                });
            })
        } else {
            this.props.createDailyMenu(daily_menu).then(res => {
                this.props.getDailyMenus();
                this.closeModal();
                this.props.setLoading(false);
            });
        }

    }

    handleUpdateDailyMenu = async() => {
        const {t} = this.props;
        let {daily_menu, file} = this.state;

        if(this.formIsInvalid()) {
            this.setState({showValidation: true});
            return;
        }

        if (!this.validateTime()) {
            return;
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        if (file) {
            this.handleMediaUpload(file).then(res => {
                daily_menu = {...daily_menu, ...res};
                this.props.updateDailyMenu(daily_menu.id, daily_menu).then(res => {
                    this.props.getDailyMenus();
                    this.closeModal();
                    this.props.setLoading(false);
                });
            })
        } else {
            this.props.updateDailyMenu(daily_menu.id, daily_menu).then(res => {
                this.props.getDailyMenus();
                this.closeModal();
                this.props.setLoading(false);
            });
        }
    }

    openDailyMenu = (daily_menu) => {
        this.props.history.push("/daily/" + daily_menu.id + "/products");
    }

    onClickEdit = (item, e) => {
        e.stopPropagation();
        this.setState({editing: true, daily_menu: item});
        this.openModal();
    }

    onClickDelete = async(item, e) => {
        e.stopPropagation();
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.deleteDailyMenu(item.id).then(res => {
            this.props.getDailyMenus();
            this.props.setLoading(false);
        });
    }

    onClickActivate = async(item, e) => {
        e.stopPropagation();
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.activateDailyMenu(item).then(res => {
            this.props.getDailyMenus()
            this.props.setLoading(false);
        })
    }

    onClickDeactivate = async(item, e) => {
        console.log(item);
        e.stopPropagation();
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.deactivateDailyMenu(item).then(res => {
            this.props.getDailyMenus();
            this.props.setLoading(false);
        })
    }

    formIsInvalid = () => {
        const {daily_menu} = this.state;
        return validate(inputValidations.dailyTitleInput, daily_menu.title) ||
                validate(inputValidations.dailyTitleEnInput, daily_menu.titleEn) ||
                validate(inputValidations.dailyPriceInput, daily_menu.totalPrice) ||
                validate(inputValidations.dailyStartTimeInput, daily_menu.startTime) ||
                validate(inputValidations.dailyEndTimeInput, daily_menu.endTime) ||
                validate(inputValidations.dailyDescriptionInput, daily_menu.description) ||
                validate(inputValidations.dailyDescriptionEnInput, daily_menu.descriptionEn);
    }

    openCreateDailyMenuSidebar = () => {
        this.clearDailyMenu()
        this.setState({
            editing: false,
            sidebar_content: DAILY_MENUS_SIDEBAR_CONTENT.CREATE_DAILY_MENU,
            sidebar_opened: true,
        })
    }

    openEditDailyMenuSidebar = (daily_menu, e) => {
        e.stopPropagation();
        this.clearDailyMenu()
        this.setState({
            editing: true,
            daily_menu: daily_menu,
            sidebar_content: DAILY_MENUS_SIDEBAR_CONTENT.EDIT_DAILY_MENU,
            sidebar_opened: true,
        })
    }

    render() {
        const {isModalOpen, daily_menu, editing, file, showValidation, sidebar_content, sidebar_opened, search_text} = this.state;
        const {daily_menus_fetched, t, user_details} = this.props;
        const daily_menus = search(this.props.daily_menus, search_text, "title");

        return (
            <div className="dailies-page">

                <div className="page-header-row">
                    <h1>{t("Daily menus")}</h1>
                    <div className="d-flex">
                        <Button
                            color={ButtonColors.Green}
                            onClick={() => this.openCreateDailyMenuSidebar()}>
                            + {t("Create Daily Menu")}
                        </Button>
                    </div>
                </div>

                <div className="page-search-row">
                    <Input
                        type="text"
                        id="searchTablesInput"
                        name="searchTablesInput"
                        placeholder={t("Search for daily menus")}
                        value={search_text}
                        onChange={(e) => this.setState({search_text: e.target.value})}/>
                </div>

                {daily_menus_fetched ?
                    daily_menus.length > 0 ?
                        <DragAndDrop
                            isDragDisabled
                            items={daily_menus}
                            type={DraggableTypes.MENU}
                            onClickItem={(item, e) => this.openDailyMenu(item, e)}
                            onClickEdit={(item, e) => this.openEditDailyMenuSidebar(item, e)}
                            onClickActivate={(item, e) => this.onClickActivate(item, e)}
                            onClickDeactivate={(item, e) => this.onClickDeactivate(item, e)}
                            onClickDelete={(item, e) => this.onClickDelete(item, e)}
                            onDragEnd={(result) => this.onDragEnd(result)}/> : "No daily menus..."

                    : <div className="text-center py-3">
                        <div className="spinner-border text-info"/>
                    </div>}

                <Drawer
                    open={sidebar_opened}>
                    <div className="d-flex justify-content-between mb-16">
                        <h2>{editing ? t("Edit Daily Menu") : t("Create Daily Menu")}</h2>
                        <div className="d-flex">
                            <Button
                                type={ButtonTypes.Icon}
                                onClick={this.closeModal}>
                                <CloseIcon/>
                            </Button>
                        </div>
                    </div>
                    <Input
                        label={`${t("Title")} (${t("Primary language")})`}
                        id="dailyTitleInput"
                        name="dailyTitleInput"
                        value={daily_menu.title}
                        style={InputStyles.Material}
                        validations={[inputValidations.dailyTitleInput, showValidation]}
                        onChange={(e) => this.setState({daily_menu: {...daily_menu, title: e.target.value}})}/>

                    <Input
                        label={`${t("Title")} (${t("Secondary language")})`}
                        id="dailyTitleInputEn"
                        name="dailyTitleInputEn"
                        value={daily_menu.titleEn}
                        style={InputStyles.Material}
                        validations={[inputValidations.dailyTitleEnInput, showValidation]}
                        onChange={(e) => this.setState({daily_menu: {...daily_menu, titleEn: e.target.value}})}/>

                    <Input
                        label={t("Price")}
                        id="dailyPriceInput"
                        name="dailyPriceInput"
                        value={daily_menu.totalPrice}
                        style={InputStyles.Material}
                        validations={[inputValidations.dailyPriceInput, showValidation]}
                        onChange={(e) => this.setState({daily_menu: {...daily_menu, totalPrice: e.target.value}})}/>

                    <Input
                        type="time"
                        label={t("Start time")}
                        id="dailyStartTimeInput"
                        name="dailyStartTimeInput"
                        value={daily_menu.startTime}
                        style={InputStyles.Material}
                        validations={[inputValidations.dailyStartTimeInput, showValidation]}
                        onChange={(time) => this.setState({daily_menu: {...daily_menu, startTime: time}})}/>

                    <Input
                        type="time"
                        label={t("End time")}
                        id="dailyEndTimeInput"
                        name="dailyEndTimeInput"
                        value={daily_menu.endTime}
                        style={InputStyles.Material}
                        validations={[inputValidations.dailyEndTimeInput, showValidation]}
                        onChange={(time) => this.setState({daily_menu: {...daily_menu, endTime: time}})}/>

                    <Input
                        type="file"
                        label={t("Photo or Video")}
                        id="dailyImageInput"
                        name="dailyImageInput"
                        value={file}
                        style={InputStyles.Material}
                        validations={[inputValidations.dailyImageInput, showValidation]}
                        onChange={(e) => this.setState({file: e.target.files[0] ? e.target.files[0] : null})}/>

                    <Input
                        type="textarea"
                        label={`${t("Description")} (${t("Primary language")})`}
                        id="dailyDescriptionInput"
                        name="dailyDescriptionInput"
                        value={daily_menu.description}
                        style={InputStyles.Material}
                        validations={[inputValidations.dailyDescriptionInput, showValidation]}
                        onChange={(e) => this.setState({daily_menu: {...daily_menu, description: e.target.value}})}/>

                    <Input
                        type="textarea"
                        label={`${t("Description")} (${t("Secondary language")})`}
                        id="dailyDescriptionInput"
                        name="dailyDescriptionInput"
                        value={daily_menu.descriptionEn}
                        style={InputStyles.Material}
                        validations={[inputValidations.dailyDescriptionEnInput, showValidation]}
                        onChange={(e) => this.setState({daily_menu: {...daily_menu, descriptionEn: e.target.value}})}/>

                    <Button
                        className="mt-24"
                        size={ButtonSizes.Full}
                        color={ButtonColors.Green}
                        type={ButtonTypes.Filled}
                        onClick={() => editing ? this.handleUpdateDailyMenu() : this.handleCreateDailyMenu()}>
                        {editing ? t("Edit Daily Menu") : t("Create Daily Menu")}
                    </Button>

                </Drawer>

            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        daily_menus: state.app.daily_menus,
        daily_menus_fetched: state.app.daily_menus_fetched,
        user_details: state.app.user_details,

    }
}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    getDailyMenus: (data) => dispatch(getDailyMenus(data)),
    createDailyMenu: (data) => dispatch(createDailyMenu(data)),
    updateDailyMenu: (dailyMenuId, data) => dispatch(updateDailyMenu(dailyMenuId, data)),
    deleteDailyMenu: (dailyMenuId) => dispatch(deleteDailyMenu(dailyMenuId)),
    activateDailyMenu: (data) => dispatch(activateDailyMenu(data)),
    deactivateDailyMenu: (data) => dispatch(deactivateDailyMenu(data)),
    uploadMediaImage: (data) => dispatch(uploadMediaImage(data)),
    uploadMediaVideo: (data) => dispatch(uploadMediaVideo(data)),

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dailies)));
