import React, {Component} from "react";
import styles from "./Orders.module.scss";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import DragAndDrop, {DraggableTypes} from "../../../components/Draggable/DragAndDrop";
import {withTranslation} from "react-i18next";
import Input from "../../../components/Input/Input";
import Drawer from "../../../components/Drawer/Drawer";
import {getOrders} from "../../../actions/orders";
import OrderDetailsSidebar from "../../../components/Sidebars/OrderDetailsSidebar/OrderDetailsSidebar";
import Button, {ButtonColors} from "../../../components/Button/Button";
import {search} from "../../../utils";
import Empty from "../../../components/Empty/Empty";

class Orders extends Component {
    popUpQuestion = "A jeni të sigurt të vazhdoni?";

    constructor(props) {
        super(props);

        this.state = {
            order: null,
            isSidebarOpen: false
        }
    }

    componentDidMount() {
        this.props.getOrders();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {user_details} = this.props;
        if(user_details && user_details.configuration.enableOrder === false) {
            this.props.history.push("/menus");
        }
    }

    openSidebar = () => {
        this.setState({isSidebarOpen: true});
    }

    closeSidebar = () => {
        this.setState({isSidebarOpen: false});
    }

    openOrder = (order) => {
        this.setState({
            order: order,
            isSidebarOpen: true,
        })
    }


    render() {
        const {search_text, isSidebarOpen, order} = this.state;
        const {orders_fetched, t} = this.props;
        const orders = search(this.props.orders, search_text, "orderNumber");

        return (
            <div className="orders-page">

                <div className="page-header-row">
                    <h1>{t("Orders")}</h1>
                </div>

                <div className="page-search-row">
                        <Input
                            type="text"
                            id="searchTablesInput"
                            name="searchTablesInput"
                            placeholder={t("Search for orders")}
                            value={search_text}
                            onChange={(e) => this.setState({search_text: e.target.value})}/>
                </div>

                <div className={styles.listHeader}>
                    <div className={styles.column}>
                        {t("Order ID")}
                    </div>
                    <div className={styles.column}>
                        {t("Table")}
                    </div>
                    <div className={styles.column}>
                        {t("Date and time")}
                    </div>
                    <div className={styles.column}>
                        {t("Status")}
                    </div>
                    <div className={styles.column}>
                        {t("Total items")}
                    </div>
                    <div className={styles.column}>
                        {t("Total price")}
                    </div>
                </div>
                <div className="box-body">

                    {orders_fetched ?
                        <>
                            {orders.length > 0 ?
                                <DragAndDrop
                                    isDragDisabled
                                    items={orders}
                                    type={DraggableTypes.ORDER}
                                    onClickItem={(item, e) => this.openOrder(item, e)}
                                    onDragEnd={(result) => this.onDragEnd(result)}/>
                                    : <div className="mt-94 text-center">
                                            <Empty />
                                            <p className="mt-16">{t("No orders to display!")}</p>
                                        </div>}
                        </>
                        : <div className="text-center py-3">
                            <div className="spinner-border text-info"/>
                        </div>
                    }

                </div>

                <Drawer
                    open={isSidebarOpen}>
                    {order &&
                    <OrderDetailsSidebar
                        completed
                        order={order}
                        onCloseButtonClick={() => this.closeSidebar()}/>}
                </Drawer>

            </div>
        );
    }

}

const mapStateToProps = state => ({
    orders: state.app.orders,
    orders_fetched: state.app.orders_fetched,
    user_details: state.app.user_details
})

const mapDispatchToProps = dispatch => ({
    getOrders: () => dispatch(getOrders())
});
const translation = withTranslation();
const redux = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(redux(translation(Orders)));
