import React from "react";
import styles from "./OrderRow.module.scss";
import {ReactComponent as CalendarIcon} from "../../../assets/icons/date.svg";
import {ReactComponent as ClockIcon} from "../../../assets/icons/time.svg";
import moment from "moment";
import Status, {StatusTypes} from "../../Status/Status";
import {calculateTotal} from "../../../utils";

const OrderStatusTypes = {
    APPROVED: StatusTypes.APPROVED,
    DECLINED: StatusTypes.REJECTED,
    SENT: StatusTypes.PENDING
}

const OrderRow = ({order}) => {
    return (
        <div className={styles.orderRow}>
            <div className={styles.column}>
                <p>#{order.orderNumber.substr(order.orderNumber.length - 5)}</p>
            </div>
            <div className={styles.column}>
                <p>{order.table}</p>
            </div>
            <div className={styles.column}>
                <p className="d-flex align-items-center">
                    <CalendarIcon className="mr-2" /> {moment.utc(order.createdDateTime).local().format("DD/MM/YYYY")}
                    <ClockIcon className="ml-16 mr-2"/> {moment.utc(order.createdDateTime).local().format("hh:mm")}
                </p>
            </div>
            <div className={styles.column}>
                <Status type={OrderStatusTypes[order.status]} />
            </div>
            <div className={styles.column}>
                <p className="pl-2">{order.orderItems.length}</p>
            </div>
            <div className={styles.column}>
                <p className="pl-2">{calculateTotal(order)}€</p>
            </div>
        </div>
    )
}

export default OrderRow;
