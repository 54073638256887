import React from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

export const ButtonTypes = {
    Filled: "filled",
    Outline: "outline",
    Add: "add",
    Icon: "icon",
}

export const ButtonColors = {
    Green: "green",
    Red: "red",
    Gray: "gray",
    White: "white",
}

export const ButtonSizes = {
    Auto: "auto",
    Full: "full",
    Small: "small"
}

export default function Button({type, color, size, className, content, disabled, onClick, loading, children}) {

    const classes = classNames(styles.button, className,
        {[styles.icon]: type === ButtonTypes.Icon},
        {[styles.green]: color === ButtonColors.Green},
        {[styles.red]: color === ButtonColors.Red},
        {[styles.white]: color === ButtonColors.White},
        {[styles.gray]: color === ButtonColors.Gray},
        {[styles.auto]: size === ButtonSizes.Auto},
        {[styles.full]: size === ButtonSizes.Full},
        {[styles.small]: size === ButtonSizes.Small},
        {[styles.disabled]: disabled},
    );

    return (
        <button
            type="button"
            onClick={onClick}
            className={classes}
            disabled={disabled === true}>
            {loading ? <div className="spinner-border text-white"/> : children}

            {type === ButtonTypes.Add && <FontAwesomeIcon icon={faPlus} color="#26C165" />}
        </button>
    )

}
