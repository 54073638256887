import React, {Component} from "react";
import "./Modal.scss";
import ReactModal from 'react-modal';
import {withTranslation} from "react-i18next";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button, {ButtonColors, ButtonTypes} from "../Button/Button";
import classNames from "classnames";

ReactModal.setAppElement("#root");

class Modal extends Component {
    componentDidMount() {
        //
    }

    render() {
        const {title, children, isModalOpen, handleClose, handleCancel, handleSubmit, wide, video, noFooter, t} = this.props;
        return (
            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={handleClose}
                shouldCloseOnOverlayClick={false}
                className={classNames("custom-modal", {"wide-modal": wide, "video-modal": video})}
                overlayClassName="custom-modal-overlay">

                <div className="modal-container">
                    <div className="modal-header">
                        <div className="modal-title">
                            {title}
                        </div>
                        <button className="modal-close-button"
                                onClick={handleClose}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </button>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    {noFooter !== true &&
                    <div className="modal-footer">
                        <Button
                            type={ButtonTypes.Filled}
                            color={ButtonColors.Red}
                            content={t("Close")}
                            onClick={handleCancel || handleClose}/>
                        <Button
                            type={ButtonTypes.Filled}
                            color={ButtonColors.Primary}
                            content={t("Save")}
                            onClick={handleSubmit}/>
                    </div>}
                </div>

            </ReactModal>
        );
    }
}

export default withTranslation()(Modal);

