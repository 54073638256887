import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../Button/Button";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";
import {productExists, search} from "../../../utils";
import {setLoading} from "../../../actions";
import {createTable, getTables} from "../../../actions/tables";
import {connect} from "react-redux";
import Input, {InputStyles} from "../../Input/Input";
import ProductCard from "../../Cards/ProductCard/ProductCard";
import styles from "./AddProductsSidebar.module.scss";

class AddProductsSidebar extends Component {

    state = {
        showValidation: false,
        search_text: "",
        selected_products: []
    }

    componentDidMount() {
        //
    }


    handleSelectProduct = e => {
        const value = parseInt(e.target.value);
        const checked = e.target.checked;
        let {selected_products} = this.state;
        if (checked && !selected_products.includes(value)) {
            selected_products.push(value);
        } else if (!checked && selected_products.includes(value)) {
            selected_products.splice(selected_products.indexOf(value), 1);
        }
        this.setState({selected_products});
    }

    render() {
        const {t, submenuProducts} = this.props;
        const {search_text, selected_products} = this.state;
        const products = search(this.props.products, search_text, "name");
        return (
            <div>
                <div className="d-flex justify-content-between mb-16">
                    <h2>{t("Add Products")}</h2>
                    <div className="d-flex">
                        <Button
                            type={ButtonTypes.Icon}
                            onClick={this.props.onCloseButtonClick}>
                            <CloseIcon/>
                        </Button>
                    </div>
                </div>
                <div className={styles.selectProductsList}>
                    <Input
                        autoComplete="off"
                        label={t("Search")}
                        value={search_text}
                        id="search-input"
                        name="search-input"
                        style={InputStyles.Material}
                        onChange={(e) => this.setState({search_text: e.target.value})}/>

                    <div className="">
                        {products.map((product, index) => (
                            !productExists(submenuProducts, product.id) &&
                            <div className="add-products-list-item py-1 pl-2 pr-3"
                                key={`products-list-${product.id}`}>
                                <div className="row">
                                    <div className="col-1">
                                        <input type="checkbox"
                                               className="h-100"
                                               id={"product-" + index}
                                               value={product.id}
                                               checked={selected_products.includes(product.id)}
                                               onChange={(e) => this.handleSelectProduct(e)}/>
                                    </div>
                                    <div className="col">
                                        <ProductCard product={product} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <Button
                    className="mt-24"
                    size={ButtonSizes.Full}
                    color={ButtonColors.Green}
                    type={ButtonTypes.Filled}
                    onClick={() => this.props.onSubmit(selected_products)}>
                    {t("Add Products")}
                </Button>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    products: state.app.products
})
const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    getTables: data => dispatch(getTables()),
    createTable: data => dispatch(createTable(data))
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withTranslation()(AddProductsSidebar));

