export const isNotEmpty = (value) => {
    return !!(value);
}

export const isNumber = (value) => {
    return !isNaN(value);
}

export const isPositiveNumber = (value) => {
    return parseInt(value) > 0;
}

export const validationTests = {
    isNotEmpty: (value) => isNotEmpty(value),
    isNumber: (value) => isNumber(value),
    isPositiveNumber: (value) => isPositiveNumber(value),
}
