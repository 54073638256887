import React from "react";
import styles from "./RegisterSuccess.module.scss";
import {ReactComponent as SuccessCheck} from "../../assets/icons/success-check.svg"
import classNames from "classnames";
import {useTranslation} from "react-i18next";

const RegisterSuccess = () => {

    const { t, i18n } = useTranslation();

    const classes = classNames(styles.registerSuccess);

    return (
        <div className={classes}>
            <SuccessCheck/>
            <p>
                {t('register-success-message')}
            </p>
        </div>
    )

}

export default RegisterSuccess;
