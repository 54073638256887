import axios from "axios";
import {COOKIE_NAME} from "../utils/constants";
import {getCookie} from "../utils";
import * as constants from "../utils/constants";

const url = process.env.REACT_APP_BACKEND_URL;


export const getOrders = () => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.get(url + `businesses/orders?page=0&size=100&statuses=APPROVED,DECLINED,SENT`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            dispatch({ type: constants.GET_ORDERS, payload: res.data.data });
            return res;
        });
    }
}

export const getTableOrders = (completed) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.get(url + `businesses/orders?page=0&size=100&&statuses=${completed ? "APPROVED,DECLINED" : "SENT"}`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            res.data.data.sort((a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime));
            return res;
        });
    }
}

export const updateOrderStatus = (orderId, status) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.put(url + `businesses/orders/${orderId}`, {"orderStatus": status},{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const getDeliveryOrders = () => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.get(url + `businesses/delivery-orders?page=0&size=100&statuses=APPROVED,DECLINED,SENT`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            dispatch({ type: constants.GET_DELIVERY_ORDERS, payload: res.data.data });
            return res;
        });
    }
}

export const updateDeliveryOrderStatus = (orderId, status) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.put(url + `businesses/delivery-orders/${orderId}`, {"orderStatus": status},{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const createOrder = (data) => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.post(url + `open/clients/orders`, data, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                [COOKIE_NAME]: getCookie(COOKIE_NAME)
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const createDeliveryOrder = (data) => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.post(url + `open/clients/delivery-orders`, data, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                [COOKIE_NAME]: getCookie(COOKIE_NAME)
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const sendOrder = (orderId, data) => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.put(url + `open/clients/orders/${orderId}`, data, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                [COOKIE_NAME]: getCookie(COOKIE_NAME)
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}
