import React from "react";
import styles from "./MenuCard.module.scss";
import {useTranslation} from "react-i18next";
import Button, {ButtonTypes} from "../../Button/Button";
import {ReactComponent as ArrowRightIcon} from "../../../assets/icons/right.svg";
import classNames from "classnames";
import {getAllProductsFromMenus, getProductsFromMenu, getTitleByLanguage} from "../../../utils";
import {connect, useSelector} from "react-redux";

const MenuCard = ({menu, menus, defaultMenu, className, onClick}) => {

    const {t, i18n} = useTranslation();
    const image = menu.thumbnail || menu.photoUrl;
    const business = useSelector(state => state.client.business);

    const productCount = () => {
        if(defaultMenu) {
            return getAllProductsFromMenus(menus).length;
        } else {
            return getProductsFromMenu(menu).length;
        }
    }

    return (
        <div className={classNames(styles.menuCard, className)}
             onClick={onClick}>
            <div style={{backgroundImage: "url('" + image + "')"}} className={styles.menuCardImage} />
            <div className={styles.menuCardContent}>
                <p className={styles.menuCardTitle}>{getTitleByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, menu)}</p>
                <p className={styles.menuCardSubtitle}>{productCount()} {t("products")}</p>
            </div>
            <Button
                type={ButtonTypes.Icon}>
                <ArrowRightIcon/>
            </Button>
        </div>
    )
}
const mapStateToProps = state => ({
    menus: state.client.menus
});
const mapDispatchToProps = dispatch => ({
    //
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(MenuCard);
