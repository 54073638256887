import React from "react";
import styles from "./MenuUnavailable.module.scss";
import classNames from "classnames";
import SadFace from "../../../assets/images/sad-face.png";

const MenuUnavailable = ({text, className}) => {
    return (
        <div className={classNames(styles.menuUnavailable, className)}>
            <img src={SadFace} alt="not-available"/>
            <p>{text}</p>
        </div>
    )
}

export default MenuUnavailable;
