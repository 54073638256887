import axios from "axios";
import * as constants from "../utils/constants";

const url = process.env.REACT_APP_BACKEND_URL;

export const login = data => {
    return function(dispatch) {
        return axios.post(url + "oauth/token", `grant_type=password&username=${data.username}&password=${data.password}`, {
            headers: {
                "Authorization": "Basic MEdTTlRjYmxhUDNmZ2lUOlQwMnhrVEdLdjA2bktzOUhT",
                "Content-Type": "application/x-www-form-urlencoded",
            }
        }).then(res => {
            dispatch({ type: constants.LOGIN, payload: res.data });
            return res;
        })
    }
}

export const register = data => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.post(url + "users/businesses/signup", data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            dispatch({ type: constants.REGISTER, payload: res.data });
            return res;
        })
    }
}

export const authenticateClient = data => {
    return function(dispatch) {
        return axios.post(url + "oauth/token", `grant_type=client_credentials`, {
            headers: {
                "Authorization": "Basic MEdTTlRjYmxhUDNmZ2lUOlQwMnhrVEdLdjA2bktzOUhT",
                "Content-Type": "application/x-www-form-urlencoded",
            }
        }).then(res => {
            return res;
        })
    }
}

export const logout = data => ({
    type: constants.LOGOUT, payload: data
})

export const getBusinessDetailsClient = businessId => {
    const token = localStorage.getItem("client_access_token");
    return axios.get(url + `out/businesses/${businessId}`, {
                headers: {
                    "Authorization": "Bearer " + token
                }
            }).then(res => {
                return res;
            });
}

export const getUserDetails = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.get(url + `users/details`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const getBusinessDetails = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        dispatch({ type: constants.FETCHED_USER_DETAILS });
        return axios.get(url + `businesses/details`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            dispatch({ type: constants.GET_BUSINESS_DETAILS, payload: res.data });
            return res;
        });
    }
}

export const updateBusinessDetails = (data, id) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.put(url + `businesses/${id}`, data,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const updateBusinessAddress = (data, id) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.put(url + `businesses/${id}/addresses`, data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const sendPasswordResetEmail = data => {
    const client_token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.post(url + `out/request-reset-password`, { email: data },{
            headers: {
                "Authorization": "Bearer " + client_token
            }
        }).then(res => {
            return res;
        });
    }
}

export const resetPassword = data => {
    const client_token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.post(url + `out/reset-password`, data,{
            headers: {
                "Authorization": "Bearer " + client_token
            }
        }).then(res => {
            return res;
        });
    }
}

