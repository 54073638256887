import React, {Component} from "react";
import "./Menus.scss";
import {
    activateMenu,
    createMenu,
    deactivateMenu,
    deleteMenu,
    getMenus,
    setMenus,
    updateMenu,
    updateMenuOrdering
} from "../../../actions/menus";
import {connect} from "react-redux";
import Modal from "../../../components/Modal/Modal";
import {Link, withRouter} from "react-router-dom";
import {
    askForConfirmation,
    editingTitle,
    getLanguageLabel,
    prepareOrderingPayload,
    reorder,
    search
} from "../../../utils";
import DragAndDrop, {DraggableTypes} from "../../../components/Draggable/DragAndDrop";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import * as constants from "../../../utils/constants";
import {withTranslation} from "react-i18next";
import {setLoading} from "../../../actions";
import {uploadMediaImage} from "../../../actions/media";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../../components/Button/Button";
import Input, {InputStyles} from "../../../components/Input/Input";
import {inputValidations} from "../../../utils/validation/configurations";
import {validate} from "../../../utils/validation";
import Drawer from "../../../components/Drawer/Drawer";
import {ReactComponent as EditIcon} from "../../../assets/icons/edit.svg";
import {ReactComponent as QrCodeIcon} from "../../../assets/icons/qr-code.svg";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";

class Menus extends Component {
    popUpQuestion = "A jeni të sigurt të vazhdoni?";

    constructor(props) {
        super(props);

        this.state = {
            showValidations: false,
            isModalOpen: false,
            editing: false,
            file: null,
            sidebar_opened: false,
            search_text: "",
            menu: {
                title: "",
                titleEn: "",
                photoUrl: "",
            }
        }
    }

    componentDidMount() {
        this.props.getMenus();
    }

    openModal = () => {
        this.setState({isModalOpen: true});
    }

    closeModal = () => {
        this.setState({
            showValidation: false,
            sidebar_opened: false,
            editing: false,
            menu: constants.EMPTY_MENU_OBJECT,
            file: null
        });
    }

    handleMediaUpload = async (file) => {
        return this.props.uploadMediaImage(file).then(res => {
            return {
                photoUrl: res.data
            }
        })
    }

    handleCreateMenu = async() => {
        const {t} = this.props;
        let {menu, file} = this.state;

        if(this.formIsInvalid()) {
            this.setState({showValidations: true});
            return;
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        if (file) {
            this.handleMediaUpload(file).then(res => {
                menu = {...menu, ...res};
                this.props.createMenu(menu).then(res => {
                    this.props.getMenus();
                    this.closeModal();
                    this.props.setLoading(false);
                });
            })
        } else {
            this.props.createMenu(menu).then(res => {
                this.props.getMenus();
                this.closeModal();
                this.props.setLoading(false);
            });
        }

    }

    handleUpdateMenu = async() => {
        const {t} = this.props;
        let {menu, file} = this.state;

        if(this.formIsInvalid()) {
            this.setState({showValidations: true});
            return;
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        if (file) {
            this.handleMediaUpload(file).then(res => {
                menu = {...menu, ...res};
                this.props.updateMenu(menu.id, menu).then(res => {
                    this.props.getMenus();
                    this.closeModal();
                    this.props.setLoading(false);
                });
            })
        } else {
            this.props.updateMenu(menu.id, menu).then(res => {
                this.props.getMenus();
                this.closeModal();
                this.props.setLoading(false);
            });
        }
    }

    openMenu = (menu) => {
        this.props.history.push("/menus/" + menu.id + "/submenus");
    }

    onClickEdit = (item, e) => {
        e.stopPropagation();
        this.setState({editing: true, menu: item});
        this.openModal();
    }

    onClickDelete = async(item, e) => {
        e.stopPropagation();
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.deleteMenu(item.id).then(res => {
            this.props.getMenus();
            this.props.setLoading(false);
        });
    }

    onClickActivate = async(item, e) => {
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.activateMenu(item).then(res => {
            this.props.getMenus()
            this.props.setLoading(false);
        })
    }

    onClickDeactivate = async(item, e) => {
        console.log(item);
        e.stopPropagation();
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.deactivateMenu(item).then(res => {
            this.props.getMenus();
            this.props.setLoading(false);
        })
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) return;

        const menus = reorder(
            this.props.menus,
            result.source.index,
            result.destination.index
        );

        const reorderedMenusPayload = prepareOrderingPayload(menus, "menuId");
        this.props.updateMenuOrdering(reorderedMenusPayload);

        this.props.setMenus(menus);
    }

    formIsInvalid = () => {
        const {menu} = this.state;
        return validate(inputValidations.menuTitleInput, menu.title) || validate(inputValidations.menuTitleEnInput, menu.titleEn);
    }


    openCreateMenuSidebar = () => {
        this.setState({
           sidebar_opened: true,
           editing: false
        });
    }

    openEditMenuSidebar = (menu, e) => {
        e.stopPropagation();
        this.setState({
            menu: menu,
            sidebar_opened: true,
            editing: true
        });
    }

    render() {
        const {isModalOpen, menu, editing, file, showValidations, sidebar_opened, search_text} = this.state;
        const {menus_fetched, t, user_details} = this.props;
        const menus = search(this.props.menus, search_text, "title");

        return (
            <div className="menus-page">

                <div className="page-header-row">
                    <h1>{t("Menus")}</h1>
                    <div className="d-flex">
                        <Button
                            className="mr-16"
                            color={ButtonColors.Green}
                            onClick={() => this.openCreateMenuSidebar()}>
                            + {t("Create Menu")}
                        </Button>
                    </div>
                </div>

                <div className="page-search-row">
                    <Input
                        type="text"
                        id="searchTablesInput"
                        name="searchTablesInput"
                        placeholder={t("Search for menus")}
                        value={search_text}
                        onChange={(e) => this.setState({search_text: e.target.value})}/>
                </div>

                <div className="box-body">

                    {menus_fetched ?
                        <>
                            {menus.length > 0 ?
                                <DragAndDrop
                                    items={menus}
                                    type={DraggableTypes.MENU}
                                    onClickItem={(item, e) => this.openMenu(item, e)}
                                    onClickEdit={(item, e) => this.openEditMenuSidebar(item, e)}
                                    onClickDelete={(item, e) => this.onClickDelete(item, e)}
                                    onClickActivate={(item, e) => this.onClickActivate(item, e)}
                                    onClickDeactivate={(item, e) => this.onClickDeactivate(item, e)}
                                    onDragEnd={(result) => this.onDragEnd(result)}/> : "No menus..."}
                        </>
                        : <div className="text-center py-3">
                            <div className="spinner-border text-info"/>
                        </div>
                    }

                </div>


                <Drawer
                    open={sidebar_opened}>

                    <div className="d-flex justify-content-between mb-32">
                        <h2>{editing ? t("Edit Menu") : t("Create Menu")}</h2>
                        <div className="d-flex">
                            <Button
                                type={ButtonTypes.Icon}
                                onClick={this.closeModal}>
                                <CloseIcon/>
                            </Button>
                        </div>
                    </div>

                    <Input
                        label={`${t("Title")} (${t("Primary language")})`}
                        id="menuTitleInput"
                        name="menuTitleInput"
                        value={menu.title}
                        style={InputStyles.Material}
                        validations={[inputValidations.menuTitleInput, showValidations]}
                        onChange={(e) => this.setState({menu: {...menu, title: e.target.value}})}/>

                    <Input
                        label={`${t("Title")} (${t("Secondary language")})`}
                        id="menuTitleInput"
                        name="menuTitleInput"
                        value={menu.titleEn}
                        style={InputStyles.Material}
                        validations={[inputValidations.menuTitleEnInput, showValidations]}
                        onChange={(e) => this.setState({menu: {...menu, titleEn: e.target.value}})}/>

                    <Input
                        label={t("Photo or Video")}
                        type="file"
                        id="menuImageInput"
                        name="menuImageInput"
                        value={file}
                        style={InputStyles.Material}
                        validations={[inputValidations.menuImageInput, showValidations]}
                        onChange={(e) => this.setState({file: e.target.files[0] ? e.target.files[0] : null})}/>


                    <Button
                        className="mt-24"
                        size={ButtonSizes.Full}
                        color={ButtonColors.Green}
                        type={ButtonTypes.Filled}
                        onClick={() => editing ? this.handleUpdateMenu() : this.handleCreateMenu()}>
                        {editing ? t("Edit Menu") : t("Create Menu")}
                    </Button>
                </Drawer>

            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        menus: state.app.menus,
        menus_fetched: state.app.menus_fetched,
        user_details: state.app.user_details,
    }
}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    getMenus: data => dispatch(getMenus()),
    createMenu: data => dispatch(createMenu(data)),
    updateMenu: (menuId, data) => dispatch(updateMenu(menuId, data)),
    activateMenu: (data) => dispatch(activateMenu(data)),
    deactivateMenu: (data) => dispatch(deactivateMenu(data)),
    deleteMenu: menuId => dispatch(deleteMenu(menuId)),
    setMenus: data => dispatch(setMenus(data)),
    updateMenuOrdering: data => dispatch(updateMenuOrdering(data)),
    uploadMediaImage: (data) => dispatch(uploadMediaImage(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Menus)));
