import React, {Component} from "react";
import "./Help.scss";
import {withTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlayCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import ReactModal from "react-modal";

class Help extends Component {

    state = {
        isModalOpen: false,
        url: "",
        title: ""
    }

    items = () => {
        const {t} = this.props;
        return [
            {title: t("help-create-menu"), url: "https://drive.google.com/file/d/1M75FiST9WwfbViicC531ZTD1CIFacKCP/preview"},
            {title: t("help-create-daily-menu"), url: "https://drive.google.com/file/d/12JI-P21S94ljauC-kQagfGPMpFXdho6e/preview"},
            {title: t("help-create-submenu"), url: "https://drive.google.com/file/d/1ssuDt9HPRcHFgTIFNz43RbcQ6F0kv3NH/preview"},
            {title: t("help-create-product"), url: "https://drive.google.com/file/d/1vKDTENXO9hnN396ukmkftKWxIjcsgoFp/preview"},
            {title: t("help-create-table"), url: "https://drive.google.com/file/d/1SWShGYbiJz8KuRzlleSVYJin9hLXwbnY/preview"},
            {title: t("help-edit-product"), url: "https://drive.google.com/file/d/1aaWwYSK8hU6tw13xbAcXVqO36dayznG0/preview"},
            {title: t("help-download-qr-code"), url: "https://drive.google.com/file/d/1NPi-EHwt8SrI4zeriTVYRoM-Cw6xbnu2/preview"},
            {title: t("help-add-product-to-submenu"), url: "https://drive.google.com/file/d/1LlaNHzezcB1CT3aTP9XHrUseR5aDg8bD/preview"},
            {title: t("help-order-delete-product"), url: "https://drive.google.com/file/d/1ONeXcEeOFamjnAp2dISeEPLkN1DzaPlB/preview"},
            {title: t("help-deactivate-menu"), url: "https://drive.google.com/file/d/18inpTI01rxTSJ2zDvaOBKfQu5R6jksS2/preview"},
            {title: t("help-deactivate-submenu"), url: "https://drive.google.com/file/d/1T4M1FmuRLriGXZ3h2fajjiH7yiLy2yMZ/preview"},
        ]
    }

    render() {
        const {t} = this.props;
        const {isModalOpen, title, url} = this.state;
        return (
            <div className="help-page">

                <div className="page-header-row">
                    <h1>{t("Help")}</h1>
                </div>

                <div className="help-list">
                    {this.items().map(item => (
                        <div className="help-list-item"
                             onClick={() => this.setState({isModalOpen: true, url: item.url, title: item.title})}>
                            <FontAwesomeIcon icon={faPlayCircle} className="mr-3" /> {item.title}
                        </div>
                    ))}
                </div>

                <ReactModal
                    isOpen={isModalOpen}
                    onRequestClose={() => this.setState({ isModalOpen: false })}
                    shouldCloseOnOverlayClick={true}
                    className="videos-modal">

                    <FontAwesomeIcon
                        icon={faTimes}
                        color="black"
                        className="videos-model-close-icon"
                        onClick={() => this.setState({ isModalOpen: false }) }/>


                    <iframe src={url} width="800" height="460" />

                </ReactModal>


            </div>
        );
    }

}

export default withTranslation()(Help);
