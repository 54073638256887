import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {CLIENT_MODAL_PAGES} from "../../../../utils/constants";
import {getDeliveryOrders, getOrders} from "../../../../actions/client";
import {connect} from "react-redux";
import styles from "./WishlistModalOrder.module.scss"
import ClientOrderCard from "../../../Cards/ClientOrderCard/ClientOrderCard";
import Empty from "../../../Empty/Empty";
import classNames from "classnames";

class WishlistModalOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comment: "",
            order: null,
            product: null,
            page: CLIENT_MODAL_PAGES.PRODUCTS_PAGE,
        }
    }

    componentDidMount() {
        const {business_id} = this.props;
        this.props.getOrders(business_id);
        this.props.getDeliveryOrders(business_id);
    }

    render() {
        const {t, orders, delivery_orders} = this.props;

        return (
            <div className={styles.wishlistModalOrders}>

                <p className={classNames(styles.wishlistModalTitle ,"mb-2")}>{t("Orders history")}</p>

                {!this.props.is_delivery_enabled && <div>
                    {orders.length ?
                        orders.map(order =>
                            <ClientOrderCard
                                order={order}
                                onDetailsButtonClick={() => this.props.onOrderClick(order)} />)

                        :<div className="mt-94 text-center">
                            <Empty />
                            <p className="font-20 mt-16">{t("No orders to display!")}</p>
                            <p className="font-17 text-tertiary mt-2">{t("It looks like you haven't made any order yet!")}</p>
                        </div>
                    }
                </div>}

                {this.props.is_delivery_enabled && <div>
                    {delivery_orders.length ?
                        delivery_orders.map(order =>
                            <ClientOrderCard
                                order={order}
                                onDetailsButtonClick={() => this.props.onOrderClick(order)} />)

                        :<div className="mt-94 text-center">
                            <Empty />
                            <p className="font-20 mt-16">{t("No orders to display!")}</p>
                            <p className="font-17 text-tertiary mt-2">{t("It looks like you haven't made any order yet!")}</p>
                        </div>
                    }
                </div>}


            </div>
        )
    }

}

const mapStateToProps = state => ({
    orders: state.client.orders,
    delivery_orders: state.client.delivery_orders,
    is_delivery_enabled: state.client.is_delivery_enabled
});
const mapDispatchToProps = dispatch => ({
    getOrders: (businessId) => dispatch(getOrders(businessId)),
    getDeliveryOrders: (businessId) => dispatch(getDeliveryOrders(businessId)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withTranslation()(WishlistModalOrders));
