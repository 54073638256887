import React from "react";
import styles from "./EmptySearch.module.scss";
import {ReactComponent as EmptyIcon} from "../../assets/icons/search-icon.svg";
import {ReactComponent as NoResultsIcon} from "../../assets/icons/empty-search-icon.svg";

const EmptySearch = ({ noResults }) => (
    noResults ? <NoResultsIcon className={styles.empty} /> : <EmptyIcon className={styles.empty} />
)

export default EmptySearch;
