import React from "react";
import "./Home.scss";
import Image from "../../../assets/images/home-section-image.png";
import {useTranslation} from "react-i18next";

const Home = () => {
    
    const { t, i18n } = useTranslation();
    
    return (
        <section id="home">
            <div className="text">
                <div className="title">
                    {t("home-title-1")} <br/>
                    {t("home-title-2")}
                </div>
                <div className="subtitle">
                    {t("home-subtitle-1")} <br/>
                    {t("home-subtitle-2")}
                </div>
                {/*<button className="scan-button"*/}
                {/*        onClick={() => window.open("https://scan.menyja.co", "_blank")}>*/}
                {/*    {t("Scan qr code")}*/}
                {/*</button>*/}
            </div>
            <img src={Image} alt="Image"/>
        </section>
    )
}

export default Home;
