export const validationTests = {
    isNumber: "isNumber",
    isNotEmpty: "isNotEmpty",
    isPositiveNumber: "isPositiveNumber",
}

// validation tests of each input
export const inputValidations =  {

    //login
    loginEmailInput: [validationTests.isNotEmpty],
    loginPasswordInput: [validationTests.isNotEmpty],

    //forgot password
    forgotPasswordEmailInput: [validationTests.isNotEmpty],

    //new password
    newPasswordInput: [validationTests.isNotEmpty],
    confirmNewPasswordInput: [validationTests.isNotEmpty],

    //menus
    menuTitleInput: [validationTests.isNotEmpty],
    menuTitleEnInput: [],
    menuImageInput: [],

    //submenus
    submenuTitleInput: [validationTests.isNotEmpty],
    submenuTitleEnInput: [],
    submenuDescriptionInput: [],
    submenuDescriptionEnInput: [],
    submenuStartTimeInput: [],
    submenuEndTimeInput: [],


    //tables
    tableNameInput: [validationTests.isNotEmpty],
    numberOfTablesInput: [validationTests.isNotEmpty, validationTests.isNumber, validationTests.isPositiveNumber],

    //products
    productNameInput: [validationTests.isNotEmpty],
    productNameEnInput: [],
    productDescriptionInput: [],
    productDescriptionEnInput: [],
    productDeliveryInput: [],
    productPriceInput: [validationTests.isNotEmpty],

    //account
    accountNameInput: [validationTests.isNotEmpty],
    accountAddressInput: [validationTests.isNotEmpty],
    accountPhoneInput: [validationTests.isNotEmpty],
    accountCityInput: [validationTests.isNotEmpty],
    accountCountryInput: [validationTests.isNotEmpty],
    accountFacebookInput: [validationTests.isNotEmpty],
    accountInstagramInput: [validationTests.isNotEmpty],
    accountTripadvisorInput: [validationTests.isNotEmpty],
    accountLatitudeInput: [validationTests.isNotEmpty],
    accountLongitudeInput: [validationTests.isNotEmpty],
    accountDistanceInput: [validationTests.isNotEmpty],
    accountStartDayTime: [validationTests.isNotEmpty],
    accountEndDayTime: [validationTests.isNotEmpty],
    accountDescription: [],
    accountDescriptionEn: [],

    //daily menu
    dailyTitleInput: [validationTests.isNotEmpty],
    dailyTitleEnInput: [],
    dailyPriceInput: [validationTests.isNotEmpty],
    dailyStartTimeInput: [validationTests.isNotEmpty],
    dailyEndTimeInput: [validationTests.isNotEmpty],
    dailyImageInput: [],
    dailyDescriptionInput: [],
    dailyDescriptionEnInput: [],

    //events
    eventTitleInput: [validationTests.isNotEmpty],
    eventTitleEnInput: [],
    eventLinkInput: [],
    eventFileInput: [],
    eventDescriptionInput: [],
    eventDescriptionEnInput: [],

    feedbackNameInput: [validationTests.isNotEmpty],
    feedbackCommentInput: [validationTests.isNotEmpty],

    deliveryAddressInput: [validationTests.isNotEmpty],
    deliveryEmailInput: [validationTests.isNotEmpty],
    deliveryPhoneInput: [validationTests.isNotEmpty],

    //delivery
    deliveryStartTimeInput: [validationTests.isNotEmpty],
    deliveryEndTimeInput: [validationTests.isNotEmpty],
    deliveryPriceLimitInput: [validationTests.isNotEmpty],
    deliveryFeeInput: [validationTests.isNotEmpty],

    //register
    registerFirstNameInput: [validationTests.isNotEmpty],
    registerLastNameInput: [validationTests.isNotEmpty],
    registerEmailInput: [validationTests.isNotEmpty],
    registerDescriptionInput: [validationTests.isNotEmpty],
    registerPasswordInput: [validationTests.isNotEmpty],
    registerBusinessNameInput: [validationTests.isNotEmpty],
    registerBusinessAddressInput: [validationTests.isNotEmpty],
    registerBusinessPhoneInput: [validationTests.isNotEmpty],
    registerBusinessUsernameInput: [validationTests.isNotEmpty],
    registerBusinessCountryInput: [validationTests.isNotEmpty],
    registerBusinessCityInput: [validationTests.isNotEmpty],
    registerBusinessStartTimeInput: [validationTests.isNotEmpty],
    registerBusinessEndTimeInput: [validationTests.isNotEmpty],
    registerBusinessLatitudeInput: [validationTests.isNotEmpty],
    registerBusinessLongitudeInput: [validationTests.isNotEmpty],



}
