import React from "react";
import "./About.scss";
import Corner from "../../../assets/images/corner.png";
import ArrowDown from "../../../assets/images/arrow-down.png";
import {useTranslation} from "react-i18next";


const About = () => {
    
    const { t, i18n } = useTranslation();
    
    return (
        <section id="about">
    
            <img src={Corner} alt="Corner" className="corner-top-left"/>
            <img src={Corner} alt="Corner" className="corner-top-right"/>
            <img src={Corner} alt="Corner" className="corner-bottom-right"/>
            <img src={Corner} alt="Corner" className="corner-bottom-left"/>
            
            <div className="text">
                <div className="title">
                    {t("About us")}
                </div>
                <div className="subtitle">
                    {t("about-us-text")}
                </div>
            </div>
    
            <img src={ArrowDown} alt="Arrow down" className="arrow-down"/>


        </section>
    )
}

export default About;
