import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL;

export const getStatistics = data => {
    const token = localStorage.getItem("access_token");
    return axios.get(url + "businesses/statistics", {
        params: data,
        headers: {
            "Authorization": "Bearer " + token
        } }).then(res => {
        console.log(res);
        return res;
    });
}
