import React from "react";
import styles from "./Text.module.scss";
import classNames from "classnames";
import {InputStyles} from "../Input";

export default function Text(props) {

    const {
        id,
        name,
        type,
        style,
        value,
        className,
        placeholder,
        autoComplete,
        onChange,
        onBlur,
        autoFocus,
        disabled
    } = props;

    const classes = classNames(styles.textInput, className,
        {[styles.materialTextInput]: style === InputStyles.Material},
        {[styles.disabled]: disabled},
        );

    return (
        <input
            key={id}
            id={id}
            name={name}
            type={type}
            value={value}
            className={classes}
            placeholder={placeholder}
            autoComplete={autoComplete}
            onChange={onChange}
            onBlur={onBlur}
            autoFocus={autoFocus}
            disabled={disabled}
        />
    )
}
