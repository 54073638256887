import React, {useRef, useState} from "react";
import styles from "./File.module.scss";
import classNames from "classnames";
import {InputStyles} from "../Input";
import {useTranslation} from "react-i18next";

export default function File(props) {

    const {
        id,
        name,
        type,
        value,
        className,
        placeholder,
        onBlur,
        style
    } = props;

    const input = useRef(null);
    const {t, i18n} = useTranslation();
    const [filename, setFilename] = useState("");

    const classes = classNames(styles.fileInput, className,
            {[styles.materialTextInput]: style === InputStyles.Material});

    const onChange = e => {
        const filename = e.target?.files[0]?.name;
        setFilename(filename);
        props.onChange(e);
    }

    return (
        <div
            onClick={() => input.current.click()}
            className={classes}>
            {filename || t("Choose File")}
            <input
                id={id}
                name={name}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                ref={input}/>
        </div>

    )
}
