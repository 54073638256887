import React, {Component} from "react";
import ProductCard from "../../../Cards/Product/ProductCard";
import {withTranslation} from "react-i18next";
import {CLIENT_MODAL_PAGES} from "../../../../utils/constants";
import {closeModal, getOrders, updateWishlist} from "../../../../actions/client";
import {connect} from "react-redux";
import {
    decreaseQuantityOfWishlistProduct,
    getAllProductsFromMenus,
    getWishlistProducts,
    increaseQuantityOfWishlistProduct, removeProductFromWishlist
} from "../../../../utils";
import styles from "./WishlistModalWishlist.module.scss"
import Empty from "../../../Empty/Empty";
import {withRouter} from "react-router";
import classNames from "classnames";

class WishlistModalWishlist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comment: "",
            order: null,
            product: null,
            page: CLIENT_MODAL_PAGES.PRODUCTS_PAGE,
        }
    }

    handleIncreaseClick = (e, product) => {
        e.stopPropagation();
        increaseQuantityOfWishlistProduct(product);
        this.props.updateWishlist();
    }

    handleDecreaseClick = (e, product) => {
        e.stopPropagation();
        decreaseQuantityOfWishlistProduct(product);
        this.props.updateWishlist();
    }

    handleRemoveFromWishlistButtonClick = (product) => {
        removeProductFromWishlist(product);
        this.props.updateWishlist();
    }

    openProduct = (product) => {
        this.props.history.push(`/menu/${this.props.business_id}/${this.props.table_number}/products/${product.id}`);
        this.props.closeModal();
    }


    render() {
        const {t, menus, daily_menus} = this.props;
        const products = getAllProductsFromMenus(menus);
        const wishlist = getWishlistProducts(products, daily_menus);

        return (
            <div className={styles.wishlistModalWishlist}>

                <p className={classNames(styles.wishlistModalTitle, "mt-32 mb-12")}>
                    {t("Favorite Products")}
                </p>
                {wishlist.length ?
                    wishlist.map(product =>
                    product &&
                    <ProductCard
                        cartProduct
                        className="mb-12"
                        product={product}
                        onClick={() => this.openProduct(product)}/>)
                            :
                    <div className="mt-94 text-center">
                        <Empty />
                        <p className="font-21 mt-16">{t("No products to display!")}</p>
                        <p className="font-17 text-tertiary mt-2">{t("It looks like you haven't added any favorite products!")}</p>
                    </div>}

            </div>
        )
    }

}

const mapStateToProps = state => ({
    menus: state.client.menus,
    wishlist: state.client.wishlist,
    orders: state.client.orders,
    daily_menus: state.client.daily_menus,
    is_ordering_enabled: state.client.is_ordering_enabled
});
const mapDispatchToProps = dispatch => ({
    updateWishlist: () => dispatch(updateWishlist()),
    getOrders: (businessId) => dispatch(getOrders(businessId)),
    closeModal: () => dispatch(closeModal()),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
const translation = withTranslation();
export default withRouter(connector(translation(WishlistModalWishlist)));
