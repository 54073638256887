import axios from "axios";
import * as constants  from "../utils/constants";

const url = process.env.REACT_APP_BACKEND_URL;


export const getEvents = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.get(url + "events", {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            dispatch({ type: constants.GET_EVENTS, payload: res.data });
            return res;
        });
    }
}

export const createEvent = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.post(url + "events", data,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const updateEvent = (eventId, data) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.put(url + `events/${eventId}`, data,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const deleteEvent = eventId => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.delete(url + `events/${eventId}`,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const activateEvent = (data) => {
    const token = localStorage.getItem("access_token");
    const event = {
        ...data,
        active: true
    };
    return function(dispatch) {
        return axios.put(url + `events/${event.id}`, event,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const deactivateEvent = (data) => {
    const token = localStorage.getItem("access_token");
    const event = {
        ...data,
        active: false
    };
    console.log(event);
    return function(dispatch) {
        return axios.put(url + `events/${event.id}`, event,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

