import React, {Component} from "react";
import "./Password.scss";
import {connect} from "react-redux";
import {deleteTableNotifications, getTableNotifications, getTables} from "../../../actions/tables";
import firebase from "../../../firebaseInit";
import {askForConfirmation, errorToast, getQueryParameter, numberOfOccurrences, successToast} from "../../../utils";
import {withTranslation} from "react-i18next";
import {FORGOT_PASSWORD_PAGES} from "../../../utils/constants";
import {Link, withRouter} from "react-router-dom";
import {resetPassword, authenticateClient, sendPasswordResetEmail} from "../../../actions/user";
import {toast} from "react-toastify";
import {setLoading} from "../../../actions";
import Input from "../../../components/Input/Input";
import Button, {ButtonColors, ButtonTypes} from "../../../components/Button/Button";
import Logo from "../../../assets/icons/logo-text.svg";
import {inputValidations} from "../../../utils/validation/configurations";
import {validate} from "../../../utils/validation";

class Password extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            showValidation: false,
            page: FORGOT_PASSWORD_PAGES.EMAIL_PAGE,
            email: "",
            new_password: "",
            confirm_new_password: "",
            code: ""
        }
    }
    
    componentDidMount() {
        
        this.props.loginClient(this.state).then(res => {
            localStorage.setItem("client_access_token", res.data.access_token);
        });
        
        document.querySelector("body").classList.add("login-page");
        const code = getQueryParameter("code", window.location.href);
        if (code) {
            this.setState({
                page: FORGOT_PASSWORD_PAGES.NEW_PASSWORD_PAGE,
                code: code
            })
        }
    }
    
    componentWillUnmount() {
        document.querySelector("body").classList.remove("login-page");
    }
    
    
    handleSubmit = async() => {
        const {t} = this.props;
        const {page, code, email, new_password, confirm_new_password} = this.state;

        if(this.formIsInvalid()) {
            this.setState({showValidation: true});
            return;
        }
        
        this.props.setLoading(true);
        if (page === FORGOT_PASSWORD_PAGES.EMAIL_PAGE) {
            this.props.sendPasswordResetEmail(email).then(res => {
                this.props.setLoading(false);
                successToast(t("Please check your email"));
            })
        } else {
            const data = {
                code: code,
                password: new_password
            }
            if (new_password === confirm_new_password) {
                this.props.resetPassword(data).then(res => {
                    this.props.setLoading(false);
                    successToast(t("Your password was reset"));
                    this.props.history.push("/login");
                })
            } else {
                this.props.setLoading(false);
                errorToast(t("Please enter valid password"));
            }
        }
    }

    formIsInvalid = () => {
        const {page, email, new_password, confirm_new_password} = this.state;
        if(page === FORGOT_PASSWORD_PAGES.EMAIL_PAGE) {
            return validate(inputValidations.forgotPasswordEmailInput, email);
        } else {
            return validate(inputValidations.newPasswordInput, new_password) || validate(inputValidations.confirmNewPasswordInput, confirm_new_password);
        }
    }
    
    renderEmailPage = () => {
        const {t} = this.props;
        const {showValidation} = this.state;
        
        return (
            <div className="register-box">
                <img src={Logo} alt="logo" className="login-logo"/>
                <div className="login-box-body">
                    
                    <form onSubmit={e => e.preventDefault()} method="post">
                        <Input
                            label={t("Email")}
                            id="forgotPasswordEmailInput"
                            name="forgotPasswordEmailInput"
                            value={this.state.email}
                            validations={[inputValidations.forgotPasswordEmailInput, showValidation]}
                            onChange={(e) => this.setState({email: e.target.value})}/>
                        
                        <div className="text-center">
                            
                            <Button
                                className="px-4 py-1 mt-4"
                                type={ButtonTypes.Filled}
                                color={ButtonColors.Green}
                                onClick={(e) => this.handleSubmit(e)}>
                                {t("Send reset email")}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    
    renderNewPasswordPage = () => {
        const {t} = this.props;
        const {showValidation, new_password, confirm_new_password} = this.state;

        return (
            <div className="register-box">
                <img src={Logo} alt="logo" className="login-logo"/>
                <div className="login-box-body">
    
                    <form onSubmit={e => e.preventDefault()} method="post">
        
                        <Input
                            type="password"
                            label={t("New password")}
                            id="newPasswordInput"
                            name="newPasswordInput"
                            value={new_password}
                            validations={[inputValidations.newPasswordInput, showValidation]}
                            onChange={(e) => this.setState({new_password: e.target.value})}/>
        
                        <Input
                            type="password"
                            label={t("Confirm new password")}
                            id="confirmNewPasswordInput"
                            name="confirmNewPasswordInput"
                            value={confirm_new_password}
                            validations={[inputValidations.confirmNewPasswordInput, showValidation]}
                            onChange={(e) => this.setState({confirm_new_password: e.target.value})}/>
        
                        <div className="text-center">
                            <Button
                                className="px-4 py-1 mt-4"
                                type={ButtonTypes.Filled}
                                color={ButtonColors.Green}
                                onClick={(e) => this.handleSubmit(e)}>
                                {t("Reset password")}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    
    render() {
        const {page} = this.state;
        
        return (
            <div className="container-fluid">
                
                <div className="register-box mb-4">
                    
                    {page === FORGOT_PASSWORD_PAGES.EMAIL_PAGE && this.renderEmailPage()}
                    {page === FORGOT_PASSWORD_PAGES.NEW_PASSWORD_PAGE && this.renderNewPasswordPage()}
                
                </div>
            
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    sendPasswordResetEmail: data => dispatch(sendPasswordResetEmail(data)),
    resetPassword: data => dispatch(resetPassword(data)),
    loginClient: data => dispatch(authenticateClient(data))
})

export default withRouter(connect(null, mapDispatchToProps)(withTranslation()(Password)));
