import React from "react";
import styles from "./BusinessCard.module.scss";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import classNames from "classnames";
import Button, {ButtonTypes} from "../../Button/Button";
import {ReactComponent as ArrowRightIcon} from "../../../assets/icons/right.svg";

const BusinessCard = ({business, className, onClick}) => {

    const {t, i18n} = useTranslation();

    return (
        <div className={classNames(styles.businessCard, className)} onClick={onClick}>
            <div style={{backgroundImage: "url('" + business.logo + "')"}} className={styles.businessCardImage} />
            <div className={styles.businessCardContent}>
                <p className={styles.businessCardTitle}>{business.name}</p>
            </div>
            <Button
                type={ButtonTypes.Icon}>
                <ArrowRightIcon/>
            </Button>
        </div>
    )
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(BusinessCard);
