import React from "react";
import styles from "./Select.module.scss";
import classNames from "classnames";
import {InputStyles} from "../Input";

export default function Select(props) {

    const {
        id,
        name,
        style,
        value,
        options,
        className,
        placeholder,
        autoComplete,
        onChange,
        onBlur
    } = props;

    const classes = classNames(styles.selectInput, className,
        {[styles.materialSelectInput]: style === InputStyles.Material});

    return (
        <select
            id={id}
            name={name}
            value={value}
            className={classes}
            placeholder={placeholder}
            autoComplete={autoComplete}
            onChange={onChange}
            onBlur={onBlur}>
            {options.map(option =>
                <option selected={value == option.value} value={option.value}>
                    {option.label}
                </option>
            )}
        </select>
    )
}
