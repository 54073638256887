import React, {Component} from "react";
import "./Landing.scss";
import Header from "./Header/Header";
import Home from "./Home/Home";
import About from "./About/About";
import Info from "./Info/Info";
import Register from "./Register/Register";
import Video from "./Video/Video";
import Subscribe from "./Subscribe/Subscribe";
import Footer from "./Footer/Footer";
import {faQrcode} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withTranslation} from "react-i18next";

class Landing extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            tooltip: true
        }
    }

    componentDidMount() {
        document.body.classList.add("client");
    }

    componentWillUnmount() {
        document.body.classList.remove("client");
    }


    render() {
        return (
            <div className="landing-container">
                <Header/>
                <Home/>
                <About/>
                <Info/>
                {/*<Register/>*/}
                {/*<Video/>*/}
                <Subscribe/>
                <Footer/>
                
                {this.state.tooltip && <div className="scan-button-tooltip"
                        onClick={() => this.setState({ tooltip: false })}>
                    {this.props.t("Kliko për të skanuar!")}
                </div>}
                
                <button className="scan-button"
                    onClick={() => window.open("https://scan.menyja.co/", "_blank")}>
                    <FontAwesomeIcon icon={faQrcode} color="white" />
                </button>
            </div>
        )
    }
}

export default withTranslation()(Landing);
