import React, {Component} from "react";
import styles from "./OrderDetailsSidebar.module.scss";
import {withTranslation} from "react-i18next";
import Button, {ButtonColors, ButtonTypes} from "../../Button/Button";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";
import {ReactComponent as LeftArrowIcon} from "../../../assets/icons/left.svg"
import ProductCard from "../../Cards/ProductCard/ProductCard";
import {askForConfirmation, calculateTotal, flattenOrderItems} from "../../../utils";
import {ORDER_STATUSES} from "../../../utils/constants";
import {setLoading} from "../../../actions";
import {createTable} from "../../../actions/tables";
import {updateDeliveryOrderStatus, updateOrderStatus} from "../../../actions/orders";
import {connect} from "react-redux";
import {ReactComponent as CalendarIcon} from "../../../assets/icons/date.svg";
import moment from "moment";
import {ReactComponent as ClockIcon} from "../../../assets/icons/time.svg";
import Status, {StatusTypes} from "../../Status/Status";

const OrderStatusTypes = {
    APPROVED: StatusTypes.APPROVED,
    DECLINED: StatusTypes.REJECTED,
    SENT: StatusTypes.PENDING
}

class OrderDetailsSidebar extends Component {
    componentDidMount() {
        //
    }

    approveOrder = async(order) => {
        const {t, updateOrderStatus, updateDeliveryOrderStatus} = this.props;
        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }
        this.props.setLoading(true);
        if(this.props.isDelivery) {
            updateDeliveryOrderStatus(order.id, ORDER_STATUSES.APPROVED).then(res => {
                this.props.setLoading(false);
                this.props.updateOrders();
                this.props.onCloseButtonClick();
            });
        } else {
            updateOrderStatus(order.id, ORDER_STATUSES.APPROVED).then(res => {
                this.props.setLoading(false);
                this.props.updateOrders();
                this.props.onCloseButtonClick();
            });
        }
    }

    declineOrder = async(order) => {
        const {t, updateOrderStatus, updateDeliveryOrderStatus} = this.props;
        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }
        this.props.setLoading(true);
        if(this.props.isDelivery) {
            updateDeliveryOrderStatus(order.id, ORDER_STATUSES.DECLINED).then(res => {
                this.props.setLoading(false);
                this.props.updateOrders();
                this.props.onCloseButtonClick();
            });
        } else {
            updateOrderStatus(order.id, ORDER_STATUSES.DECLINED).then(res => {
                this.props.setLoading(false);
                this.props.updateOrders();
                this.props.onCloseButtonClick();
            });
        }
    }


    render() {
        const {t, order, completed, isDelivery} = this.props;
        return (
            <div>
                <div className="d-flex justify-content-between mb-16">
                    <h2>#{order.orderNumber.substr(order.orderNumber.length - 5)}</h2>
                    <Button
                        type={ButtonTypes.Icon}
                        onClick={this.props.onCloseButtonClick}>
                        <CloseIcon/>
                    </Button>
                </div>
                {!completed &&
                <div className={styles.header}>
                    Actions
                </div>}
                {!completed &&
                <div className={styles.actions}>
                    <Button
                        color={ButtonColors.Green}
                        onClick={() => this.approveOrder(order)}>
                        {t("Approve")}
                    </Button>
                    <Button
                        color={ButtonColors.Red}
                        onClick={() => this.declineOrder(order)}>
                        {t("Decline")}
                    </Button>
                    <Button
                        color={ButtonColors.Gray}
                        onClick={() => this.props.onBlockButtonClick}>
                        {t("Block")}
                    </Button>
                </div>}
                <div className={styles.header}>
                    {t("Status")}
                </div>
                <Status type={OrderStatusTypes[order.status]} />
                {isDelivery && <div className={styles.header}>
                    {t("Delivery user")}
                </div>}
                {isDelivery && <div className={styles.deliveryData}>
                    <p className="mb-2">{order.deliveryUser.phoneNumber}</p>
                    <p className="mb-2">{order.deliveryUser.email}</p>
                    <p></p>
                    <p></p>
                </div>}
                {isDelivery && <div className={styles.header}>
                    {t("Delivery address")}
                </div>}
                {isDelivery && <div className={styles.deliveryData}>
                    <p className="mb-2">{order.address}</p>
                    <p></p>
                    <p></p>
                </div>}
                <div className={styles.header}>
                    {t("Date and time")}
                </div>
                <p className="d-flex align-items-center">
                    <CalendarIcon className="mr-2" /> {moment.utc(order.createdDateTime).local().format("DD/MM/YYYY")}
                    <ClockIcon className="ml-16 mr-2"/> {moment.utc(order.createdDateTime).local().format("hh:mm")}
                </p>
                <div className={styles.header}>
                    {t("Products")}
                </div>
                {flattenOrderItems(order.orderItems).map(product =>
                    <ProductCard className="mb-16" product={product} />
                )}
                <div className={styles.header}>
                    {t("Comment")}
                </div>
                <p className={styles.description}>{order.comment || <span>&mdash;</span>}</p>
                <div className={styles.header}>
                    {t("Total")}
                </div>
                <p className={styles.total}>{calculateTotal(order)}€</p>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    updateOrderStatus: (orderId, status) => dispatch(updateOrderStatus(orderId, status)),
    updateDeliveryOrderStatus: (orderId, status) => dispatch(updateDeliveryOrderStatus(orderId, status))
});
const connector = connect(null, mapDispatchToProps);
export default connector(withTranslation()(OrderDetailsSidebar));

