import React from "react";
import styles from "./FeedbackRow.module.scss";
import {formatTime} from "../../../utils";
import moment from "moment";
import {ReactComponent as ClockIcon} from "../../../assets/icons/time.svg";
import {ReactComponent as CalendarIcon} from "../../../assets/icons/date.svg";

const FeedbackRow = ({feedback}) => {
    return (
        <div className={styles.feedbackRow}>
            <div className={styles.content}>
                <p className={styles.title}>{feedback.fullName || "-"}</p>
                <p className={styles.subtitle}><CalendarIcon/>{moment(feedback.createdOn).format("DD/MM/YYYY")}</p>
                <p className={styles.subtitle}><ClockIcon/>{moment(feedback.createdOn).format("hh:mm")}</p>
            </div>
            <div className={styles.middleContent}>
                {feedback.comment}
            </div>
        </div>
    )
}

export default FeedbackRow;
