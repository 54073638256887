import React from "react";
import ProductCard from "../../../Cards/Product/ProductCard";
import {withTranslation} from "react-i18next";
import {CLIENT_MODAL_PAGES} from "../../../../utils/constants";
import {closeModal, getOrders, updateWishlist} from "../../../../actions/client";
import {connect} from "react-redux";
import {createOrder, sendOrder} from "../../../../actions/orders";
import styles from "./WishlistModalOrderDetails.module.scss";
import {calculateTotal} from "../../../../utils";
import Status, {StatusTypes} from "../../../Status/Status";
import {ReactComponent as CalendarIcon} from "../../../../assets/icons/date.svg";
import moment from "moment";
import {ReactComponent as ClockIcon} from "../../../../assets/icons/time.svg";
import Button, {ButtonTypes} from "../../../Button/Button";
import {ReactComponent as LeftArrowIcon} from "../../../../assets/icons/left.svg";
import {withRouter} from "react-router";

class WishlistModalOrderDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            comment: "",
            order: null,
            product: null,
            page: CLIENT_MODAL_PAGES.PRODUCTS_PAGE,
        }
    }


    openProduct = (product) => {
        const {history, match: { params }} = this.props;
        history.push(`/menu/${params.businessId}/${params.table}/products/${product.id}`);
        this.props.closeModal();
    }


    orderStatusTypes = {
        APPROVED: StatusTypes.APPROVED,
        DECLINED: StatusTypes.REJECTED,
        SENT: StatusTypes.PENDING
    }


    render() {
        const {t, order} = this.props;

        return (
            <div className={styles.wishlistModalOrderDetails}>

                <div className="d-flex mb-24">
                    <Button
                        type={ButtonTypes.Icon}
                        className="mr-12"
                        onClick={this.props.onBackButtonClick}>
                        <LeftArrowIcon/>
                    </Button>
                    <div className="big-title">
                        #{order.orderNumber.substr(order.orderNumber.length - 5)}
                    </div>
                </div>



                <p>{t("Total")}</p>
                <div className="medium-title text-primary mb-24">
                    {calculateTotal(order)}€
                </div>

                <p className="mb-2">{t("Status")}</p>
                <Status type={this.orderStatusTypes[order.status]}/>

                <p className="mt-24 mb-2">{t("Date and time")}</p>
                <div className="d-flex align-items-center">
                    <p><CalendarIcon className="mr-2"/> {moment(order.createdDateTime).format("DD/MM/YYYY")}</p>
                    <p className="ml-12"><ClockIcon className="mr-2"/> {moment(order.createdDateTime).format("hh:mm")}</p>
                </div>


                <p className="mt-24 mb-2">{t("Products")}</p>
                {order.orderItems.map(item =>
                    <ProductCard
                        hidePrice
                        product={item.product}
                        onClick={() => this.openProduct(item.product)} />)}

                <p className="mt-24 mb-2">{t("Comment")}</p>
                <p className="text-tertiary">
                    {order.comment || t("No comment") + "..."}
                </p>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        menus: state.client.menus,
        wishlist: state.client.wishlist,
        orders: state.client.orders,
        daily_menus: state.client.daily_menus
    }
}

const mapDispatchToProps = dispatch => ({
    updateWishlist: () => dispatch(updateWishlist()),
    createOrder: (data) => dispatch(createOrder(data)),
    sendOrder: (orderId, data) => dispatch(sendOrder(orderId, data)),
    getOrders: (businessId) => dispatch(getOrders(businessId)),
    closeModal: () => dispatch(closeModal())
})

const connector = connect(mapStateToProps, mapDispatchToProps);
const translation = withTranslation()
export default withRouter(connector(translation(WishlistModalOrderDetails)));
