import React from "react";
import {connect} from "react-redux";
import styles from "./SettingsModal.module.scss";
import {closePopup, openPopup} from "../../../actions/client";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";
import {ReactComponent as GlobeIcon} from "../../../assets/icons/language.svg";
import {ReactComponent as ArrowRightIcon} from "../../../assets/icons/right.svg";
import {ReactComponent as DotsIcon} from "../../../assets/icons/all.svg";
import {CLIENT_MODAL_TYPES} from "../../../utils/constants";
import {useTranslation} from "react-i18next";

const SettingsModal = ({openPopup, closePopup}) => {

    const {t, i18n} = useTranslation();


    return (
        <div className={styles.settingsModal}>
            <div className="d-flex justify-content-between">
                <div className="big-title">
                    {t("Settings")}
                </div>
                <button
                    onClick={closePopup}
                    className="client-icon-button">
                    <CloseIcon/>
                </button>
            </div>

            <div className="list-menu">
                <li onClick={() => {
                    openPopup(CLIENT_MODAL_TYPES.LANGUAGE)
                }}>
                    <span><GlobeIcon className="mr-4"/>{t("Language")}</span>
                    <ArrowRightIcon/>
                </li>
                <li onClick={() => {
                    openPopup(CLIENT_MODAL_TYPES.LAYOUT)
                }}>
                    <span><DotsIcon className="mr-4"/>{t("Layout")}</span>
                    <ArrowRightIcon/>
                </li>
            </div>

        </div>
    )
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
    openPopup: (type) => dispatch(openPopup(type)),
    closePopup: () => dispatch(closePopup())
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(SettingsModal);

