import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../Button/Button";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";
import {validate} from "../../../utils/validation";
import {inputValidations} from "../../../utils/validation/configurations";
import {askForConfirmation} from "../../../utils";
import {setLoading} from "../../../actions";
import {createTable, getTables} from "../../../actions/tables";
import {connect} from "react-redux";
import Input, {InputStyles} from "../../Input/Input";
import {addProductImage, createProduct, updateProduct} from "../../../actions/products";
import ProductImages from "../../ProductImages/ProductImages";
import {updateBusinessConfigurations} from "../../../actions/account";
import moment from "moment";

class DeliveryConfigurationsSidebar extends Component {

    state = {
        showValidation: false,
        configurations: {
            ...this.props.user_details?.configuration,
            deliveryStartTime: moment.utc(this.props.user_details?.configuration.deliveryStartTime).local().format("HH:mm") + ":00",
            deliveryEndTime: moment.utc(this.props.user_details?.configuration.deliveryEndTime).local().format("HH:mm") + ":00",
        },
    }

    componentDidMount() {
        //
    }

    formIsInvalid = () => {
        const {configurations} = this.state;
        return false;
    }

    handleUpdateConfigurations = async() => {
        const {t} = this.props;
        const {configurations} = this.state;

        if(this.formIsInvalid()) {
            this.setState({showValidation: true});
            return;
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        const configurationsPayload = {
            ...this.state.configurations,
            deliveryStartTime: moment(this.state.configurations.deliveryStartTime, "hh:mm:ss").toISOString(),
            deliveryEndTime: moment(this.state.configurations.deliveryEndTime, "hh:mm:ss").toISOString(),
        };
        console.log(configurationsPayload)
        this.props.setLoading(true);
        this.props.updateBusinessConfigurations(configurationsPayload).then(res => {
            this.props.setLoading(false);
            this.props.onCloseButtonClick();
        });
    }

    render() {
        const {t} = this.props;
        const {configurations, showValidation} = this.state;
        return (
            <div>
                <div className="d-flex justify-content-between mb-16">
                    <h2>{t("Delivery configurations")}</h2>
                    <div className="d-flex">
                        <Button
                            type={ButtonTypes.Icon}
                            onClick={this.props.onCloseButtonClick}>
                            <CloseIcon/>
                        </Button>
                    </div>
                </div>

                <Input
                    label={t("Activate delivery")}
                    id="activateDeliveryInput"
                    name="activateDeliveryInput"
                    type="switch"
                    value={configurations.activateDelivery}
                    style={InputStyles.Material}
                    onChange={(value) => this.setState({configurations: {...configurations, activateDelivery: value}})}/>

                <Input
                    label={t("Delivery start time")}
                    id="deliveryStartTimeInput"
                    name="deliveryStartTimeInput"
                    type="time"
                    value={configurations.deliveryStartTime}
                    style={InputStyles.Material}
                    validations={[inputValidations.deliveryStartTimeInput, showValidation]}
                    onChange={(time) => this.setState({configurations: {...configurations, deliveryStartTime: time}})}/>

                <Input
                    label={t("Delivery end time")}
                    id="deliveryEndTimeInput"
                    name="deliveryEndTimeInput"
                    type="time"
                    value={configurations.deliveryEndTime}
                    style={InputStyles.Material}
                    validations={[inputValidations.deliveryEndTimeInput, showValidation]}
                    onChange={(time) => this.setState({configurations: {...configurations, deliveryEndTime: time}})}/>

                <Input
                    label={t("Free delivery price limit")}
                    id="deliveryPriceLimitInput"
                    name="deliveryPriceLimitInput"
                    type="number"
                    value={configurations.freeDeliveryPriceLimit}
                    style={InputStyles.Material}
                    validations={[inputValidations.deliveryPriceLimitInput, showValidation]}
                    onChange={(e) => this.setState({configurations: {...configurations, freeDeliveryPriceLimit: e.target.value}})}/>

                <Input
                    label={t("Delivery fee")}
                    id="deliveryFeeInput"
                    name="deliveryFeeInput"
                    type="number"
                    value={configurations.deliveryFee}
                    style={InputStyles.Material}
                    validations={[inputValidations.deliveryFeeInput, showValidation]}
                    onChange={(e) => this.setState({configurations: {...configurations, deliveryFee: e.target.value}})}/>

                <Button
                    className="mt-24"
                    size={ButtonSizes.Full}
                    color={ButtonColors.Green}
                    type={ButtonTypes.Filled}
                    onClick={() => this.handleUpdateConfigurations()}>
                    {t("Save")}
                </Button>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user_details: state.app.user_details
});
const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    updateBusinessConfigurations: (data) => dispatch(updateBusinessConfigurations(data)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
const translation = withTranslation();
export default connector(translation(DeliveryConfigurationsSidebar));

