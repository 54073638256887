import React from "react";
import styles from "./DailyMenuCard.module.scss";
import {useTranslation} from "react-i18next";
import {getTitleByLanguage, isTimeBetween, parsePrice} from "../../../utils";
import {BLANK_IMAGE} from "../../../utils/constants";
import Button, {ButtonTypes} from "../../Button/Button";
import {ReactComponent as ArrowRightIcon} from "../../../assets/icons/right.svg";
import classNames from "classnames";
import {useSelector} from "react-redux";

const DailyMenuCard = ({daily_menu, className, onClick}) => {

    const {t, i18n} = useTranslation();
    const image = daily_menu.thumbnail || daily_menu.pictureUrl;
    const business = useSelector(state => state.client.business);

    return (
        isTimeBetween(daily_menu.startTime, daily_menu.endTime) &&
        <div className={classNames(styles.dailyMenuCard, className)}
             onClick={onClick}>
            <div className={styles.dailyMenuCardImage}
                 style={{backgroundImage: "url('" + (image || BLANK_IMAGE) + "')"}}/>
            <div className={styles.dailyMenuCardcontent}>
                <p className={styles.dailyMenuCardTitle}>{getTitleByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, daily_menu)}</p>
                <p className={styles.dailyMenuCardSubtitle}>{daily_menu.totalPrice}€</p>
            </div>
            <Button
                type={ButtonTypes.Icon}>
                <ArrowRightIcon/>
            </Button>
        </div>
    )
}

export default DailyMenuCard;
