import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../Button/Button";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";
import {validate} from "../../../utils/validation";
import {inputValidations} from "../../../utils/validation/configurations";
import {askForConfirmation} from "../../../utils";
import {setLoading} from "../../../actions";
import {createTable, getTables, updateTable} from "../../../actions/tables";
import {connect} from "react-redux";
import Input, {InputStyles} from "../../Input/Input";

class EditTableSidebar extends Component {

    state = {
        showValidation: false,
        table: this.props.table
    }

    componentDidMount() {
        //
    }

    updateTable = async() => {
        const {table} = this.state;
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.updateTable(table.id, table).then(res => {
            this.props.getTables();
            this.props.onCloseButtonClick();
            this.props.setLoading(false);
        })
    }

    render() {
        const {t} = this.props;
        const {table, showValidation} = this.state;
        return (
            <div>
                <div className="d-flex justify-content-between mb-16">
                    <h2>{t("Edit Table")}</h2>
                    <div className="d-flex">
                        <Button
                            type={ButtonTypes.Icon}
                            onClick={this.props.onCloseButtonClick}>
                            <CloseIcon/>
                        </Button>
                    </div>
                </div>
                <Input
                    label={t("Table name")}
                    id="tableNameInput"
                    name="tableNameInput"
                    value={table.name}
                    style={InputStyles.Material}
                    validations={[inputValidations.tableNameInput, showValidation]}
                    onChange={(e) => this.setState({table: {...table, name: e.target.value}})}/>
                    <Button
                        className="mt-24"
                        size={ButtonSizes.Full}
                        color={ButtonColors.Green}
                        type={ButtonTypes.Filled}
                        onClick={() => this.updateTable()}>
                        {t("Edit Table")}
                    </Button>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    getTables: data => dispatch(getTables()),
    updateTable: (id, data) => dispatch(updateTable(id, data))
});
const connector = connect(null, mapDispatchToProps);
export default connector(withTranslation()(EditTableSidebar));

