import React from "react";

const Bar = ({ percentage, count }) => (
    <div className="bar">
        <div className="progress-bar progress-bar-green"
             aria-valuemin="0"
             aria-valuemax="100"
             role="progressbar"
             style={{width: percentage + "%"}} />
    </div>

)

export default Bar;
