import React from "react";
import styles from "./Step.module.scss";
import {ReactComponent as Check} from "../../assets/icons/check.svg"
import classNames from "classnames";

const Step = ({ text, current, finished }) => {
    const classes = classNames(styles.step,
        {[styles.current]: current, [styles.finished]: finished });

    return (
        <div className={classes}>
            {finished ? <Check/> : text}
        </div>
    )

}

export default Step;
