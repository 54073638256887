import React from "react";
import "./Tables.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faQrcode} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import Card from "../../Cards/Card/Card";

export default function Tables({tables, onClickEdit, onClickDelete, onClickDownload}) {

    const {t, i18n} = useTranslation();

    const cardMenu = (table) => {
        return [
            { label: t("Edit"), handleClick: () => onClickEdit(table) },
            { label: t("Download QR Code"), handleClick: () => onClickDownload(table) },
            // { label: "Delete", handleClick: () => onClickDelete(table) }
        ]
    }

    return (
        tables.length > 0 ?
            <div className="grid">
                {tables.map((table, index) => (
                    <Card
                        menu={cardMenu(table)}
                        title={`${t("Table")} ${table.name}`}
                        subtitle={`${t("Number")} ${table.number}`}/>
                ))}
            </div>
            : t("No tables") + "..."
    )
}
