import React, {Component} from "react";
import "./Statistics.scss";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine, faPlus} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {getStatistics} from "../../../actions/statistics";
import {STATISTIC_TYPES} from "../../../utils/constants";
import Statistic from "../../../components/Cards/Statistic/Statistic";
import Button, {ButtonColors} from "../../../components/Button/Button";


class Statistics extends Component {

    constructor(props) {
        super(props);

        this.state = {
            day: "0",
            week: "0",
            month: "0",
            total: "0"
        }
    }

    componentDidMount() {
        const type = STATISTIC_TYPES.SCANNING;
        const first_date = "2020-07-15";

        let createdAt = moment().format("yyyy-MM-DD");
        getStatistics({type, createdAt}).then(res => this.setState({day: res.data.count}));

        createdAt = moment().day(1).format("yyyy-MM-DD");
        getStatistics({type, createdAt}).then(res => this.setState({week: res.data.count}));

        createdAt = moment().date(1).format("yyyy-MM-DD");
        getStatistics({type, createdAt}).then(res => this.setState({month: res.data.count}));

        createdAt = first_date;
        getStatistics({type, createdAt}).then(res => this.setState({total: res.data.count}));

    }

    render() {
        const {total, month, week, day} = this.state;
        const {t} = this.props;
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-header-row">
                            <h1>{t("Statistics")}</h1>
                        </div>
                    </div>
                    <div className="col-lg-3 col-xs-6">
                        <Statistic text={t("Visits today")} number={day} />
                    </div>
                    <div className="col-lg-3 col-xs-6">
                        <Statistic text={t("Visits this week")} number={week} />
                    </div>
                    <div className="col-lg-3 col-xs-6">
                        <Statistic text={t("Visits this month")} number={month} />
                    </div>
                    <div className="col-lg-3 col-xs-6">
                        <Statistic text={t("Total visits")} number={total} />
                    </div>
                </div>
            </>
        );
    }

}

export default withRouter(withTranslation()(Statistics));
