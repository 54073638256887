import React, {Component} from "react";
import "./Daily.scss";
import {connect} from "react-redux";
import {getProducts,} from "../../../actions/products";
import Modal from "../../../components/Modal/Modal";
import {askForConfirmation, productExists, search} from "../../../utils";
import {Link, withRouter} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import DragAndDrop, {DraggableTypes} from "../../../components/Draggable/DragAndDrop";
import {withTranslation} from "react-i18next";
import {
    addDailyMenuProducts,
    getDailyMenu,
    removeProductFromDailyMenu,
    updateDailyMenuProductsOrdering
} from "../../../actions/daily";
import {setLoading} from "../../../actions";
import Button, {ButtonColors, ButtonTypes} from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import Drawer from "../../../components/Drawer/Drawer";
import AddProductsSidebar from "../../../components/Sidebars/AddProductsSidebar/AddProductsSidebar";

class Daily extends Component {

    constructor(props) {
        super(props);

        this.state = {
            daily_title: "",
            daily_menu_title: "",
            daily_products: [],
            selected_products: [],
            isModalOpen: false,
            search_text: "",
            sidebar_opened: false
        }
    }

    componentDidMount() {
        const dailyId = this.props.match.params.dailyId;

        if (this.props.daily_menus) {
            const daily_menu = this.props.daily_menus.find(daily_menu => daily_menu.id == dailyId);
            if (daily_menu) {
                this.setState({daily_menu_title: daily_menu.title});
            }
        }

        const {products} = this.props;
        this.props.getProducts();
        this.props.getDailyMenu(dailyId).then(res => {
            this.setState({daily_products: res.data.products});
        })
    }

    openModal = () => {
        this.setState({isModalOpen: true});
    }

    closeModal = () => {
        this.setState({isModalOpen: false, selected_products: [], showValidation: false, sidebar_opened: false});
    }

    handleSelectProduct = e => {
        const value = parseInt(e.target.value);
        const checked = e.target.checked;
        let {selected_products} = this.state;
        if (checked && !selected_products.includes(value)) {
            selected_products.push(value);
        } else if (!checked && selected_products.includes(value)) {
            selected_products.splice(selected_products.indexOf(value), 1);
        }
        this.setState({selected_products});
    }

    handleAddProducts = async(products) => {
        const dailyId = this.props.match.params.dailyId;

        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.addDailyMenuProducts(dailyId, products).then(res => {
            this.props.getProducts();
            this.props.getDailyMenu(dailyId).then(res => {
                this.setState({daily_products: res.data.products});
                this.closeModal();
                this.props.setLoading(false);
            })
        })

    }

    onClickDelete = async(item, e) => {
        e.stopPropagation();
        const dailyId = this.props.match.params.dailyId;
        const productId = item.id;
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.removeProductFromDailyMenu(dailyId, productId).then(res => {
            this.props.getDailyMenu(dailyId).then(res => {
                this.setState({daily_products: res.data.products});
                this.closeModal();
                this.props.setLoading(false);
            })
        })
    }

    openAddProductsSidebar = () => {
        this.setState({
            sidebar_opened: true,
        })
    }

    render() {
        const {isModalOpen, daily_menu_title, search_text, sidebar_opened} = this.state;
        const {t} = this.props;
        const products = search(this.props.products, search_text, "name");
        const daily_products = search(this.state.daily_products, search_text, "name");

        return (
            <div className="daily-page">

                <div className="page-header-row">
                    <h1>{t("Products")}</h1>
                    <div className="d-flex">
                        <Button
                            color={ButtonColors.Green}
                            onClick={() => this.openAddProductsSidebar()}>
                            + {t("Add Products")}
                        </Button>
                    </div>
                </div>

                <div className="page-search-row">
                    <Input
                        type="text"
                        id="searchTablesInput"
                        name="searchTablesInput"
                        placeholder="Search for products"
                        value={search_text}
                        onChange={(e) => this.setState({search_text: e.target.value})}/>
                </div>

                <div className="box-body">
                    {daily_products.length > 0 ?
                        <DragAndDrop
                            isDragDisabled
                            items={daily_products}
                            type={DraggableTypes.PRODUCT}
                            onClickDelete={(item, e) => this.onClickDelete(item, e)}
                            onDragEnd={(result) => this.onDragEnd(result)}/> : t("No products") + "..."}
                </div>

                <Drawer
                    open={sidebar_opened}>
                    <AddProductsSidebar
                        onSubmit={(products) => this.handleAddProducts(products)}
                        submenuProducts={daily_products}
                        onCloseButtonClick={this.closeModal}/>
                </Drawer>

                <Modal
                    isModalOpen={isModalOpen}
                    handleClose={this.closeModal}
                    handleSubmit={() => this.handleAddProducts()}
                    title={t("Add Products")}>

                    <div className="submenu-products-container">

                        <Input
                            autoComplete="off"
                            label={t("Search")}
                            value={search_text}
                            id="search-input"
                            name="search-input"
                            onChange={(e) => this.setState({search_text: e.target.value})}/>

                        <div className="list-group">
                            {products.map((product, index) => (
                                !productExists(daily_products, product.id) &&
                                <div className="list-group-item list-group-item-action py-1 pl-2 pr-3">
                                    <div className="row">
                                        <div className="col-1">
                                            <input type="checkbox"
                                                   className="h-100"
                                                   id={"product-" + index}
                                                   value={product.id}
                                                   onChange={(e) => this.handleSelectProduct(e)}/>
                                        </div>
                                        <div className="col">
                                            <label htmlFor={"product-" + index}
                                                   className="w-100 m-0">
                                                {product.name}
                                                <span className="float-right text-right">{product.price}€</span>
                                                <br/>
                                                <span
                                                    className="text-secondary text-sm">{product.description}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        products: state.app.products,
        daily_menus: state.app.daily_menus
    }
}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    getDailyMenu: id => dispatch(getDailyMenu(id)),
    getProducts: data => dispatch(getProducts(data)),
    addDailyMenuProducts: (dailyId, data) => dispatch(addDailyMenuProducts(dailyId, data)),
    removeProductFromDailyMenu: (dailyId, productId) => dispatch(removeProductFromDailyMenu(dailyId, productId)),
    updateDailyMenuProductsOrdering: (dailyId, data) => dispatch(updateDailyMenuProductsOrdering(dailyId, data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Daily)));
