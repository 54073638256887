import React from "react";
import "./Banner.scss";
import {useTranslation, withTranslation} from "react-i18next";

const Banner = ({banner, handleClick}) => {
    
    const {t, i18n} = useTranslation();
    
    return (
        <div className="banner-card"
             onClick={handleClick}
             style={{backgroundImage: "url('" + banner.pictureUrl + "')"}}>
        </div>
    )
}

export default Banner;
