import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from "./utils/locales/en.json";
import sq from "./utils/locales/sq.json";
import fr from "./utils/locales/fr.json";
import nl from "./utils/locales/nl.json";

// i18n.changeLanguage('en-US');


const resources = {
    en: en,
    sq: sq,
    fr: fr,
    nl: nl,
}

i18n
    .use(initReactI18next)
    .init({

        resources,

        //options
        lng: 'en',
        fallbackLng: 'en',
        // debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: false
        }

    });


export default i18n;
