import moment from "moment";

export const GET_TABLES = "GET_TABLES";
export const GENERATE_TABLES = "GENERATE_TABLES";

export const GET_ORDERS = "GET_ORDERS";
export const GET_DELIVERY_ORDERS = "GET_DELIVERY_ORDERS";

export const GET_DAILY_MENUS = "GET_DAILY_MENUS";
export const SET_DAILY_MENUS = "SET_DAILY_MENUS";
export const CREATE_DAILY_MENU = "CREATE_DAILY_MENU";
export const DELETE_DAILY_MENU = "DELETE_DAILY_MENU";

export const GET_BANNERS = "GET_BANNERS";
export const SET_BANNERS = "SET_BANNERS";
export const CREATE_BANNER = "CREATE_BANNER";
export const DELETE_BANNER = "DELETE_BANNER";

export const GET_FEEDBACKS = "DELETE_BANNER";

export const GET_EVENTS = "GET_EVENTS";
export const SET_EVENTS = "SET_EVENTS";
export const CREATE_EVENT = "CREATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";

export const GET_BUSINESSES = "GET_BUSINESSES";

export const GET_MENUS = "GET_MENUS";
export const SET_MENUS = "SET_MENUS";
export const CREATE_MENU = "GET_MENU";
export const DELETE_MENU = "DELETE_MENU";

export const GET_SUBMENUS = "GET_SUBMENUS";
export const GET_SUBMENU = "GET_SUBMENU";
export const SET_SUBMENUS = "SET_SUBMENUS";
export const CREATE_SUBMENU = "CREATE_SUBMENU";
export const DELETE_SUBMENU = "DELETE_SUBMENU";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";

export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";
export const GET_BUSINESS_DETAILS = "GET_BUSINESS_DETAILS";
export const FETCHED_USER_DETAILS = "FETCHED_USER_DETAILS";
export const GET_BUSINESS = "GET_BUSINESS";

export const UPDATE_WISHLIST = "UPDATE_WISHLIST";

export const SET_DELIVERY_ENABLED = "SET_DELIVERY_ENABLED";

export const SET_LAYOUT_TYPE = "SET_LAYOUT_TYPE";

export const SET_DAILY_MENU = "SET_DAILY_MENU";
export const SET_EVENT = "SET_EVENT";
export const SET_IMAGE = "SET_IMAGE";
export const SET_PRODUCT = "SET_PRODUCT";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const OPEN_POPUP = "OPEN_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";

export const FIREBASE_REGISTER = "FIREBASE_REGISTER";
export const FIREBASE_UNREGISTER = "FIREBASE_UNREGISTER";

export const GET_RATINGS = "GET_RATINGS";

export const SET_LOADING = "SET_LOADING";

export const CALL_WAITER_SECONDS = 10;


export const PRODUCT_SORTING_TYPES = {
    RATING_HIGHEST: "RATING_HIGHEST",
    RATING_LOWEST: "RATING_LOWEST"
}

export const SUBMENU_MODAL_TYPES = {
    EDIT: "EDIT",
    CREATE: "CREATE",
    ADD: "ADD",
    IMAGES: "IMAGES"
}

export const PRODUCT_MODAL_TYPES = {
    IMAGES: "IMAGES",
    FORM: "FORM",
    RATING: "RATING"
}

export const DEVICE_TYPES = {
    IOS: "iOS",
    WINDOWS: "WINDOWS",
    ANDROID: "ANDROID"
}

export const CLIENT_PAGES = {
    MENUS_PAGE: "MENUS_PAGE",
    PRODUCTS_PAGE: "PRODUCTS_PAGE",
    DETAILS_PAGE: "DETAILS_PAGE"
}

export const ACCOUNT_PAGE_TABS = {
    PROFILE: "profile",
    CONFIGURATIONS: "configurations",
    PASSWORD: "password",
}

export const FORGOT_PASSWORD_PAGES = {
    EMAIL_PAGE: "EMAIL_PAGE",
    NEW_PASSWORD_PAGE: "NEW_PASSWORD_PAGE"
}

export const CLIENT_MODAL_PAGES = {
    CART: "cart",
    FORM: "form",
    ORDERS: "orders",
    ORDER_DETAILS: "order-details"
}

export const CLIENT_MODAL_TYPES = {
    WISHLIST: "WISHLIST",
    DAILY_MENU: "DAILY_MENU",
    IMAGE: "IMAGE",
    EVENT: "EVENT",
    FEEDBACK: "FEEDBACK",
    NOTIFICATIONS: "NOTIFICATIONS",
    SEARCH: "SEARCH",
    SETTINGS: "SETTINGS",
    LANGUAGE: "LANGUAGE",
    RATING: "RATING",
    BUSINESS: "BUSINESS",
    SHOW_MORE: "SHOW_MORE",
    LAYOUT: "LAYOUT",
}

export const STATISTIC_TYPES = {
    SCANNING: "SCANNING"
}

export const EMPTY_MENU_OBJECT = {
    title: "",
    photoUrl: ""
}

export const TABLE_MODAL_PAGES = {
    CREATE: "CREATE",
    EDIT: "EDIT",
    GENERATE: "GENERATE"
}

export const WAITER_SIDEBAR_CONTENT = {
    ORDERS: "orders",
    ORDER_DETAILS: "order-details",
    EDIT_TABLE: "edit-table",
    CREATE_TABLE: "create-table",
    GENERATE_TABLES: "generate-tables"
}

export const SUBMENU_SIDEBAR_CONTENT = {
    CREATE_PRODUCT: "create-product",
    EDIT_PRODUCT: "edit-product",
    ADD_PRODUCTS: "add-products",
}

export const DAILY_MENUS_SIDEBAR_CONTENT = {
    CREATE_DAILY_MENU: "create-daily-menu",
    EDIT_DAILY_MENU: "edit-daily-menu",
}

export const PRODUCTS_SIDEBAR_CONTENT = {
    CREATE_PRODUCT: "create-product",
    EDIT_PRODUCT: "edit-product",
    RATINGS: "RATINGS",
}

export const DELIVERY_SIDEBAR_CONTENT = {
    ORDER_DETAILS: "order-details",
    CONFIGURATIONS: "configurations",
}

export const ORDER_STATUSES = {
    APPROVED: "APPROVED",
    DECLINED: "DECLINED",
    SENT: "SENT"
}

export const LAYOUT_TYPES = {
    GRID: "grid",
    LIST: "list"
}

export const EMPTY_DAILY_MENU_OBJECT = {
    title: "",
    titleEn: "",
    description: "",
    descriptionEn: "",
    videoUrl: "",
    pictureUrl: "",
    totalPrice: "",
    startTime: moment().format("HH:mm") + ":00",
    endTime: moment().format("HH:mm") + ":00"
}

export const EMPTY_BANNER_OBJECT = {
    description: "",
    videoUrl: "",
    pictureUrl: "",
    startTime: "",
    endTime: ""
}

export const EMPTY_SUBMENU_OBJECT = {
    title: "",
    titleEn: "",
    description: "",
    descriptionEn: "",
    category: "",
    logo: "",
    icon: ""
}

export const EMPTY_PRODUCT_OBJECT = {
    name: "",
    nameEn: "",
    price: "",
    description: "",
    descriptionEn: "",
    preparingTime: "",
    images: []
}

export const EMPTY_EVENT_OBJECT = {
    link: "",
    title: "",
    description: "",
    pictureUrl: ""
}

export const STAR_TYPES = {
    FULL: "FULL",
    HALF: "HALF",
    EMPTY: "EMPTY",
}

export const RATING_LEVELS = {
    FIVE: "FIVE",
    FOUR: "FOUR",
    THREE: "THREE",
    TWO: "TWO",
    ONE: "ONE",

}

export const RATING_VALUES = {
    [RATING_LEVELS.ONE]: 1,
    [RATING_LEVELS.TWO]: 2,
    [RATING_LEVELS.THREE]: 3,
    [RATING_LEVELS.FOUR]: 4,
    [RATING_LEVELS.FIVE]: 5,
}

export const RATING_PERCENTAGES_OBJECT = {
    [RATING_LEVELS.FIVE]: {count: 0, percentage: 0},
    [RATING_LEVELS.FOUR]: {count: 0, percentage: 0},
    [RATING_LEVELS.THREE]: {count: 0, percentage: 0},
    [RATING_LEVELS.TWO]: {count: 0, percentage: 0},
    [RATING_LEVELS.ONE]: {count: 0, percentage: 0},
}

export const compression_options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
}

export const thumbnail_compression_options = {
    maxSizeMB: 0.01,
    maxWidthOrHeight: 400,
    useWebWorker: true
}

export const BLANK_IMAGE = "https://filestore.community.support.microsoft.com/api/images/72e3f188-79a1-465f-90ca-27262d769841";
export const COOKIE_NAME = "user-cookie-id-browser";
export const VERSION = "2.1.4";
// €
