import React, {useState} from "react";
import {CLIENT_MODAL_PAGES} from "../../../utils/constants";
import {useTranslation} from "react-i18next";
import styles from "./DailyMenuModal.module.scss";
import {closeModal, updateWishlist} from "../../../actions/client";
import {
    addProductToWishlist,
    decreaseQuantityOfWishlistProduct, getDescriptionByLanguage,
    getProductWishlistQuantity,
    getTitleByLanguage,
    increaseQuantityOfWishlistProduct,
    isAddedToWishlist as productIsAddedToWishlist,
    isAddedToWishlist,
    removeProductFromWishlist
} from "../../../utils";
import {connect, useSelector} from "react-redux";
import Quantity from "../../Quantity/Quantity";
import ProductCard from "../../Cards/Product/ProductCard";
import {withRouter} from "react-router-dom";
import Button, {ButtonColors, ButtonTypes} from "../../Button/Button";
import classNames from "classnames";
import {ReactComponent as HeartIcon} from "../../../assets/icons/favorite.svg";

const DailyModal = ({ daily_menu, updateWishlist, closeModal, history, is_ordering_enabled }) => {

    const { t, i18n } = useTranslation();
    const business = useSelector(state => state.client.business);

    const increaseWishlistQuantity = (product) => {
        increaseQuantityOfWishlistProduct(product);
        updateWishlist();
    }

    const decreaseWishlistQuantity = (product) => {
        decreaseQuantityOfWishlistProduct(product)
        updateWishlist();
    }

    const openProduct = (product) => {
        history.push(`/menu/product/${product.id}`);
        closeModal();
    }

    const onWishlistButtonClick = (product) => {
        const isAddedToWishlist = productIsAddedToWishlist(product);
        if (isAddedToWishlist) {
            removeProductFromWishlist(product);
        } else {
            addProductToWishlist(product);
        }
        updateWishlist();
    }

    return (
        <div className={styles.dailyMenuModal}>
            <div className="big-title">
                {getTitleByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, daily_menu)}
            </div>
            <div className="medium-title text-primary">
                {daily_menu.totalPrice}€
            </div>

            {is_ordering_enabled && <p className="mt-24">{t("Order")}</p>}

            {is_ordering_enabled &&
            <Quantity
                inverse
                quantity={getProductWishlistQuantity(daily_menu.product)}
                onMinusButtonClick={() => decreaseWishlistQuantity(daily_menu.product)}
                onPlusButtonClick={() => increaseWishlistQuantity(daily_menu.product)} />}

            {!is_ordering_enabled &&
            <Button
                    type={ButtonTypes.Icon}
                    className={classNames(styles.wishlistButton, {[styles.active]: isAddedToWishlist(daily_menu.product)})}
                    onClick={() => onWishlistButtonClick(daily_menu.product)}>
                <HeartIcon/>
            </Button>}

            <p className="mt-24">{t("Items")}</p>
            {daily_menu.products.map(product =>
                <ProductCard
                    hidePrice product={product}
                    onClick={() => openProduct(product)}  /> )}

            <p className="mt-24">Description</p>
            <p className="text-tertiary">
                {getDescriptionByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, daily_menu)}
            </p>
        </div>
    )
}

const mapStateToProps = state => ({
    wishlist: state.client.wishlist,
    is_ordering_enabled: state.client.is_ordering_enabled
});
const mapDispatchToProps = dispatch => ({
    updateWishlist: () => dispatch(updateWishlist()),
    closeModal: () => dispatch(closeModal())
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(DailyModal));
