
export const validationMessageTypes = {
    CLEAR: "clear",
    ERROR: "error",
}

export const errorValidationMessage = (message) => {
    return {
        message: validationMessages[message],
        type: validationMessageTypes.ERROR
    }
}

export const noValidationMessage = {
    message: "",
    type: validationMessageTypes.CLEAR
}

export const validationMessages = {
    isNumber: "Ju lutemi shenoni numër!",
    isNotEmpty: "Ju lutemi shenoni një vlerë!",
    isPositiveNumber: "Ju lutemi shenoni një numër pozitiv!",
}



