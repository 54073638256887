import {getItemsPos, slidingWindow} from "react-horizontal-scrolling-menu";

export function onWheel(
    { getItemById, items, visibleItems, scrollToItem },
    ev
) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        // NOTE: for center items
        const nextGroupItems = slidingWindow(
            items.toItemsKeys(),
            visibleItems
        ).next();
        const { center } = getItemsPos(nextGroupItems);
        scrollToItem(getItemById(center), "smooth", "center");
    } else if (ev.deltaY > 0) {
        const prevGroupItems = slidingWindow(
            items.toItemsKeys(),
            visibleItems
        ).prev();
        const { center } = getItemsPos(prevGroupItems);
        scrollToItem(getItemById(center), "smooth", "center");
    }
}
