import axios from "axios";
import * as constants  from "../utils/constants";

const url = process.env.REACT_APP_BACKEND_URL;

export const addLogo = data => {
    const token = localStorage.getItem("access_token");
    const formData = new FormData();
    formData.append('file', data);
    return function(dispatch) {
        return axios.post(url + `businesses/logos`, formData,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const deleteLogo = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.delete(url + `businesses/logos`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const changePassword = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.post(url + `reset-password`, data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const updateBusinessConfigurations = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.put(url + `businesses/configurations`, data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}
