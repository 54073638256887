import React from "react";
import styles from "./Dots.module.scss";
import classNames from "classnames";

const Dots = ({selected, count, className}) => {
    const items = Array.from(Array(count).keys())
    return (
        <ul className={classNames(styles.dots, className)}>
            {items.map((item, index) =>
                <li key={`${item}-dot`} className={classNames(styles.dot, {[styles.selected]: selected === index})} /> )}
        </ul>
    )
}

export default Dots;
