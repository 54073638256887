import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import styles from "./WishlistModalForm.module.scss"
import Input, {InputStyles} from "../../../Input/Input";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../../Button/Button";
import {inputValidations} from "../../../../utils/validation/configurations";
import {validate} from "../../../../utils/validation";

class WishlistModalForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            phone: "",
            address: "",
            showValidations: false
        }
    }

    createOrder = () => {

        if(this.formIsInvalid()) {
            this.setState({ showValidations: true });
            return
        }

        const { address, email, phone } = this.state;
        const payload = {
            address: address,
            deliveryUser :{
                email: email,
                phoneNumber: phone
            }
        }
        this.props.onCreateOrder(payload);
    }

    formIsInvalid = () => {
        const { address, email, phone } = this.state;
        return validate(inputValidations.deliveryAddressInput, address) ||
            validate(inputValidations.deliveryEmailInput, email) ||
            validate(inputValidations.deliveryPhoneInput, phone);
    }

    render() {
        const {t, i18n, loading} = this.props;
        const {email, phone, address, showValidations} = this.state;

        return (
            <div className={styles.wishlistModalForm}>

                <h2 className="mt-48">{t("Please fill in your data")}</h2>

                <Input
                    type="text"
                    value={phone}
                    className="mt-24"
                    id="deliveryPhoneInput"
                    placeholder={t("Phone number")}
                    style={InputStyles.Standard}
                    validations={[inputValidations.deliveryPhoneInput, showValidations]}
                    onChange={(e) => this.setState({ phone: e.target.value })}/>

                <Input
                    type="text"
                    value={email}
                    className="mt-24"
                    id="deliveryEmailInput"
                    placeholder={t("Email")}
                    style={InputStyles.Standard}
                    validations={[inputValidations.deliveryEmailInput, showValidations]}
                    onChange={(e) => this.setState({ email: e.target.value })}/>

                <Input
                    type="text"
                    value={address}
                    className="mt-24"
                    id="deliveryAddressInput"
                    placeholder={t("Address")}
                    style={InputStyles.Standard}
                    validations={[inputValidations.deliveryAddressInput, showValidations]}
                    onChange={(e) => this.setState({ address: e.target.value })}/>

                <Button
                    className="mt-48"
                    loading={loading}
                    disabled={loading}
                    size={ButtonSizes.Full}
                    type={ButtonTypes.Filled}
                    color={ButtonColors.Green}
                    onClick={this.createOrder}>
                    { t("Complete order") }
                </Button>
            </div>
        )
    }

}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withTranslation()(WishlistModalForm));
