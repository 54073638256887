
    export const MISSING_INFORMATION=["0000", "Could not precede due to missing information"];
    export const INVALID_UUI=["0001", "UUID doesnt match with token one. Update only your data!"];
    export const INVALID_INPUT=["0002", "The input given is either empty or null!"];
    export const EMPLOYEE_BUSY=["0003", "Employees might be busy at this day time!"];
    export const CHECK_APPOINTMENT_UNKNOWN_EXCEPTION=["0004", "Check appointment unknown exception!"];
    export const BLACK_LIST_ADDRESS=["0005", "Your ip ip_address has been blocked due to malicious activities. If tried again, we will proceed your information to the Investigation Department!"];
    export const INVALID_COOKIE=["0006", "Invalid cookie request. Contact support!"];
    export const BLACK_LIST_ADDRESS_CANT_SAVE=["0007", "Could not save black ip address in database!"];


    export const RESET_PASSWORD_CODE_COULD_NOT_SAVE=["1111", "Could not send save password code in database"];
    export const RESET_PASSWORD_INVALID_CODE=["1112", "The given code is not valid"];
    export const RESET_PASSWORD_EXPIRED_CODE=["1113", "The given code has expired"];
    export const EMAIL_COULD_NOT_SEND_EMAIL=["2220", "Could not send email to send grid!"];

    export const BUSINESS_COULD_NOT_SAVE=["3000", "Could not save business in database"];
    export const BUSINESS_COULD_NOT_FIND=["3001", "Could not find business in database"];
    export const BUSINESS_COULD_NOT_UPDATE=["3002", "Could not update business in database"];
    export const BUSINESS_TYPE_COULD_NOT_FIND=["3003", "Incorrect business type. This business type is not supported"];

    export const USER_COULD_NOT_SAVE=["2000", "Could not save user in database"];
    export const USER_COULD_NOT_FIND=["2001", "Could not find user in database"];
    export const USER_BAD_CREDENTIALS=["2002", "Bad credentials."];
    export const USER_COULD_NOT_UPDATE=["2003", "Could not update user in database"];
    export const USER_BUSINESS_INVALID_REQUEST=["2004", "You can't use this endpoint to update business admin user or deactivate it. This endpoint is used only for deactivating employees."];

    export const PN_COULD_NOT_REGISTER_DEVICE_FOR_TOPIC=["4000", "Could not register device for topic!"];
    export const PN_FAILED_TO_SEND_PUSH_NOTIFICATION=["4001", "Could not send push notification to FCM!"];

    export const PRODUCT_IMAGE_COULD_NOT_SAVE=["5001", "Could not save product image in database"];
    export const PRODUCT_IMAGE_COULD_NOT_DELETE=["5002", "Could not delete product image from database"];
    export const PRODUCT_IMAGE_COULD_NOT_FIND=["5003", "Could not find product image in database"];

    export const MENU_COULD_NOT_FOUND=["6000", "Could not find menu with given id"];
    export const MENU_COULD_NOT_UPDATE=["6001", "Could not update menu with given id"];

    export const SUB_MENU_COULD_NOT_FOUND=["7000", "Could not find sub menu with given id"];
    export const PRODUCT_COULD_NOT_DELETE=["8000", "Could not delete products from database."];

    export const TABLE_COULD_NOT_FIND=["9000", "Could not find table with given id."];
    export const TABLE_COULD_NOT_UPDATE=["9001", "Could not update table with given id."];


    export const BUSINESS_LOGO_FAILED_TO_SAVE_LOGO=["10000", "Could not save business logo."];
    export const BUSINESS_LOGO_FAILED_TO_DELETE_LOGO=["10001", "Could not delete business logo."];

    export const FCM_COULD_NOT_SAVE_TOKEN=["11000","Could not save user FCM token in database"];
    export const FCM_COULD_NOT_SEND_TOKEN=["11001","Could not send FCM token to device"];
    export const FCM_COULD_SAVE_NOTIFICATION_LOCALLY=["11002","Could not save notification locally"];
    export const FCM_COULD_UPDATE_NOTIFICATION_LOCALLY=["11003","Could not update notification locally"];



    export const CALL_WAITER_INVALID_DISTANCE=["12000","Could not call waiter, distance is too large!"];
    export const CALL_WAITER_NOT_SUPPORTED=["12001","BusinessHeader doesn't support call waiter!"];

    export const ORDERING_COULD_NOT_UPDATE=["15002", "Could not ordering for menu/submenu!"];
    export const DUPLICATE_ORDERING_IDS=["15003", "Duplicate ordering values. Order values should be distinct. Duplicated values are: "];

    export const PRODUCT_SUBMENU_COULD_NOT_FOUND=["16000", "Could not find product from submenu with given id"];
    export const PRODUCT_SUBMENU_COULD_NOT_DELETE=["16001", "Could not delete product from submenu from database."];

    export const CLIENT_COULD_NOT_SAVE=["17001", "Could not save client in database"];
    export const CLIENT_COULD_NOT_FIND=["17001", "Could not find client in database"];

    export const EMPLOYEE_COULD_NOT_SAVE=["18000", "Could not save employee in database"];
    export const EMPLOYEE_COULD_NOT_FIND=["18001", "Could not find employee in database"];
    export const EMPLOYEE_IS_INACTIVE=["18002", "Could not find active employee. Found employee is deactivated!"];

    export const ORDER_COULD_NOT_SAVE=["19000", "Could not save order in database"];
    export const ORDER_COULD_NOT_FIND=["19001", "Could not find order in database"];
    export const ORDER_COULD_NOT_UPDATE=["19002", "Could not update order in database"];

    export const ORDER_ITEM_COULD_NOT_SAVE=["20000", "Could not save order item in database"];
    export const ORDER_ITEM_COULD_NOT_FIND=["20001", "Could not find order item in database"];
    export const ORDER_ITEM_COULD_NOT_DELETE=["20002", "Could not delete order item in database"];

    export const IMAGE_FAILED_TO_LOAD_BYTES=["10000","Failed to load bytes from given multipart file while saving on cloudinary!"];
    export const MEDIA_COULD_NOT_SAVE=["10001", "Could not save media on cloudinary"];
    export const MEDIA_COULD_NOT_DELETE=["10002", "Could not delete media on cloudinary"];

    export const BANNER_COULD_NOT_SAVE=["20000", "Could not save banner card on database"];
    export const BANNER_COULD_NOT_FIND=["20001", "Could not find banner card on database"];
    export const BANNER_COULD_NOT_DELETE=["20002", "Could not delete banner card on database"];

    export const DAILY_MENU_COULD_NOT_SAVE=["30000", "Could not save daily menu in database"];
    export const DAILY_MENU_COULD_NOT_FIND=["30001", "Could not find daily menu in database"];
    export const DAILY_MENU_COULD_NOT_DELETE=["30002", "Could not delete daily menu in database"];

    export const DAILY_MENU_PRODUCT_COULD_NOT_SAVE=["40000", "Could not save find daily menu product in database"];


    export const PRODUCT_RATE_COULD_NOT_SAVE=["50000", "Could not save product rate in database"];
    export const PRODUCT_RATE_COULD_NOT_FIND=["50001", "Could not find product rate in database"];

    export const FEEDBACK_COULD_NOT_SAVE=["60000", "Could not save feedback in database"];
    export const FEEDBACK_COULD_NOT_FIND=["60001", "Could not find feedback in database"];
