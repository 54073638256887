import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import styles from "./ProductImages.module.scss";
import {setLoading} from "../../actions";
import {updateProduct} from "../../actions/products";
import {ReactComponent as PlusIcon} from "../../assets/icons/plus-2.svg"
import {ReactComponent as RemoveIcon} from "../../assets/icons/x.svg"
import {imageObjectToUrl} from "../../utils";

class ProductImages extends Component {

    state = {
        imagesToRemove: [],
        images: []
    }

    componentDidMount() {
        this.setState({images: this.props.images});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.images != prevProps.images){
            this.setState({images: this.props.images});
        }
    }

    inputRef = null;

    onChange = e => {
        const {images, imagesToRemove} = this.state;
        const image = e.target.files?.[0];
        const imageUrl = imageObjectToUrl(e.target.files?.[0]);
        if(!image){
            return;
        }
        this.inputRef.value = "";
        const newImages = [ ...images, {link: imageUrl, file: image, isLocal: true} ];
        this.setState({
            images: newImages,
        },() => {
            const imagesToAdd = newImages.filter(image => image.isLocal);
            this.props.onChange({imagesToRemove: imagesToRemove, imagesToAdd: imagesToAdd});
        });
    }

    onRemove = index => {
        const {images} = this.state;
        const {imageUrls, imagesToRemove} = this.state;
        const newImages = [...images];

        if(!(newImages[index]?.isLocal)) {
            const newImagesToRemove = [...imagesToRemove, newImages[index]['id']];
            this.setState({imagesToRemove: newImagesToRemove});
        }

        newImages.splice(index, 1);

        this.setState({images: newImages},
            () => {
                const {imagesToRemove} = this.state;
                const imagesToAdd = newImages.filter(image => image.isLocal);
            this.props.onChange({imagesToRemove: imagesToRemove, imagesToAdd: imagesToAdd});
        });
    }

    render() {
        const {t} = this.props;
        const {imagesToAdd, imagesToRemove, images} = this.state;
        return (
            <div className={styles.productImages}>
                {!!(images && images.length) && images.map((image, index) =>
                    <div
                        key={`product-image-${index}`}
                        className={styles.image}
                         style={{backgroundImage: "url(" + image.link + ")"}}>
                        <div className={styles.removeButton}
                            onClick={() => this.onRemove(index)}>
                            &#10006;
                        </div>
                    </div>)}

                {!!(images && images.length < 5) &&
                <div className={styles.addNewButton}
                     onClick={() => this.inputRef.click()}>
                    <PlusIcon/>
                </div>}
                <input
                    type="file"
                    className="d-none"
                    ref={ref => this.inputRef = ref}
                    onChange={this.onChange}/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    updateProduct: (id, data) => dispatch(updateProduct(id, data)),
});
const translation = withTranslation();
const connector = connect(null, mapDispatchToProps);
export default connector(translation(ProductImages));

