import React, {useState} from "react";
import "./Header.scss";
import Logo from "../../../assets/images/logo-white.png";
import {Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller} from 'react-scroll';
import {useHistory} from "react-router";
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {Dropdown, Menu} from "antd";
import {ReactComponent as AlbaniaIcon} from "../../../assets/icons/albania.svg";
import {ReactComponent as FranceIcon} from "../../../assets/icons/france.svg";
import {ReactComponent as UKIcon} from "../../../assets/icons/united-kingdom.svg";
import {ReactComponent as NetherlandsIcon} from "../../../assets/icons/netherlands.svg";

const Header = () => {
    const history = useHistory();
    const [open, setOpen] = useState(false);
    
    const {t, i18n} = useTranslation();

    const languageIcons = {
        en: <UKIcon className="mr-3"/>,
        sq: <AlbaniaIcon className="mr-3"/>,
        fr: <FranceIcon className="mr-3"/>,
        nl: <NetherlandsIcon className="mr-3"/>,
    }

    const onLanguageChange = ({ key }) => {
        i18n.changeLanguage(key);
    }

    const menu = (
        <Menu onClick={onLanguageChange}>
            <Menu.Item key="sq">
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <AlbaniaIcon className="mr-3"/> Shqip
                </a>
            </Menu.Item>
            <Menu.Item key="en">
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <UKIcon className="mr-3"/> English
                </a>
            </Menu.Item>
            <Menu.Item key="fr">
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <FranceIcon className="mr-3"/> Française
                </a>
            </Menu.Item>
            <Menu.Item key="nl">
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <NetherlandsIcon className="mr-3"/> Nederlands
                </a>
            </Menu.Item>
        </Menu>
    );

    
    return (
        <header id="header">
            <img src={Logo} alt="Logo"/>
            
            <FontAwesomeIcon
                icon={faBars}
                color="white"
                className="nav-bars"
                onClick={() => setOpen(true)}/>
            
            <nav className="desktop-nav">
                <ul>
                    <li><Link to="home" spy={true} smooth={true} duration={500}>{t("Home")}</Link></li>
                    <li><Link to="about" spy={true} smooth={true} duration={500}>{t("About Us")}</Link></li>
                    <li><Link to="info" spy={true} smooth={true} duration={500}>{t("Why Menyja")}</Link></li>
                    <li><Link to="video" spy={true} smooth={true} duration={500}>{t("Video")}</Link></li>
                    <li><Link to="subscribe" spy={true} smooth={true} duration={500}>{t("Subscribe")}</Link></li>
                    <li>
                        <button className="login-button" onClick={() => history.push("/login")}>{t("Log in")}</button>
                    </li>
                    <li>
                        <button className="login-button" onClick={() => history.push("/register")}>{t("Sign Up")}</button>
                    </li>
                    <li>
                        <Dropdown overlay={menu}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                {languageIcons[i18n.language]} {i18n.language.toUpperCase()}
                            </a>
                        </Dropdown>
                    </li>
                </ul>
            </nav>
            
            <nav className={classNames("mobile-nav", {isOpen: open})}>
                <FontAwesomeIcon
                    icon={faTimes}
                    color="white"
                    className="nav-close"
                    onClick={() => setOpen(false)}/>
                <ul>
                    <li><Link to="home" spy={true} smooth={true} duration={500}>{t("Home")}</Link></li>
                    <li><Link to="about" spy={true} smooth={true} duration={500}>{t("About Us")}</Link></li>
                    <li><Link to="info" spy={true} smooth={true} duration={500}>{t("Why Menyja")}</Link></li>
                    <li><Link to="video" spy={true} smooth={true} duration={500}>{t("Video")}</Link></li>
                    <li><Link to="subscribe" spy={true} smooth={true} duration={500}>{t("Subscribe")}</Link></li>
                    <li>
                        <button className="login-button" onClick={() => history.push("/login")}>{t("Log in")}</button>
                    </li>
                    <li>
                        <button className="login-button" onClick={() => history.push("/register")}>{t("Sign Up")}</button>
                    </li>
                    <li>
                        <Dropdown overlayStyle={{ zIndex: "9999" }} overlay={menu} trigger={['click']}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                {languageIcons[i18n.language]} {i18n.language.toUpperCase()}
                            </a>
                        </Dropdown>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default Header;
