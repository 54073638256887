import axios from "axios";
import * as constants from "../utils/constants";

const url = process.env.REACT_APP_BACKEND_URL;

export const getFeedbacks = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.get(url + `feedback?page=${data}`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            dispatch({ type: constants.GET_FEEDBACKS, payload: res.data });
            return res;
        });
    }
}
