import React, {Component} from "react";
import "./Events.scss";
import {connect} from "react-redux";
import Modal from "../../../components/Modal/Modal";
import {Link, withRouter} from "react-router-dom";
import DragAndDrop, {DraggableTypes} from "../../../components/Draggable/DragAndDrop";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {withTranslation} from "react-i18next";
import {uploadMediaImage, uploadMediaVideo} from "../../../actions/media";
import {TimePicker} from "antd";
import moment from "moment";
import {askForConfirmation, editingTitle, isTimeBefore, search} from "../../../utils";
import {
    activateEvent,
    createEvent,
    deactivateEvent,
    deleteEvent,
    getEvents,
    updateEvent
} from "../../../actions/events";
import {setLoading} from "../../../actions";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../../components/Button/Button";
import Input, {InputStyles} from "../../../components/Input/Input";
import {inputValidations} from "../../../utils/validation/configurations";
import {validate} from "../../../utils/validation";
import Drawer from "../../../components/Drawer/Drawer";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";
import {EMPTY_EVENT_OBJECT} from "../../../utils/constants";

class Events extends Component {
    popUpQuestion = "A jeni të sigurt të vazhdoni?";

    constructor(props) {
        super(props);

        this.state = {
            showValidation: false,
            isModalOpen: false,
            editing: false,
            file: null,
            sidebar_opened: false,
            search_text: "",
            event: {
                pictureUrl: "",
                link: "",
                title: "",
                description: ""
            }
        }
    }

    componentDidMount() {
        this.props.getEvents();
    }

    openModal = () => {
        this.setState({
            isModalOpen: true,
        });
    }

    closeModal = () => {
        this.setState({
            showValidation: false,
            isModalOpen: false,
            editing: false,
            file: null,
            sidebar_opened: false,
            event: EMPTY_EVENT_OBJECT
        });
    }

    clearEvent = () => {
        this.setState({
            event: EMPTY_EVENT_OBJECT
        });
    }

    validateTime = () => {
        if (isTimeBefore(this.state.event.endTime, this.state.event.startTime)) {
            alert("Please enter correct dates");
            return false;
        } else {
            return true
        }
    }

    handleMediaUpload = async (file) => {
        return this.props.uploadMediaImage(file).then(res => {
            return {
                pictureUrl: res.data
            }
        })
    }

    handleCreateEvent = async () => {
        const {t} = this.props;
        let {event, file} = this.state;

        if(this.formIsInvalid()) {
            this.setState({showValidation: true});
            return;
        }

        if (!this.validateTime()) {
            return
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        if (file) {
            this.handleMediaUpload(file).then(res => {
                event = {...event, ...res};
                this.props.createEvent(event).then(res => {
                    this.props.getEvents();
                    this.closeModal();
                    this.props.setLoading(false);
                });
            })
        } else {
            this.props.createEvent(event).then(res => {
                this.props.getEvents();
                this.closeModal();
                this.props.setLoading(false);
            });
        }
    }

    handleUpdateEvent = async() => {
        const {t} = this.props;
        let {event, file} = this.state;

        if(this.formIsInvalid()) {
            this.setState({showValidation: true});
            return;
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        if (file) {
            this.handleMediaUpload(file).then(res => {
                event = {...event, ...res};
                this.props.updateEvent(event.id, event).then(res => {
                    this.props.getEvents();
                    this.closeModal();
                    this.props.setLoading(false);
                });
            })
        } else {
            this.props.updateEvent(event.id, event).then(res => {
                this.props.getEvents();
                this.closeModal();
                this.props.setLoading(false);
            });
        }
    }

    onClickEdit = (item, e) => {
        e.stopPropagation();
        this.setState({editing: true, event: item});
        this.openModal();
    }

    onClickDelete = async(item, e) => {
        e.stopPropagation();
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.deleteEvent(item.id).then(res => {
            this.props.getEvents();
            this.props.setLoading(false);
        });
    }

    onClickActivate = async(item, e) => {
        e.stopPropagation();
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.activateEvent(item).then(res => {
            this.props.getEvents()
            this.props.setLoading(false);
        })
    }

    onClickDeactivate = async(item, e) => {
        console.log(item);
        e.stopPropagation();
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.deactivateEvent(item).then(res => {
            this.props.getEvents();
            this.props.setLoading(false);
        })
    }

    formIsInvalid = () => {
        const {event} = this.state;
        return validate(inputValidations.eventTitleInput, event.title) ||
                validate(inputValidations.eventDescriptionInput, event.description) ||
                validate(inputValidations.eventLinkInput, event.link);
    }

    openCreateEventSidebar = () => {
        this.clearEvent();
        this.setState({
            sidebar_opened: true,
            editing: false
        });
    }

    openEditEventSidebar = (event, e) => {
        e.stopPropagation();
        this.clearEvent();
        this.setState({
            event: event,
            sidebar_opened: true,
            editing: true
        });
    }

    render() {
        const {isModalOpen, event, editing, file, showValidation, sidebar_opened, search_text} = this.state;
        const {events_fetched, t} = this.props;
        const events = search(this.props.events, search_text, "title");

        return (
            <div className="events-page">

                <div className="page-header-row">
                    <h1>{t("Events")}</h1>
                    <div className="d-flex">
                        <Button
                            color={ButtonColors.Green}
                            onClick={() => this.openCreateEventSidebar()}>
                            + {t("Create Event")}
                        </Button>
                    </div>
                </div>

                <div className="page-search-row">
                    <Input
                        type="text"
                        id="searchTablesInput"
                        name="searchTablesInput"
                        placeholder={t("Search for posts")}
                        value={search_text}
                        onChange={(e) => this.setState({search_text: e.target.value})}/>
                </div>

                {events_fetched ?
                    <>
                        {events.length > 0 ?
                            <DragAndDrop
                                isDragDisabled
                                items={events}
                                type={DraggableTypes.POST}
                                onClickEdit={(item, e) => this.openEditEventSidebar(item, e)}
                                onClickDelete={(item, e) => this.onClickDelete(item, e)}
                                onDragEnd={(result) => this.onDragEnd(result)}/> : t("No events") + "..."}
                    </>
                    : <div className="text-center py-3">
                        <div className="spinner-border text-info"/>
                    </div>
                }

                <Drawer
                    open={sidebar_opened}>

                    <div className="d-flex justify-content-between mb-16">
                        <h2>{editing ? t("Edit Post") : t("Create Post")}</h2>
                        <div className="d-flex">
                            <Button
                                type={ButtonTypes.Icon}
                                onClick={this.closeModal}>
                                <CloseIcon/>
                            </Button>
                        </div>
                    </div>

                    <Input
                        label={`${t("Title")} (${t("Primary language")})`}
                        id="eventTitleInput"
                        name="eventTitleInput"
                        value={event.title}
                        style={InputStyles.Material}
                        validations={[inputValidations.eventTitleInput, showValidation]}
                        onChange={(e) => this.setState({event: {...event, title: e.target.value}})}/>

                    <Input
                        label={`${t("Title")} (${t("Secondary language")})`}
                        id="eventTitleEnInput"
                        name="eventTitleEnInput"
                        value={event.titleEn}
                        style={InputStyles.Material}
                        validations={[inputValidations.eventTitleEnInput, showValidation]}
                        onChange={(e) => this.setState({event: {...event, titleEn: e.target.value}})}/>

                    <Input
                        label={t("Link")}
                        id="eventLinkInput"
                        name="eventLinkInput"
                        value={event.link}
                        style={InputStyles.Material}
                        validations={[inputValidations.eventLinkInput, showValidation]}
                        onChange={(e) => this.setState({event: {...event, link: e.target.value}})}/>

                    <Input
                        type="file"
                        label={t("Photo or Video")}
                        id="eventFileInput"
                        name="eventFileInput"
                        value={file}
                        style={InputStyles.Material}
                        validations={[inputValidations.eventFileInput, showValidation]}
                        onChange={(e) => this.setState({file: e.target.files[0] ? e.target.files[0] : null})}/>

                    <Input
                        type="textarea"
                        label={`${t("Description")} (${t("Primary language")})`}
                        id="eventDescriptionInput"
                        name="eventDescriptionInput"
                        value={event.description}
                        style={InputStyles.Material}
                        validations={[inputValidations.eventDescriptionInput, showValidation]}
                        onChange={(e) => this.setState({event: {...event, description: e.target.value}})}/>

                    <Input
                        type="textarea"
                        label={`${t("Description")} (${t("Secondary language")})`}
                        id="eventDescriptionEnInput"
                        name="eventDescriptionEnInput"
                        value={event.descriptionEn}
                        style={InputStyles.Material}
                        validations={[inputValidations.eventDescriptionEnInput, showValidation]}
                        onChange={(e) => this.setState({event: {...event, descriptionEn: e.target.value}})}/>

                    <Button
                        className="mt-24"
                        size={ButtonSizes.Full}
                        color={ButtonColors.Green}
                        type={ButtonTypes.Filled}
                        onClick={() => editing ? this.handleUpdateEvent() : this.handleCreateEvent()}>
                        {editing ? t("Edit Post") : t("Create Post")}
                    </Button>

                </Drawer>

                <Modal
                    isModalOpen={isModalOpen}
                    handleClose={this.closeModal}
                    handleSubmit={() => editing ? this.handleUpdateEvent() : this.handleCreateEvent()}
                    title={editing ? t("Edit PostCard") + editingTitle(event.title) : t("Create PostCard")}>

                    <Input
                        label={t("Title")}
                        id="eventTitleInput"
                        name="eventTitleInput"
                        value={event.title}
                        validations={[inputValidations.eventTitleInput, showValidation]}
                        onChange={(e) => this.setState({event: {...event, title: e.target.value}})}/>

                    <Input
                        label={t("Link")}
                        id="eventLinkInput"
                        name="eventLinkInput"
                        value={event.link}
                        validations={[inputValidations.eventLinkInput, showValidation]}
                        onChange={(e) => this.setState({event: {...event, link: e.target.value}})}/>

                    {/*<div className="form-group">*/}
                    {/*    <label htmlFor="event-end-time-input">{ t("Date") }:</label>*/}
                    {/*    <DatePicker*/}
                    {/*        className="form-control"*/}
                    {/*        defaultValue={event.date ? moment(event.date, 'yyyy-MM-DD') : moment()}*/}
                    {/*        format={"yyyy-MM-DD"}*/}
                    {/*        onChange={(date) => this.setState({ event: { ...event, date: date ? date.format("yyyy-MM-DD") : "" }})} />*/}
                    {/*</div>*/}

                    {/*<Input*/}
                    {/*    type="time"*/}
                    {/*    label={t("Start time")}*/}
                    {/*    id="event-start-time-input"*/}
                    {/*    name="event-start-time-input"*/}
                    {/*    value={event.startTime}*/}
                    {/*    onChange={(time) => this.setState({event: {...event, startTime: time}})}/>*/}
                    
                    {/*<Input*/}
                    {/*    type="time"*/}
                    {/*    label={t("End time")}*/}
                    {/*    id="event-end-time-input"*/}
                    {/*    name="event-end-time-input"*/}
                    {/*    value={event.endTime}*/}
                    {/*    onChange={(time) => this.setState({event: {...event, endTime: time}})}/>*/}

                    <Input
                        type="file"
                        label={t("Photo or Video")}
                        id="eventFileInput"
                        name="eventFileInput"
                        value={file}
                        validations={[inputValidations.eventFileInput, showValidation]}
                        onChange={(e) => this.setState({file: e.target.files[0] ? e.target.files[0] : null})}/>

                    <Input
                        type="textarea"
                        label={t("Description")}
                        id="eventDescriptionInput"
                        name="eventDescriptionInput"
                        value={event.description}
                        validations={[inputValidations.eventDescriptionInput, showValidation]}
                        onChange={(e) => this.setState({event: {...event, description: e.target.value}})}/>

                </Modal>

            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        events: state.app.events,
        events_fetched: state.app.events_fetched,
    }
}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    getEvents: (data) => dispatch(getEvents(data)),
    createEvent: (data) => dispatch(createEvent(data)),
    updateEvent: (bannerId, data) => dispatch(updateEvent(bannerId, data)),
    deleteEvent: (bannerId) => dispatch(deleteEvent(bannerId)),
    activateEvent: (data) => dispatch(activateEvent(data)),
    deactivateEvent: (data) => dispatch(deactivateEvent(data)),
    uploadMediaImage: (data) => dispatch(uploadMediaImage(data)),
    uploadMediaVideo: (data) => dispatch(uploadMediaVideo(data)),

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Events)));
