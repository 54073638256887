import React, {useState} from "react";
import Text from "./Text/Text";
import styles from "./Input.module.scss";
import Time from "./Time/Time";
import File from "./File/File";
import Textarea from "./Textarea/Textarea";
import {validate} from "../../utils/validation";
import classNames from "classnames";
import Switch from "./Switch/Switch";
import Select from "./Select/Select";

export const InputStyles = {
    Standard: "standard",
    Material: "material"
}

export default function Input(props) {

    const {
        id,
        name,
        type,
        value,
        label,
        style,
        className,
        wrapperClassName,
        placeholder,
        autoComplete,
        onChange,
        onBlur,
        validations,
        customValidationMessage,
        autoFocus,
        disabled,
        options,
    } = props;

    const [validationVisible, setValidationVisible] = useState(false);
    const validation = !!(validations && validations.length) ? validate(validations[0], value) : null;
    const showValidation = ((validations && validations.length > 1) && (validationVisible !== true)) ? validations[1] : validationVisible;

    const handleBlur = e => {
        if (validations) {
            setValidationVisible(true);
        }
        if (onBlur) {
            onBlur(e);
        }
    }

    return (
        <div key={id} className={classNames(styles.inputGroup, wrapperClassName,
            {[styles.materialInput]: style === InputStyles.Material})}>

            {label &&
            <label
                htmlFor={name}
                className={styles.inputLabel}>
                {label}
            </label>}

            {type === "textarea" &&
            <Textarea
                id={id}
                style={style}
                name={name}
                type={type}
                value={value}
                onBlur={handleBlur}
                className={className}
                placeholder={placeholder}
                autoComplete={autoComplete}
                onChange={onChange}/>}

            {(!type || type === "text" || type === "password" || type === "number") &&
            <Text
                id={id}
                name={name}
                type={type}
                style={style}
                value={value}
                onBlur={handleBlur}
                className={className}
                placeholder={placeholder}
                autoComplete={autoComplete}
                onChange={onChange}
                autoFocus={autoFocus}
                disabled={disabled}
            />}

            {type === "time" &&
            <Time
                id={id}
                name={name}
                style={style}
                value={value}
                onBlur={handleBlur}
                className={className}
                placeholder={placeholder}
                onChange={onChange}/>}

            {type === "select" &&
            <Select
                id={id}
                name={name}
                type={type}
                style={style}
                value={value}
                options={options}
                onBlur={handleBlur}
                className={className}
                placeholder={placeholder}
                autoComplete={autoComplete}
                onChange={onChange}/>}

            {type === "file" &&
            <File
                id={id}
                type={type}
                name={name}
                style={style}
                value={value}
                onBlur={handleBlur}
                className={className}
                placeholder={placeholder}
                onChange={onChange}/>}

            {type === "switch" &&
            <Switch
                id={id}
                type={type}
                name={name}
                style={style}
                value={value}
                className={className}
                placeholder={placeholder}
                onChange={onChange}/>}

            {!!(showValidation && validation && !customValidationMessage) &&
            <div className={styles.validationMessage}>
                {validation.message}
            </div>}

            {!!customValidationMessage &&
            <div className={styles.validationMessage}>
                {customValidationMessage}
            </div>}

        </div>

    )
}
