import React from "react";
import styles from "./BusinessModal.module.scss";
import {useTranslation} from "react-i18next";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../Button/Button";
import {formatTime, getDescriptionByLanguage, getTitleByLanguage} from "../../../utils";
import {useSelector} from "react-redux";

const BusinessModal = () => {

    const { t, i18n } = useTranslation();
    const business = useSelector(state => state.client.business);

    return (
        <div className={styles.businessModal}>
            <div className={styles.businessModalImage} style={{backgroundImage: "url('" + business.logo + "')"}} />
            <div className="big-title mt-32 mb-1">
                {business.name}
            </div>
            <p className="font-17 text-tertiary">
                {business.address.addressLine}, {business.address.city}
            </p>
            <p className="font-17 text-tertiary mt-1">
                {business.phoneNumber}
            </p>

            <div className="d-flex justify-content-between mt-24">
                <div>
                    <p className="font-17">{t("Working hours")}</p>
                    <p className="text-tertiary">
                        {formatTime(business.startDayTime)} - {formatTime(business.endDayTime)}
                    </p>
                </div>
                {/*<div className="text-right">*/}
                {/*    <p>{t("Delivery hours")}</p>*/}
                {/*    <p className="text-tertiary">*/}
                {/*        {formatTime(business.configuration.deliveryStartTime)} - {formatTime(business.configuration.deliveryEndTime)}*/}
                {/*    </p>*/}
                {/*</div>*/}
            </div>

            <p className="font-17 text-tertiary mt-24">
                {getDescriptionByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, business)}
            </p>


        </div>
    )
}

export default BusinessModal;
