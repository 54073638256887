import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../Button/Button";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";
import {getProductStarTypes, productExists, search} from "../../../utils";
import {setLoading} from "../../../actions";
import {createTable, getTables} from "../../../actions/tables";
import {connect} from "react-redux";
import Input, {InputStyles} from "../../Input/Input";
import ProductCard from "../../Cards/ProductCard/ProductCard";
import styles from "./ProductRatingsSidebar.scss";
import Bars from "../../Rating/Bars/Bars";
import Stars from "../../Stars/Stars";

class ProductRatingsSidebar extends Component {

    state = {

    }

    componentDidMount() {
        //
    }

    getRatingOfProduct = (productId) => {
        const {ratings} = this.props;
        if (ratings.length) {
            const rating = ratings.find(rating => rating.productId == productId);
            return rating ? (rating.averageRate).toFixed(1) : 0;
        }
    }

    getRatingsCountProduct = (productId) => {
        const {ratings} = this.props;
        if (ratings.length) {
            const rating = ratings.find(rating => rating.productId == productId);
            return rating ? rating.count : 0;
        }
    }


    render() {
        const {t, product, ratings, rating} = this.props;
        return (
            <div>
                <div className="d-flex justify-content-between mb-16">
                    <h2>{t("Ratings")}</h2>
                    <div className="d-flex">
                        <Button
                            type={ButtonTypes.Icon}
                            onClick={this.props.onCloseButtonClick}>
                            <CloseIcon/>
                        </Button>
                    </div>
                </div>


                <div className="product-rating">
                    <div className="product-rating-stars">
                        <h3>{ `${this.getRatingOfProduct(product.id)} ${t("out of")} 5`  }</h3>
                        <Stars stars={getProductStarTypes(ratings, product.id)} />

                        <p className="text-tertiary">{`${t("out of")} ${this.getRatingsCountProduct(product.id)} ${t("ratings")}`  }</p>
                    </div>
                    <div className="product-rating-bars">
                        <Bars ratings={rating} />
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    ratings: state.app.ratings
})
const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withTranslation()(ProductRatingsSidebar));

