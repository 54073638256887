import React, {useState} from "react";
import styles from "./Floating.module.scss";
import {useTranslation} from "react-i18next";
import {wishlistCount} from "../../utils";
import {openModal} from "../../actions/client";
import {CLIENT_MODAL_TYPES} from "../../utils/constants";
import {connect} from "react-redux";
import classNames from "classnames";
import {ReactComponent as BellIcon} from "../../assets/icons/ring-bell.svg";
import {ReactComponent as CartIcon} from "../../assets/icons/cart-1.svg";
import {ReactComponent as HeartIcon} from "../../assets/icons/favorite.svg";
import {ReactComponent as DeliveryIcon} from "../../assets/icons/Email.svg";

const Floating = ({openModal, isCallWaiterEnabled, handleCallWaiter, countdown, visible, is_ordering_enabled, is_delivery_enabled}) => {

    const [tooltip, setTooltip] = useState(true);
    const [button_animation, setButtonAnimation] = useState(false);
    const {t, i18n} = useTranslation();
    const wishlist = wishlistCount();

    const handleCallWaiterButtonClick = () => {
        setTooltip(false);
        handleCallWaiter();
        setButtonAnimation(true);
        setTimeout(() => {
            setButtonAnimation(false);
        }, 500);
    }

    const icon = () => {
        if(is_ordering_enabled || is_delivery_enabled) {
            return <CartIcon />
        } else {
            return <HeartIcon />
        }
    }

    return (
        <div className={classNames(styles.floatingButtons, {[styles.hidden]: !visible})}>

            {(isCallWaiterEnabled && !is_delivery_enabled) &&
            <button
                onClick={handleCallWaiterButtonClick}
                className={classNames(styles.callWaiterButton,
                    {[styles.pop]: button_animation, [styles.disabled]: countdown > 0})}>
                {countdown <= 0 ? <BellIcon/> : countdown}
            </button>}

            <button
                className={classNames(styles.wishlistButton, {[styles.wishlist]: !is_ordering_enabled && !is_delivery_enabled})}
                onClick={() => openModal(CLIENT_MODAL_TYPES.WISHLIST)}>
                {icon()}

                {!!wishlist &&
                <span className={styles.wishlistButtonCount}>
                        {wishlist}
                    </span>}
            </button>

        </div>
    )
}

const mapStateToProps = state => ({
    is_ordering_enabled: state.client.is_ordering_enabled,
    is_delivery_enabled: state.client.is_delivery_enabled,
});
const mapDispatchToProps = dispatch => ({
    openModal: (type) => dispatch(openModal(type))
});
export default connect(mapStateToProps, mapDispatchToProps)(Floating);
