import React from "react";
import styles from "./Table.module.scss"
import {useTranslation} from "react-i18next";
import {ReactComponent as CartIcon} from "../../../assets/icons/cart-1.svg";
import {ReactComponent as BellIcon} from "../../../assets/icons/ring-bell-1.svg";
import classNames from "classnames";

export default function Table(props) {
    const {t} = useTranslation();
    const {
        table,
        calls,
        orders,
        onClick,
        enableOrders
    } = props;

    return (
        <div className={classNames(styles.table, { [styles.disabledOrders]:! enableOrders })}
             onClick={onClick}>

            <div className={styles.tableIcons}>
                {enableOrders && <div className={classNames(styles.cartIcon, {[styles.active]: orders})}>
                    {orders || <CartIcon/>}
                </div>}
                <div className={classNames(styles.bellIcon, {[styles.active]: calls})}>
                    {calls || <BellIcon/>}
                </div>
            </div>
            <p className={styles.tableName}>{table.name}</p>
        </div>
    )
}
