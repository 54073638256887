import React, {useEffect, useState} from "react";
import styles from "./SelectIcons.module.scss";
import {
    BeerIconColored,
    BeerIconWhite, BurgerIconColored,
    BurgerIconWhite, CocktailIconColored,
    CocktailIconWhite, CoffeeIconColored,
    CoffeeIconWhite, CroissantIconColored,
    CroissantIconWhite, DesertsIconColored,
    DesertsIconWhite, DrinksIconColored,
    DrinksIconWhite, FoodIconColored, FoodIconWhite, FrenchFriesIconColored,
    FrenchFriesIconWhite, IceCreamIconColored,
    IceCreamIconWhite, MainDishIconColored,
    MainDishIconWhite, MoreDrinksIconColored,
    MoreDrinksIconWhite, PastaIconColored,
    PastaIconWhite, PizzaIconColored, PizzaIconWhite, SaladIconColored,
    SaladIconWhite, SandwichIconColored,
    SandwichIconWhite, ShrimpIconColored,
    ShrimpIconWhite, SideDishIconColored,
    SideDishIconWhite, SoupIconColored,
    SoupIconWhite, TacosIconColored,
    TacosIconWhite, VineIconColored,
    VineIconWhite,
    TequilaIconColored,
    DrinkIconColored,
    TeaIconColored,
    WaterIconColored,
    HookahIconColored,
    TequilaIconWhite,
    DrinkIconWhite,
    TeaIconWhite,
    WaterIconWhite,
    HookahIconWhite, StarIconColored, StarIconWhite,

} from "./SubmenuIcons";
import classNames from "classnames";


export const submenuIcons = {
    Beer: "beer",
    Burger: "burger",
    Cocktail: "cocktail",
    Coffee: "coffee",
    Croissant: "croissant",
    Deserts: "deserts",
    Drinks: "drinks",
    MoreDrinks: "more-drinks",
    Food: "food",
    FrenchFries: "french-fries",
    IceCream: "ice-cream",
    MainDish: "main-dish",
    Pasta: "pasta",
    Pizza: "pizza",
    Salad: "salad",
    Sandwich: "sandwich",
    Shrimp: "shrimp",
    SideDish: "side-dish",
    Soup: "soup",
    Tacos: "tacos",
    Vine: "vine",
    Drink: "drink",
    Hookah: "hookah",
    Tea: "tea",
    Tequila: "tequila",
    Water: "water",
    Star: "star"
}

export const whiteIcons = {
    [submenuIcons.Beer]: <BeerIconWhite/>,
    [submenuIcons.Burger]: <BurgerIconWhite/>,
    [submenuIcons.Cocktail]: <CocktailIconWhite/>,
    [submenuIcons.Coffee]: <CoffeeIconWhite/>,
    [submenuIcons.Croissant]: <CroissantIconWhite/>,
    [submenuIcons.Deserts]: <DesertsIconWhite/>,
    [submenuIcons.Drinks]: <DrinksIconWhite/>,
    [submenuIcons.Food]: <FoodIconWhite/>,
    [submenuIcons.MoreDrinks]: <MoreDrinksIconWhite/>,
    [submenuIcons.FrenchFries]: <FrenchFriesIconWhite/>,
    [submenuIcons.IceCream]: <IceCreamIconWhite/>,
    [submenuIcons.MainDish]: <MainDishIconWhite/>,
    [submenuIcons.Pasta]: <PastaIconWhite/>,
    [submenuIcons.Pizza]: <PizzaIconWhite/>,
    [submenuIcons.Salad]: <SaladIconWhite/>,
    [submenuIcons.Sandwich]: <SandwichIconWhite/>,
    [submenuIcons.Shrimp]: <ShrimpIconWhite/>,
    [submenuIcons.SideDish]: <SideDishIconWhite/>,
    [submenuIcons.Soup]: <SoupIconWhite/>,
    [submenuIcons.Tacos]: <TacosIconWhite/>,
    [submenuIcons.Vine]: <VineIconWhite/>,

    [submenuIcons.Drink]: <DrinkIconWhite/>,
    [submenuIcons.Hookah]: <HookahIconWhite/>,
    [submenuIcons.Tea]: <TeaIconWhite/>,
    [submenuIcons.Tequila]: <TequilaIconWhite/>,
    [submenuIcons.Water]: <WaterIconWhite/>,
    [submenuIcons.Star]: <StarIconWhite/>,


}

export const coloredIcons = {
    [submenuIcons.Beer]: <BeerIconColored/>,
    [submenuIcons.Burger]: <BurgerIconColored/>,
    [submenuIcons.Cocktail]: <CocktailIconColored/>,
    [submenuIcons.Coffee]: <CoffeeIconColored/>,
    [submenuIcons.Croissant]: <CroissantIconColored/>,
    [submenuIcons.Deserts]: <DesertsIconColored/>,
    [submenuIcons.Drinks]: <DrinksIconColored/>,
    [submenuIcons.Food]: <FoodIconColored/>,
    [submenuIcons.MoreDrinks]: <MoreDrinksIconColored/>,
    [submenuIcons.FrenchFries]: <FrenchFriesIconColored/>,
    [submenuIcons.IceCream]: <IceCreamIconColored/>,
    [submenuIcons.MainDish]: <MainDishIconColored/>,
    [submenuIcons.Pasta]: <PastaIconColored/>,
    [submenuIcons.Pizza]: <PizzaIconColored/>,
    [submenuIcons.Salad]: <SaladIconColored/>,
    [submenuIcons.Sandwich]: <SandwichIconColored/>,
    [submenuIcons.Shrimp]: <ShrimpIconColored/>,
    [submenuIcons.SideDish]: <SideDishIconColored/>,
    [submenuIcons.Soup]: <SoupIconColored/>,
    [submenuIcons.Tacos]: <TacosIconColored/>,
    [submenuIcons.Vine]: <VineIconColored/>,

    [submenuIcons.Drink]: <DrinkIconColored/>,
    [submenuIcons.Hookah]: <HookahIconColored/>,
    [submenuIcons.Tea]: <TeaIconColored/>,
    [submenuIcons.Tequila]: <TequilaIconColored/>,
    [submenuIcons.Water]: <WaterIconColored/>,
    [submenuIcons.Star]: <StarIconColored/>,
}

const SelectIcons = ({ selectedIcon, onChange }) => {

    const [hoveredIcon, setHoveredIcon] = useState("");

    const onIconClick = (icon) => {
        onChange(icon);
    }

    const onIconMouseEnter = (icon) => {
       setHoveredIcon(icon);
    }

    const onIconMouseLeave = (icon) => {
        setHoveredIcon("");
    }

    return (
        <div className={styles.selectIcons}>
            <div className={styles.preview}>
                {selectedIcon && coloredIcons[selectedIcon]}
            </div>
            <div className={styles.icons}>
                {Object.values(submenuIcons).map(icon =>
                    <div
                        onClick={() => onIconClick(icon)}
                        onMouseEnter={() => onIconMouseEnter(icon)}
                        onMouseLeave={onIconMouseLeave}
                        className={classNames(styles.icon, { [styles.selected]: selectedIcon === icon } )}>
                        {icon === hoveredIcon || icon === selectedIcon
                            ? whiteIcons[icon] : coloredIcons[icon]}
                    </div>
                )}
            </div>
        </div>
    )
}

export default SelectIcons;
