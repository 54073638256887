import axios from "axios";
import * as constants from "../utils/constants";
import Compressor from 'compressorjs';
import imageCompression from "browser-image-compression";
import {compression_options, thumbnail_compression_options} from "../utils/constants";

const url = process.env.REACT_APP_BACKEND_URL;

export const setProducts = data => ({
    type: constants.SET_PRODUCTS,
    payload: data
})

export const getProducts = data => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.get(url + "products", {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            dispatch({type: constants.GET_PRODUCTS, payload: res.data});
            return res;
        });
    }
}

export const getProductsRatings = data => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.get(url + "product-rates", {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            dispatch({type: constants.GET_RATINGS, payload: res.data});
            return res;
        });
    }
}

export const getProductRating = productId => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.get(url + `products/${productId}/product-rates`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const createProduct = data => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.post(url + `products`, data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const updateProduct = (productId, data) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.put(url + `products/${productId}`, data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const addProducts = (menuId, submenuId, data) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.post(url + `menus/${menuId}/sub-menus/${submenuId}/products`, {
            productIds: data
        }, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const updateProductOrdering = (menuId, submenuId, data) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.put(url + `menus/${menuId}/sub-menus/${submenuId}/products/ordering`, {
            products: data
        }, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const deleteProduct = (productId) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.delete(url + `products/${productId}`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const removeProductFromSubmenu = (menuId, submenuId, productId) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.delete(url + `menus/${menuId}/sub-menus/${submenuId}/products/${productId}`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const getProductImages = (productId) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.get(url + `products/${productId}/images`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const addProductImage = (productId, data) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return imageCompression(data, compression_options)
            .then(function (compressedFile) {
                return imageCompression(compressedFile, thumbnail_compression_options)
                    .then(function (compressedThumbnailFile) {
                        console.log(compressedFile);
                        const filename = new Date().getTime().toString();
                        const formData = new FormData();
                        formData.append('file', compressedFile, filename + ".png");
                        formData.append('thumbnailFile', compressedFile, filename + "_thumbnail.png");
                        return axios.post(url + `products/${productId}/images`, formData, {
                            headers: {
                                "Authorization": "Bearer " + token
                            }
                        }).then(res => res);
                    }).catch(function (error) {
                        console.log(error.message);
                    });
            })
            .catch(function (error) {
                console.log(error.message);
            });
    }
}

export const removeProductImage = (productId, imageId, data) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.delete(url + `products/${productId}/images/${imageId}`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const addProductImageLink = (productId, data) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.post(url + `products/${productId}/images-link`, {link: data},{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}
