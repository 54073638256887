import React, {Component} from "react";
import "./Login.scss";
import {connect} from "react-redux";
import {getBusinessDetails, login} from "../../../actions/user";
import {Link, withRouter} from "react-router-dom";
import firebase from "../../../firebaseInit";
import {register} from "../../../actions/notifications";
import {withTranslation} from "react-i18next";
import facebookImage from "../../../assets/images/facebook.png"
import {VERSION} from "../../../utils/constants";
import {setLoading} from "../../../actions";
import Input from "../../../components/Input/Input";
import Logo from "../../../assets/images/menyja-login-logo.png";
import Button, {ButtonColors, ButtonTypes} from "../../../components/Button/Button";
import {inputValidations} from "../../../utils/validation/configurations";
import {validate} from "../../../utils/validation";
import {isNotificationsPage, notificationsSupported} from "../../../utils";

class Login extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            showValidation: false,
            username: "",
            password: "",
        }
    }
    
    componentDidMount() {
        document.querySelector("body").classList.add("login-page");
    }
    
    componentWillUnmount() {
        document.querySelector("body").classList.remove("login-page");
    }
    
    handleSubmit = e => {
        e.preventDefault()

        if(this.formIsInvalid()) {
            this.setState({showValidation: true});
            return;
        }

        this.props.setLoading(true);
        const {username, password} = this.state;
        this.props.login({username: encodeURIComponent(username), password}).then(res => {
            this.props.getUserDetails().then(res => {
                this.props.setLoading(false);
                if(isNotificationsPage() && notificationsSupported()) {
                    if (Notification.permission === "granted") {
                        this.registerToken();
                    } else if (Notification.permission !== "denied") {
                        Notification.requestPermission().then((permission) => {
                            if (permission === "granted") {
                                this.registerToken();
                            }
                        });
                    }
                }
                this.props.history.push("/menus");
            })
        });
    }
    
    registerToken = () => {
        if (firebase.messaging.isSupported()) {
            const messaging = firebase.messaging();
            messaging.getToken()
                .then((token) => {
                    this.props.register(token).then(res => {
                        this.props.history.push("/menus");
                    });
                });
        }
    }

    formIsInvalid = () => {
        const {username, password} = this.state;
        return validate(inputValidations.loginEmailInput, username) || validate(inputValidations.loginPasswordInput, password);
    }

    render() {
        
        const {username, password, showValidation} = this.state;
        const {t} = this.props;
        
        return (
            <div className="login-box">
                <p className="text-center">Welcome to</p>
                <h1 className="text-center mb-24">Menyja</h1>
                <div className="login-box-body">
                    
                    <form onSubmit={e => e.preventDefault()} method="post">
                        
                        <Input
                            wrapperClassName="mb-24"
                            id="loginEmailInput"
                            name="loginEmailInput"
                            placeholder="email@example.com"
                            value={username}
                            validations={[inputValidations.loginEmailInput, showValidation]}
                            onChange={(e) => this.setState({username: e.target.value})}/>
                        
                        <Input
                            type="password"
                            id="loginPasswordInput"
                            name="loginPasswordInput"
                            placeholder="********"
                            value={password}
                            validations={[inputValidations.loginPasswordInput, showValidation]}
                            onChange={(e) => this.setState({password: e.target.value})}/>
                        
                        <div className="d-flex justify-content-between align-items-center mt-24">
                            <Link to="/reset-password">{t("Forgot password")}?</Link>

                            <Button
                                type={ButtonTypes.Filled}
                                color={ButtonColors.Green}
                                onClick={(e) => this.handleSubmit(e)}>
                                {t("Log in")}
                            </Button>
                        </div>
                    </form>
                    <div className="mt-24 text-center">
                        {"v" + VERSION}
                    </div>
                </div>
                {/*<a href="https://www.facebook.com/Menyjaco-108469744304161"*/}
                {/*   className="login-facebook"*/}
                {/*   target="_blank">*/}
                {/*    <img src={facebookImage} alt="facebook"*/}
                {/*         style={{*/}
                {/*             display: "block",*/}
                {/*             width: "35px",*/}
                {/*             margin: "auto",*/}
                {/*             borderRadius: "4px"*/}
                {/*         }}/>*/}
                {/*</a>*/}
            </div>
        );
    }
    
}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    login: data => dispatch(login(data)),
    register: data => dispatch(register(data)),
    getUserDetails: data => dispatch(getBusinessDetails(data))
});

export default withRouter(connect(null, mapDispatchToProps)(withTranslation()(Login)));
