import React from "react";
import styles from "./FloatingReviewButton.module.scss";
import {openModal} from "../../actions/client";
import {CLIENT_MODAL_TYPES} from "../../utils/constants";
import {connect} from "react-redux";
import {ReactComponent as ReviewIcon} from "../../assets/icons/reviews.svg";
import {useTranslation} from "react-i18next";

const FloatingReviewButton = ({openModal}) => {

    const {t, i18n} = useTranslation();

    return (
        <button
            className={styles.floatingReviewButton}
            onClick={() => openModal(CLIENT_MODAL_TYPES.FEEDBACK)}>
            <ReviewIcon className="" />
            {/*{t("Add feedback")}*/}
        </button>
    )
}

const mapDispatchToProps = dispatch => ({
    openModal: (type) => dispatch(openModal(type))
});
const connector = connect(null, mapDispatchToProps);
export default connector(FloatingReviewButton);
