import React, {useState} from "react";
import "./Card.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

export default function Card({title, subtitle, menu, time}) {

    const [menuIsOpen, setMenuOpen] = useState(false);

    const handleMenuItemClick = (e, item) => {
        e.stopPropagation();
        item.handleClick();
        setMenuOpen(false);
    }

    return (
        <div className={classNames("card", { "menu-open": menuIsOpen })}>
            <div className="card-subtitle">
                {subtitle}
            </div>
            <div className="card-title">
                {title}
            </div>
            <div className="card-time">
                {time}
            </div>
            {menu &&
            <div className="card-menu-icon"
                onClick={() => setMenuOpen(!menuIsOpen)}>
                <FontAwesomeIcon icon={faEllipsisH}/>
                <div className="card-menu">
                    {menu.map(item => (
                        <div
                            onClick={(e) => handleMenuItemClick(e, item)}
                            className="card-menu-item">
                            {item.label}
                        </div>
                    ))}
                </div>
            </div>}
        </div>
    )
}
