import {validationTests} from "./tests";
import {errorValidationMessage} from "./messages";

export const validate = (validations, value) => {
    for(let i = 0; i < validations.length; i++) {
        if(validationTests[validations[i]](value) === false) {
            return errorValidationMessage(validations[i])
        }
    }
    return null;
}