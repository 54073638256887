import React, {Component} from "react";
import "./Waiter.scss";
import {connect} from "react-redux";
import {
    blacklist,
    deleteTableNotifications,
    generateQrCode,
    getTableNotifications,
    getTables
} from "../../../actions/tables";
import {
    errorToast,
    existsInArray, notificationsAreEnabled,
    notificationsSupported,
    numberOfOccurrences,
    search,
    setTableCalls,
    successToast
} from "../../../utils";
import {withTranslation} from "react-i18next";
import {getTableOrders, updateOrderStatus} from "../../../actions/orders";
import Table from "../../../components/Cards/Table/Table";
import {WAITER_SIDEBAR_CONTENT} from "../../../utils/constants";
import Input from "../../../components/Input/Input";
import Button, {ButtonColors} from "../../../components/Button/Button";
import * as firebase from "firebase";
import Drawer from "../../../components/Drawer/Drawer";
import OrdersSidebar from "../../../components/Sidebars/OrdersSidebar/OrdersSidebar";
import {setLoading} from "../../../actions";
import OrderDetailsSidebar from "../../../components/Sidebars/OrderDetailsSidebar/OrderDetailsSidebar";
import CreateTableSidebar from "../../../components/Sidebars/CreateTableSidebar/CreateTableSidebar";
import EditTableSidebar from "../../../components/Sidebars/EditTableSidebar/EditTableSidebar";
import GenerateTablesSidebar from "../../../components/Sidebars/GenerateTablesSidebar/GenerateTablesSidebar";
import classNames from "classnames";
import TableQrCode from "../../../components/TableQrCode/TableQrCode";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import {saveAs} from "file-saver";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

class Waiter extends Component {
    constructor(props) {
        super(props);

        this.qrCodeRefs = [];
        this.qrCodeImages = [];

        this.state = {
            table_calls: [],
            table_pending_orders: [],
            table_completed_orders: [],
            opened_table: null,
            opened_order: null,
            is_modal_open: false,
            sidebar_opened: false,
            modal_content: null,
            table: null,
            search_text: ""
        }
    }


    componentDidMount() {
        const {t} = this.props;
        window.addEventListener("focus", this.handleWindowFocus)
        this.props.getTables().then(res => {
            this.updateTableCalls();
            this.updateTableOrders();


            if(notificationsSupported() && notificationsAreEnabled()) {
                const messaging = firebase.messaging()

                messaging.onMessage((message) => {
                    console.log(message);
                    this.updateTableCalls();
                    this.updateTableOrders();
                    if (message.data.title === "New Order") {
                        successToast(t("Order from table") + ": " + message.data.tableNumber);
                    } else {
                        errorToast(t("Call from table") + ": " + message.data.tableName);
                    }
                });
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener("focus", this.handleWindowFocus)
    }

    openModal = () => {
        this.setState({
            is_modal_open: true
        });
    }

    closeModal = () => {
        this.setState({
            modal_content: null,
            opened_table: null,
            sidebar_opened: false
        });
    }

    handleWindowFocus = () => {
        this.updateTableCalls();
        this.updateTableOrders();
    }

    tableHasCalls = (table) => {
        const {table_calls} = this.state;
        return existsInArray(table_calls, table.id, "id");
    }

    tableHasOrders = (table) => {
        const {table_pending_orders} = this.state;
        const {user_details} = this.props;
        return existsInArray(table_pending_orders, table.number, "table") && !!user_details?.configuration.enableOrder;
    }

    filteredPendingTableOrders = (table_number) => {
        const {table_pending_orders} = this.state;
        return table_pending_orders.filter(order => order.table == table_number);
    }

    filteredCompletedTableOrders = (table_number) => {
        const {table_completed_orders} = this.state;
        return table_completed_orders.filter(order => order.table == table_number);
    }

    updateTableCalls = () => {
        this.props.getTableNotifications().then(res => {
            const table_calls = setTableCalls(res.data);
            this.setState({table_calls: table_calls});
        })
    }

    updateTableOrders = () => {
        const completed = true;
        this.props.getTableOrders().then(res => { //pending orders
            this.setState({table_pending_orders: res.data.data});
        })
        this.props.getTableOrders(completed).then(res => { //completed orders
            this.setState({table_completed_orders: res.data.data});
        });
    }

    removeTableCalls = (table) => {
        if (this.tableHasCalls(table)) {
            this.props.deleteTableNotifications(table.id).then(res => {
                this.updateTableCalls();
            })
        }
    }

    handleMouseUp = table => {
        this.openModal();
        this.setState({opened_table: table, modal_content: WAITER_SIDEBAR_CONTENT.PENDING_ORDERS});
    }

    downloadQrCode = async() => {
        const {opened_table} = this.state;
        const qrCodeRef = this.qrCodeRefs.find(ref =>  ref.id === opened_table.number.toString());
        if(qrCodeRef) {
            this.props.setLoading(true);
            setTimeout(async() => {
                const qrCodeImage = await html2canvas(qrCodeRef);
                var a = document.createElement('a');
                // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
                a.href = qrCodeImage.toDataURL("image/png").replace("image/png", "image/octet-stream");
                a.download = `table-${qrCodeRef.id}.png`;
                a.click();
                this.props.setLoading(false);
            }, 150);

        }
    }

    openTable = (table) => {
        this.setState({
            modal_content: WAITER_SIDEBAR_CONTENT.ORDERS,
            sidebar_opened: true,
            opened_table: table
        });
    }

    openGenerateTablesSidebar = () => {
        this.setState({
            modal_content: WAITER_SIDEBAR_CONTENT.GENERATE_TABLES,
            sidebar_opened: true,
        });
    }

    openOrderDetails = (order) => {
        this.setState({
            modal_content: WAITER_SIDEBAR_CONTENT.ORDER_DETAILS,
            opened_order: order
        });
    }

    openCreateTableSidebar = () => {
        this.setState({
            modal_content: WAITER_SIDEBAR_CONTENT.CREATE_TABLE,
            sidebar_opened: true
        });
    }

    openEditTableSidebar = () => {
        this.setState({
            modal_content: WAITER_SIDEBAR_CONTENT.EDIT_TABLE,
            sidebar_opened: true
        });
    }

    downloadAllQrCodes = async() => {
        this.props.setLoading(true);
        setTimeout(() => {
            const requests = this.qrCodeRefs.map(async(el) => await html2canvas(el));
            Promise.all(requests)
                .then((responses) => {
                    let zip = new JSZip();
                    let tables = zip.folder("tables");
                    responses.map((res, index) => {
                        tables.file(`table-${index+1}.png`, res.toDataURL().split(",")[1], {base64: true});
                    });
                    zip.generateAsync({type:"blob"})
                        .then(content => {
                            saveAs(content, "tables.zip");
                            this.props.setLoading(false);
                        });
                });
        }, 200);
    }

    render() {
        const {
            table_calls,
            table_pending_orders,
            opened_table,
            opened_order,
            modal_content,
            sidebar_opened,
            search_text
        } = this.state;
        const {tables_fetched, user_details, t} = this.props;
        const tables = search(this.props.tables, search_text, "name");

        return (
            <div className="waiter-page">

                <div className="page-header-row">
                    <h1>{t("Tables")}</h1>
                    <div className="d-flex">
                        <Button
                            className="mr-16"
                            color={ButtonColors.Green}
                            onClick={() => this.openGenerateTablesSidebar()}>
                            + {t("Generate QR Codes")}
                        </Button>
                        <Button
                            className="mr-16"
                            color={ButtonColors.Green}
                            onClick={() => this.openCreateTableSidebar()}>
                            + {t("Create Table")}
                        </Button>
                        <Button
                            color={ButtonColors.Green}
                            onClick={() => this.downloadAllQrCodes()}>
                            + {t("Download QR Codes")}
                        </Button>
                    </div>
                </div>

                <div className="page-search-row">
                    <Input
                        type="text"
                        id="searchTablesInput"
                        name="searchTablesInput"
                        placeholder={t("Search for tables")}
                        value={search_text}
                        onChange={(e) => this.setState({search_text: e.target.value})}/>
                </div>

                {tables_fetched ?
                    <div className="tables grid">
                        {tables.length > 0 ?
                            tables.map((table, index) => (
                                <Table
                                    enableOrders={!!user_details?.configuration.enableOrder}
                                    table={table}
                                    calls={numberOfOccurrences(table_calls, table.id, "id")}
                                    orders={numberOfOccurrences(table_pending_orders, table.number, "table")}
                                    active_calls={this.tableHasCalls(table)}
                                    active_orders={this.tableHasOrders(table)}
                                    onClick={() => this.openTable(table)}
                                    onClickCalls={() => this.removeTableCalls(table)}
                                    onClickOrders={() => this.handleMouseUp(table)}
                                />
                            )) : t("No tables") + "..."}
                    </div>
                    : <div className="text-center py-3">
                        <div className="spinner-border text-primary"/>
                    </div>
                }

                {!!tables.length &&
                <div className="mini-tables">
                    {tables.map((table, index) => (
                        <div className={classNames("mini-table", {"active-calls": this.tableHasCalls(table), "active-orders": this.tableHasOrders(table)})}/>
                    ))}
                </div>}

                <Drawer
                    open={sidebar_opened}>

                    {modal_content === WAITER_SIDEBAR_CONTENT.ORDER_DETAILS &&
                    <OrderDetailsSidebar
                        onCloseButtonClick={() => this.setState({modal_content: WAITER_SIDEBAR_CONTENT.ORDERS})}
                        onBackButtonClick={() => this.setState({modal_content: WAITER_SIDEBAR_CONTENT.ORDERS})}
                        updateOrders={() => this.updateTableOrders()}
                        onBlockButtonClick={(order) => null}
                        order={opened_order}/>}

                    {modal_content === WAITER_SIDEBAR_CONTENT.ORDERS &&
                    <OrdersSidebar
                        table={opened_table}
                        updateOrders={() => this.updateTableOrders()}
                        clearTableCalls={() => this.removeTableCalls(opened_table)}
                        onQrCodeButtonClick={this.downloadQrCode}
                        onCloseButtonClick={() => this.closeModal()}
                        onEditButtonClick={() => this.openEditTableSidebar()}
                        onDetailsButtonClick={(order) => this.openOrderDetails(order)}
                        orders={!!user_details?.configuration.enableOrder && this.filteredPendingTableOrders(opened_table.number)}
                        calls={numberOfOccurrences(table_calls, opened_table.id, "id")}/>}


                    {modal_content === WAITER_SIDEBAR_CONTENT.CREATE_TABLE &&
                    <CreateTableSidebar
                        onCloseButtonClick={() => this.closeModal()}/>}

                    {modal_content === WAITER_SIDEBAR_CONTENT.EDIT_TABLE &&
                    <EditTableSidebar
                        table={opened_table}
                        onCloseButtonClick={() => this.closeModal()}/>}

                    {modal_content === WAITER_SIDEBAR_CONTENT.GENERATE_TABLES &&
                    <GenerateTablesSidebar
                        onCloseButtonClick={() => this.closeModal()}/>}

                </Drawer>

                {user_details && <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
                    {tables.map((table, index) =>
                        <TableQrCode
                            key={index}
                            number={table.number}
                            link={table.barcodeValue}
                            logo={user_details.logo}
                            ref={ref => this.qrCodeRefs[index] = ref}
                        /> )}
                </div>}

            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        tables: state.app.tables,
        tables_fetched: state.app.tables_fetched,
        firebase_token: state.app.firebase_token,
        user_details: state.app.user_details,
    }
}

const mapDispatchToProps = dispatch => ({
    getTables: data => dispatch(getTables()),
    blacklist: data => dispatch(blacklist(data)),
    setLoading: (data) => dispatch(setLoading(data)),
    getTableOrders: data => dispatch(getTableOrders(data)),
    downloadQrCode: tableId => dispatch(generateQrCode(tableId)),
    getTableNotifications: data => dispatch(getTableNotifications()),
    deleteTableNotifications: tableId => dispatch(deleteTableNotifications(tableId)),
    updateOrderStatus: (orderId, status) => dispatch(updateOrderStatus(orderId, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Waiter));
