import React, {useState} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {addFeedback} from "../../../actions/client";
import styles from "./FeedbackModal.module.scss";
import Input, {InputStyles} from "../../Input/Input";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../Button/Button";
import {inputValidations} from "../../../utils/validation/configurations";
import {validate} from "../../../utils/validation";

const FeedbackModal = ({image, handleClose, addFeedback, business_id}) => {
    
    const {t, i18n} = useTranslation();
    const [name, setName] = useState("");
    const [comment, setComment] = useState("");
    const [showValidations, setShowValidations] = useState(false);
    const [loading, setLoading] = useState(false);

    const sendFeedback = () => {
        if(formIsInvalid()) {
            setShowValidations(true);
            return;
        }

        if(loading) return;

        setLoading(true);

        const data = {
            fullName: name,
            comment: comment,
            businessId: business_id
        };
        addFeedback(data)
            .then(res => {
                setLoading(false);
                handleClose()
                // alert(t("business-feedback-success"));
            }).catch(err => {
                setLoading(false);
                handleClose()
                alert(t("business-feedback-error"));
            });
    }

    const formIsInvalid = () => {
        return validate(inputValidations.feedbackNameInput, name) || validate(inputValidations.feedbackCommentInput, comment);
    }
    
    return (
        <div className={styles.feedbackModal}>
            <p className="big-title">
                {t("Add feedback")}
            </p>

            <Input
                type="text"
                value={name}
                label={t("Name")}
                id="feedbackNameInput"
                wrapperClassName="mt-24"
                style={InputStyles.Standard}
                validations={[inputValidations.feedbackNameInput, showValidations]}
                onChange={(e) => setName(e.target.value)}/>

            <Input
                type="textarea"
                value={comment}
                label={t("Comment")}
                id="feedbackCommentInput"
                wrapperClassName="mt-32"
                style={InputStyles.Standard}
                validations={[inputValidations.feedbackCommentInput, showValidations]}
                onChange={(e) => setComment(e.target.value)}/>

            <Button
                className="mt-16"
                type={ButtonTypes.Filled}
                color={ButtonColors.Green}
                size={ButtonSizes.Full}
                onClick={sendFeedback}>
                {loading ? <div className="spinner-border text-white"/> : t("Send")}
            </Button>
        </div>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => ({
    addFeedback: (payload) => dispatch(addFeedback(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackModal);

