import firebase from 'firebase/app';
import 'firebase/messaging';
import {notificationsAreEnabled, successToast} from "./utils";

const config = {
    apiKey: "AIzaSyB2tS6mcisYaPcBloz9vDS9Q6EEnp7rPsY",
    authDomain: "unem-3b715.firebaseapp.com",
    databaseURL: "https://unem-3b715.firebaseio.com",
    projectId: "unem-3b715",
    storageBucket: "unem-3b715.appspot.com",
    messagingSenderId: "351144865320",
    appId: "1:351144865320:web:735cba9238ffa256ba0f1d",
    measurementId: "G-HE3SYB32PF"
};

firebase.initializeApp(config);
if (firebase.messaging.isSupported()) {
}

// messaging.onMessage((payload) => {
//     console.log(payload)
// });


// export const requestFirebaseNotificationPermission = () =>
//     new Promise((resolve, reject) => {
//         messaging.getToken()
//             .then((firebaseToken) => {
//                 resolve(firebaseToken);
//             })
//             .catch((err) => {
//                 reject(err);
//             });
//     });

// *** not working
export const onMessageListener = () => {
    if (notificationsAreEnabled()) {
        const messaging = firebase.messaging();
        return new Promise((resolve) => {
            messaging.onMessage((payload) => {
                resolve(payload);
            });
        });
    }
}

export default firebase;
