import React from "react";
import "./Privacy.scss";

const Privacy = () => {
    return (
        <div className="privacy-policy">
            <p dir="ltr" style={{
                lineHeight: '1.38',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 12pt 0pt'
            }}><span style={{
                fontSize: '11.5pt',
                fontFamily: 'Arial',
                color: '#757575',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>Menyja L.L.C. has developed the [Menyja] web application as a technology product for businesses. This service is provided by Menyja and is intended for use as is.</span>
            </p>
            <p dir="ltr" style={{
                lineHeight: '1.38',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 12pt 0pt'
            }}><span style={{
                fontSize: '11.5pt',
                fontFamily: 'Arial',
                color: '#757575',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</span>
            </p>
            <p dir="ltr" style={{
                lineHeight: '1.38',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 12pt 0pt'
            }}><span style={{
                fontSize: '11.5pt',
                fontFamily: 'Arial',
                color: '#757575',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</span>
            </p>
            <h5 dir="ltr" style={{
                lineHeight: '1.7999999999999998',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 2pt 0pt'
            }}><span style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#323d47',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>Information Collection and Use</span></h5>
            <p dir="ltr" style={{
                lineHeight: '1.38',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 12pt 0pt'
            }}><span style={{
                fontSize: '11.5pt',
                fontFamily: 'Arial',
                color: '#757575',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us and used as described in this privacy policy.</span>
            </p>
            <p dir="ltr" style={{
                lineHeight: '1.38',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 12pt 0pt'
            }}><span style={{
                fontSize: '11.5pt',
                fontFamily: 'Arial',
                color: '#757575',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>The app does use third party services that may collect information used to identify you.</span></p>
            <p dir="ltr" style={{
                lineHeight: '1.38',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 12pt 0pt'
            }}><span style={{
                fontSize: '11.5pt',
                fontFamily: 'Arial',
                color: '#757575',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>Link to privacy policy of third party service providers used by the app.</span></p>
            <h5 dir="ltr" style={{
                lineHeight: '1.7999999999999998',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 2pt 0pt'
            }}><span style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#323d47',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>Use of Location</span></h5>
            <p dir="ltr" style={{
                lineHeight: '1.38',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 12pt 0pt'
            }}><span style={{
                fontSize: '11.5pt',
                fontFamily: 'Arial',
                color: '#757575',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>For the purpose of scanning the QR codes of your desired products in order to scan the order to your suppliers we need access to your camera.</span>
            </p>
            <p dir="ltr" style={{
                lineHeight: '1.38',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 12pt 0pt'
            }}><span style={{
                fontSize: '11.5pt',
                fontFamily: 'Arial',
                color: '#757575',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>For the purpose of securing our clients operations and to make sure only people in the actual business can call the waiter or send orders we need access to your location services.</span>
            </p>
            <p dir="ltr" style={{
                lineHeight: '1.38',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 12pt 0pt'
            }}><span style={{
                fontSize: '11.5pt',
                fontFamily: 'Arial',
                color: '#757575',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>Location services will not be used for anything else but for the purpose of identifying and securing the business operations.</span>
            </p>
            <h5 dir="ltr" style={{
                lineHeight: '1.7999999999999998',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 2pt 0pt'
            }}><span style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#323d47',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>Log Data</span></h5>
            <p dir="ltr" style={{
                lineHeight: '1.38',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 12pt 0pt'
            }}><span style={{
                fontSize: '11.5pt',
                fontFamily: 'Arial',
                color: '#757575',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</span>
            </p>
            <h5 dir="ltr" style={{
                lineHeight: '1.7999999999999998',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 2pt 0pt'
            }}><span style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#323d47',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>Cookies</span></h5>
            <p dir="ltr" style={{
                lineHeight: '1.38',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 12pt 0pt'
            }}><span style={{
                fontSize: '11.5pt',
                fontFamily: 'Arial',
                color: '#757575',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</span>
            </p>
            <p dir="ltr" style={{
                lineHeight: '1.38',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 12pt 0pt'
            }}><span style={{
                fontSize: '11.5pt',
                fontFamily: 'Arial',
                color: '#757575',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</span>
            </p>
            <h5 dir="ltr" style={{
                lineHeight: '1.7999999999999998',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 2pt 0pt'
            }}><span style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#323d47',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>Service Providers</span></h5>
            <p dir="ltr"
               style={{lineHeight: '1.38', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '12pt'}}><span
                style={{
                    fontSize: '11.5pt',
                    fontFamily: 'Arial',
                    color: '#757575',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap'
                }}>We may employ third-party companies and individuals due to the following reasons:</span></p>
            <ul style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
                <li dir="ltr" style={{
                    listStyleType: 'disc',
                    fontSize: '11.5pt',
                    fontFamily: 'Arial',
                    color: '#757575',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre'
                }}>
                    <p dir="ltr"
                       style={{lineHeight: '1.38', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt'}}>
                        <span style={{
                            fontSize: '11.5pt',
                            fontFamily: 'Arial',
                            color: '#757575',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap'
                        }}>To facilitate our Service;</span></p>
                </li>
                <li dir="ltr" style={{
                    listStyleType: 'disc',
                    fontSize: '11.5pt',
                    fontFamily: 'Arial',
                    color: '#757575',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre'
                }}>
                    <p dir="ltr"
                       style={{lineHeight: '1.38', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt'}}>
                        <span style={{
                            fontSize: '11.5pt',
                            fontFamily: 'Arial',
                            color: '#757575',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap'
                        }}>To provide the Service on our behalf;</span></p>
                </li>
                <li dir="ltr" style={{
                    listStyleType: 'disc',
                    fontSize: '11.5pt',
                    fontFamily: 'Arial',
                    color: '#757575',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre'
                }}>
                    <p dir="ltr"
                       style={{lineHeight: '1.38', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '0pt'}}>
                        <span style={{
                            fontSize: '11.5pt',
                            fontFamily: 'Arial',
                            color: '#757575',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap'
                        }}>To perform Service-related services; or</span></p>
                </li>
                <li dir="ltr" style={{
                    listStyleType: 'disc',
                    fontSize: '11.5pt',
                    fontFamily: 'Arial',
                    color: '#757575',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre'
                }}>
                    <p dir="ltr"
                       style={{lineHeight: '1.38', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '12pt'}}>
                        <span style={{
                            fontSize: '11.5pt',
                            fontFamily: 'Arial',
                            color: '#757575',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap'
                        }}>To assist us in analyzing how our Service is used.</span></p>
                </li>
            </ul>
            <p dir="ltr" style={{
                lineHeight: '1.38',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 12pt 0pt'
            }}><span style={{
                fontSize: '11.5pt',
                fontFamily: 'Arial',
                color: '#757575',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</span>
            </p>
            <h5 dir="ltr" style={{
                lineHeight: '1.7999999999999998',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 2pt 0pt'
            }}><span style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#323d47',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>Security</span></h5>
            <p dir="ltr" style={{
                lineHeight: '1.38',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 12pt 0pt'
            }}><span style={{
                fontSize: '11.5pt',
                fontFamily: 'Arial',
                color: '#757575',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</span>
            </p>
            <h5 dir="ltr" style={{
                lineHeight: '1.7999999999999998',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 2pt 0pt'
            }}><span style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#323d47',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>Links to Other Sites</span></h5>
            <p dir="ltr" style={{
                lineHeight: '1.38',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 12pt 0pt'
            }}><span style={{
                fontSize: '11.5pt',
                fontFamily: 'Arial',
                color: '#757575',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</span>
            </p>
            <h5 dir="ltr" style={{
                lineHeight: '1.7999999999999998',
                backgroundColor: '#ffffff',
                marginTop: '0pt',
                marginBottom: '0pt',
                padding: '0pt 0pt 2pt 0pt'
            }}><span style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#323d47',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap'
            }}>Changes to This Privacy Policy</span></h5>
            <p dir="ltr"
               style={{lineHeight: '1.38', backgroundColor: '#ffffff', marginTop: '0pt', marginBottom: '12pt'}}><span
                style={{
                    fontSize: '11.5pt',
                    fontFamily: 'Arial',
                    color: '#757575',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap'
                }}>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</span>
            </p>
        </div>
    )
}

export default Privacy;
