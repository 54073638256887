import React from "react";
import styles from "./SubmenusSliderItem.module.scss";
import classNames from "classnames";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import {useTranslation} from "react-i18next";
import {coloredIcons, submenuIcons, whiteIcons} from "../SelectIcons/SelectIcons";
import {useSelector} from "react-redux";
import {getTitleByLanguage} from "../../utils";

const SubmenusSliderItem = ({submenu, onClick, selected, children, className, itemId, onlyIcon}) => {

    const visibility = React.useContext(VisibilityContext);
    const {t, i18n} = useTranslation();
    const business = useSelector(state => state.client.business);

    const iconExists = !!(submenu.icon && coloredIcons[submenu.icon]);
    const defaultIcon = selected ? whiteIcons[submenuIcons.Food] : coloredIcons[submenuIcons.Food];
    const currentIcon = selected ? whiteIcons[submenu.icon] : coloredIcons[submenu.icon];
    const icon = iconExists ? currentIcon : defaultIcon;

    const classname = classNames(
        styles.submenusSliderItem,
        {[styles.selected]: selected},
        {[styles.onlyIcon]: onlyIcon},
        {"selected-submenu": selected},
    );

    return <div id={`submenu-slider-${submenu.id}`} className={classname} onClick={() => onClick(visibility)}>
        {icon}
        <span className={styles.submenusSliderItemTitle}>{getTitleByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, submenu)}</span>
    </div>
}

export default SubmenusSliderItem;
