import React from "react";
import styles from "./Textarea.module.scss";
import classNames from "classnames";
import {InputStyles} from "../Input";

export default function Textarea(props) {

    const {
        id,
        name,
        value,
        style,
        className,
        placeholder,
        autoComplete,
        onChange,
        onBlur
    } = props;

    const classes = classNames(styles.textareaInput, className,
        {[styles.materialTextareaInput]: style === InputStyles.Material});

    return (
        <textarea
            id={id}
            rows={3}
            name={name}
            className={classes}
            placeholder={placeholder}
            autoComplete={autoComplete}
            onChange={onChange}
            onBlur={onBlur} value={value} />
    )
}
