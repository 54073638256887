import React from "react";
import styles from "./Status.module.scss"
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {ReactComponent as ApprovedIcon} from "../../assets/icons/approve.svg";
import {ReactComponent as RejectedIcon} from "../../assets/icons/decline.svg";
import {ReactComponent as PendingIcon} from "../../assets/icons/time.svg";

export const StatusTypes = {
    APPROVED: "approved",
    REJECTED: "rejected",
    PENDING: "pending"
}

const StatusTextSQ = {
    [StatusTypes.APPROVED]: "Aprovuar",
    [StatusTypes.REJECTED]: "Refuzuar",
    [StatusTypes.PENDING]: "Ne pritje"
}

const StatusTextEN = {
    [StatusTypes.APPROVED]: "Approved",
    [StatusTypes.REJECTED]: "Rejected",
    [StatusTypes.PENDING]: "Pending"
}

const StatusLang = {
    en: StatusTextEN,
    sq: StatusTextSQ
}

const StatusIcons = {
    [StatusTypes.APPROVED]: <ApprovedIcon/>,
    [StatusTypes.REJECTED]: <RejectedIcon/>,
    [StatusTypes.PENDING]: <PendingIcon/>
}

const Status = ({type, className}) => {

    const {t, i18n} = useTranslation();

    const classes = classNames(styles.status, className,
        {[styles.green]: type === StatusTypes.APPROVED},
        {[styles.red]: type === StatusTypes.REJECTED},
        {[styles.yellow]: type === StatusTypes.PENDING});

    return (
        <div className={classes}>
            {StatusIcons[type]}
            {StatusLang[i18n.language][type]}
        </div>
    )
}

export default Status;
