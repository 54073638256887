import React from "react";
import "./DragAndDrop.scss";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {withTranslation} from "react-i18next";
import MenuRow from "../Rows/MenuRow/MenuRow";
import ProductRow from "../Rows/ProductRow/ProductRow";
import PostRow from "../Rows/PostRow/PostRow";
import FeedbackRow from "../Rows/FeedbackRow/FeedbackRow";
import OrderRow from "../Rows/OrderRow/OrderRow";

export const DraggableTypes = {
    MENU: "menu",
    POST: "post",
    FEEDBACK: "feedback",
    ORDER: "order",
    PRODUCT: "product",
}

const DragAndDrop = (props) => {

    const {
        t,
        type,
        items,
        ratings,
        onDragEnd,
        isSubmenu,
        onClickItem,
        onClickEdit,
        onClickDelete,
        onClickRatings,
        isDragDisabled,
        onClickActivate,
        onClickDeactivate
    } = props;

    console.log(onClickEdit);

    return <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}>

                    {items.map((item, index) => {
                        return !item.deleted && (
                            <Draggable index={index}
                                       key={index.toString()}
                                       draggableId={index.toString()}
                                       isDragDisabled={isDragDisabled === true}>
                                {(provided, snapshot) => (
                                    <div
                                        className="list-item"
                                        onClick={(e) => onClickItem && onClickItem(item, e)}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>

                                        {type === DraggableTypes.MENU &&
                                            <MenuRow
                                                menu={item}
                                                isSubmenu={isSubmenu}
                                                onActivateClick={(e) => onClickActivate(item, e)}
                                                onDeactivateClick={(e) => onClickDeactivate(item, e)}
                                                onEditClick={(e) => onClickEdit(item, e)}
                                                onDeleteClick={(e) => onClickDelete(item, e)}/>}

                                        {type === DraggableTypes.PRODUCT &&
                                            <ProductRow
                                                product={item}
                                                ratings={ratings}
                                                onRatingsClick={(e) => onClickRatings(item, e)}
                                                onEditClick={onClickEdit ? (e) => onClickEdit(item, e) : null}
                                                onDeleteClick={(e) => onClickDelete(item, e)}/>}

                                        {type === DraggableTypes.POST &&
                                            <PostRow
                                                post={item}
                                                onEditClick={(e) => onClickEdit(item, e)}
                                                onDeleteClick={(e) => onClickDelete(item, e)}/>}

                                        {type === DraggableTypes.FEEDBACK &&
                                            <FeedbackRow
                                                feedback={item}/>}

                                        {type === DraggableTypes.ORDER &&
                                            <OrderRow
                                                order={item}/>}
                                    </div>
                                )}
                            </Draggable>)
                    })}

                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </DragDropContext>
}

export default withTranslation()(DragAndDrop);
