import React, {Component} from "react";
import {
    callWaiter,
    clientExists,
    closeModal,
    closePopup,
    getBusinessBanners,
    getBusinessDailyMenus,
    getBusinessDetails,
    getBusinessDetailsById,
    getBusinessDetailsByUsername,
    getBusinessEvents,
    getBusinessMenus,
    getProductRatings,
    openModal,
    openPopup,
    setDeliveryEnabled,
    writeCookies
} from "../../actions/client";
import styles from "./Client.module.scss";
import {
    getAllProductsFromMenus,
    getAllSubmenusFromMenus,
    getQueryParameter,
    prepareCallWaiterData,
    setCookie
} from "../../utils";
import {CALL_WAITER_SECONDS, CLIENT_MODAL_TYPES} from "../../utils/constants";
import {connect} from "react-redux";
import BusinessHeader from "../../components/BusinessHeader/BusinessHeader";
import {Route, Switch, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import ReactGA from "react-ga";
import {authenticateClient} from "../../actions/user";
import Menu from "./Menu/Menu";
import Submenus from "./Submenus/Submenus";
import Product from "./Product/Product";
import DailyModal from "../../components/Modals/DailyMenuModal/DailyMenuModal";
import EventModal from "../../components/Modals/EventModal/EventModal";
import WishlistModal from "../../components/Modals/WishlistModal/WishlistModal";
import {matchPath} from "react-router";
import {BLACK_LIST_ADDRESS, CALL_WAITER_INVALID_DISTANCE} from "../../utils/constants/backend_exceptions";
import FeedbackModal from "../../components/Modals/FeedbackModal/FeedbackModal";
import Modal from "../../components/Modal/Client/ClientModal";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Floating from "../../components/Floating/Floating";
import FloatingReviewButton from "../../components/FloatingReviewButton/FloatingReviewButton";
import SearchModal from "../../components/Modals/SearchModal/SearchModal";
import SettingsModal from "../../components/Modals/SettingsModal/SettingsModal";
import LanguageModal from "../../components/Modals/LanguageModal/LanguageModal";
import {ReactComponent as CloseIcon} from "../../assets/icons/x.svg";
import RatingModal from "../../components/Modals/RatingModal/RatingModal";
import {bindActionCreators} from "redux";
import MenuUnavailable from "../../components/Cards/MenuUnavailable/MenuUnavailable";
import classNames from "classnames";
import BusinessModal from "../../components/Modals/BusinessModal/BusinessModal";
import ShowMoreModal from "../../components/Modals/ShowMoreModal/ShowMoreModal";
import LayoutModal from "../../components/Modals/LayoutModal/LayoutModal";

class Client extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menus: [],
            products: [],
            banners: [],
            events: [],
            daily_menus: [],
            countdown: 0,
            token: null,
            business: null,
            table_number: null,
            business_id: null,
            menuDisabled: false,
            showDisabledMessage: false
        }
    }

    async componentDidMount() {
        document.body.classList.add("client");

        const menu_token = getQueryParameter("token", window.location.href);
        try {
            let params = atob(menu_token);
            console.log("PARAMS", params);
            let url = window.location.href.split("?")[0] + "?" + params;
            let business_id = getQueryParameter("businessId", url);
            let table_number = getQueryParameter("tableId", url);

            if(business_id && table_number) {
                this.props.history.push(`/menu/${business_id}/${table_number}`)
                window.location.reload();
            }
        } catch (err) {
            alert("There was an issue with your request!");
            return;
        }

        let business_id = this.props.match.params.businessId;
        const table = getQueryParameter("table", window.location.href);
        if(business_id && table) {
            this.props.history.push(`/menu/${business_id}/${table}`)
            window.location.reload();
        }


        this.props.authenticateClient().then(async(res) => {
            localStorage.setItem("client_access_token", res.data.access_token);
            this.props.clientExists().then(res => {
                if (!res.data) {
                    this.props.writeCookies().then(({data}) => {
                        // alert("SETTING COOKIE")
                        setCookie(data.name, data.value, data.maxAge);
                    });
                }
            });




            let business_id = this.props.match.params.businessId;
            let table_number = this.props.match.params.table;
            if(!business_id || !table_number) {
                return;
            }

            const businessById = await this.props.getBusinessDetailsById(business_id);
            if(!businessById.data) {
                const businessByUsername = await this.props.getBusinessDetailsByUsername(business_id);
                if(!businessByUsername.data) {
                    alert("There was an issue with your request!");
                    return;
                } else {
                    this.props.history.push(`/menu/${businessByUsername.data.id}/${table_number}`)
                    window.location.reload();
                }
            }

            if(!businessById?.data?.configuration?.activation) {
                document.body.classList.add(["overflow-hidden", "pointer-events-none"]);
                this.setState({ menuDisabled: true });
                setTimeout(() => {
                    this.setState({ showDisabledMessage: true });
                }, 400);

            }

            this.setState({business_id, table_number});

            let countdown = localStorage.getItem("countdown");
            if(countdown) {
                countdown = parseInt(countdown);
                this.setCountdown(countdown);
            }


            window.onpopstate = () => {
                this.closeModal();
            }

            this.props.getProductRatings(business_id);
            this.props.getBusinessMenus(business_id);
            this.props.getBusinessDailyMenus(business_id);
            this.props.getBusinessEvents(business_id);
            this.props.getBusinessBanners(business_id);
            this.props.getBusinessDetails(business_id).then(res => {
                if(table_number === "delivery" && res.data?.configuration?.enableDelivery) {
                    this.props.setDeliveryEnabled(true);
                }
                const lng = localStorage.getItem("unem_language")
                this.props.i18n.changeLanguage(lng || (res.data?.configuration?.language || "sq"));
            });
        });

    }

    componentWillUnmount() {
        document.body.classList.remove("client");
    }

    goBack = () => {
        let business_id = this.props.match.params.businessId;
        let table_number = this.props.match.params.table;
        if(this.isProductPage()) {
            const pathSegments = this.props.location?.pathname?.split("/");
            const productId = pathSegments?.[pathSegments?.length - 1];
            if(productId) {
                const submenus = getAllSubmenusFromMenus(this.props.menus);
                const submenuOfProduct = submenus?.find((submenu) => {
                    return submenu.products?.find((product) => product.id.toString() == productId);
                })
                if(submenuOfProduct?.id) {
                    const menuOfProduct = this.props.menus.findIndex((menu) => {
                        return menu.subMenus?.find((submenu) => submenu.id.toString() == submenuOfProduct.id);
                    })
                    localStorage.setItem("selected_menu_index", menuOfProduct);
                    localStorage.setItem("selected_submenu", submenuOfProduct.id);
                    this.props.history.push(`/menu/${business_id}/${table_number}`);
                }
            }

        } else {
            this.props.history.push(`/menu/${business_id}/${table_number}/home`);
        }
    }

    closeModal = () => {
        this.props.closePopup();
        this.props.closeModal();
    }

    callWaiterEvent = (business_id, table_number) => {
        ReactGA.event({
            category: "Client event",
            action: "Clicked call waiter",
            label: `Business: ${business_id}. Table number: ${table_number}`
        });
    }

    callWaiter = () => {
        const {t, openModal} = this.props;
        const {business_id, table_number} = this.state;
        navigator.geolocation.getCurrentPosition(position => {
                const data = prepareCallWaiterData(position.coords.latitude, position.coords.longitude, table_number);
                this.setCountdown();
                // this.props.getCookie().then(res => console.log(res));

                this.props.callWaiter(business_id, data)
                    .then(res => null)
                    .catch(err => {
                        const code = err.response.data.code;
                        if (code === BLACK_LIST_ADDRESS[0]) {
                            alert(BLACK_LIST_ADDRESS[1]);
                        } else if (code === CALL_WAITER_INVALID_DISTANCE[0]) {
                            alert(t("Distanca juaj është shumë e madhe për të thirrur kamarjerin, kërkesa juaj do të shqyrtohet!") + "!")
                        } else {
                            alert("Kerkesa nuk u realizua!");
                        }
                    });
                this.callWaiterEvent(business_id, table_number);
            },
            error => {
            console.log(error);
                // const operating_system = getMobileOperatingSystem();
                // if(operating_system) {
                //
                // }
                alert(t("location_denied_ios"))
                // openModal(CLIENT_MODAL_TYPES.NOTIFICATIONS);
            }
        );
    }

    modalClasses = () => {
        const {modal_type} = this.props;
        if (modal_type === CLIENT_MODAL_TYPES.IMAGE) {
            return "menu image-popup";
        } else if (modal_type === CLIENT_MODAL_TYPES.EVENT) {
            return "menu events-popup";
        } else if (modal_type === CLIENT_MODAL_TYPES.FEEDBACK) {
            return "menu feedback-popup";
        } else if (modal_type === CLIENT_MODAL_TYPES.NOTIFICATIONS) {
            return "menu notifications-popup";
        } else {
            return "menu";
        }
    }

    setCountdown = (existing_time) => {
        let countdown_time = new Date();
        if(existing_time){
            countdown_time = countdown_time.getTime() + existing_time
        } else {
            countdown_time.setSeconds(countdown_time.getSeconds() + CALL_WAITER_SECONDS);
            countdown_time = countdown_time.getTime();
        }

        let interval = setInterval(() => {
            const current_time = new Date().getTime();
            const time_left = countdown_time - current_time;
            localStorage.setItem("countdown", time_left);
            const seconds = Math.round((time_left % (1000 * 60)) / 1000);
            this.setState({ countdown: seconds });
            console.log(seconds);

            if(time_left <= 0) {
                clearInterval(interval);
                localStorage.removeItem("countdown");

            }
        }, 1000)
    }

    isMenusPage = () => {
        return !!matchPath(this.props.location.pathname, {
            path: "/menu/:businessId/:table",
            exact: true,
        })
    }

    isProductPage = () => {
        return !!matchPath(this.props.location.pathname, {
            path: "/menu/:businessId/:table/products/:productId",
            exact: true,
        })
    }

    render() {
        const {t, menus, business, daily_menu, event, image, modal_type, is_modal_open, is_popup_open, is_delivery_enabled} = this.props;
        const {countdown, table_number, business_id, menuDisabled, showDisabledMessage} = this.state;
        const isCallWaiterEnabled = !!(business && business.enableCallWaiter);
        const matchMainPage = this.isMenusPage();
        const matchProductPage = this.isProductPage();

        return (
            <>
                <div className={classNames(styles.clientContentWrapper, {[styles.disabledClientContent]: menuDisabled})}>


                    <Modal
                        type={modal_type}
                        isModalOpen={is_popup_open}
                        handleClose={this.closeModal}>

                        {modal_type === CLIENT_MODAL_TYPES.SETTINGS &&
                        <SettingsModal/>}

                        {modal_type === CLIENT_MODAL_TYPES.LANGUAGE &&
                        <LanguageModal/>}

                        {modal_type === CLIENT_MODAL_TYPES.LAYOUT &&
                        <LayoutModal/>}

                    </Modal>

                    <SlidingPane
                        hideHeader
                        width="100%"
                        from="bottom"
                        isOpen={is_modal_open}
                        className={styles.slidingPane}
                        onRequestClose={this.closeModal}>

                        <div className={styles.slidingPaneClosButton}
                             onClick={this.closeModal}>
                            <CloseIcon/>
                        </div>

                        {modal_type === CLIENT_MODAL_TYPES.DAILY_MENU &&
                        <DailyModal
                            daily_menu={daily_menu}
                            handleClose={this.closeModal}/>}

                        {modal_type === CLIENT_MODAL_TYPES.EVENT &&
                        <EventModal
                            event={event}
                            handleClose={this.closeModal}/>}

                        {modal_type === CLIENT_MODAL_TYPES.WISHLIST &&
                        <WishlistModal
                            table_number={table_number}
                            business_id={business_id}
                            handleClose={this.closeModal}/>}

                        {modal_type === CLIENT_MODAL_TYPES.FEEDBACK &&
                        <FeedbackModal
                            image={image}
                            business_id={business_id}
                            handleClose={this.closeModal}/>}

                        {modal_type === CLIENT_MODAL_TYPES.SEARCH &&
                        <SearchModal
                            handleClose={this.closeModal}/>}

                        {modal_type === CLIENT_MODAL_TYPES.RATING &&
                        <RatingModal
                            business_id={business_id}
                            handleClose={this.closeModal}/>}

                        {modal_type === CLIENT_MODAL_TYPES.BUSINESS &&
                        <BusinessModal
                            handleClose={this.closeModal}/>}

                        {modal_type === CLIENT_MODAL_TYPES.SHOW_MORE &&
                        <ShowMoreModal
                            handleClose={this.closeModal}/>}


                    </SlidingPane>

                    {business &&
                    <BusinessHeader
                        minimized={true}
                        business={business}
                        homeButtonVisible={matchMainPage}
                        backButtonVisible={matchProductPage}
                        onBackButtonClick={() => this.goBack()}
                        onShowMoreButtonClick={() => this.props.openModal(CLIENT_MODAL_TYPES.SHOW_MORE)}
                        onBusinessLogoClick={() => this.props.openModal(CLIENT_MODAL_TYPES.BUSINESS)}
                        onSearchButtonClick={() => this.props.openModal(CLIENT_MODAL_TYPES.SEARCH)}
                        onSettingsIconClick={() => this.props.openPopup(CLIENT_MODAL_TYPES.SETTINGS)} />}


                    {!!menus.length &&
                    <div className={styles.clientContent}>
                        <Switch>
                            <Route exact path="/menu/:businessId/:table">
                                {/*{({match}) => (*/}
                                {/*    <CSSTransition*/}
                                {/*        in={match != null}*/}
                                {/*        timeout={300}*/}
                                {/*        classNames="page"*/}
                                {/*        unmountOnExit>*/}
                                {/*        <div className="page">*/}

                                <Submenus/>

                                {/*        </div>*/}
                                {/*    </CSSTransition>*/}
                                {/*)}*/}
                            </Route>
                            {/*<Route exact path="/menu/:businessId/:table/home">*/}
                                {/*{({match}) => (*/}
                                {/*    <CSSTransition*/}
                                {/*        in={matchMenus}*/}
                                {/*        timeout={300}*/}
                                {/*        classNames="page"*/}
                                {/*        unmountOnExit>*/}
                                {/*        <div className="page">*/}
                                {/*<Menu/>*/}


                                {/*<Menus/>*/}
                                {/*        </div>*/}
                                {/*    </CSSTransition>*/}
                                {/*)}*/}
                            {/*</Route>*/}
                            <Route exact path="/menu/:businessId/:table/menus/:menuId">
                                {/*{({match}) => (*/}
                                {/*    <CSSTransition*/}
                                {/*        in={matchSubmenus}*/}
                                {/*        timeout={300}*/}
                                {/*        classNames="page"*/}
                                {/*        unmountOnExit>*/}
                                {/*        <div className="page">*/}
                                <Submenus/>
                                {/*        </div>*/}
                                {/*    </CSSTransition>*/}
                                {/*)}*/}
                            </Route>
                            <Route
                                path="/menu/:businessId/:table/products/:productId"
                                render={(props) => {
                                    console.log(props.match.params.productId)
                                    return <Product {...props} key={props.match.params.productId}/>
                                }} />
                                {/*{({match}) => (*/}
                                {/*    <CSSTransition*/}
                                {/*        in={match != null}*/}
                                {/*        timeout={300}*/}
                                {/*        classNames="page"*/}
                                {/*        unmountOnExit>*/}
                                {/*        <div className="page">*/}
                                {/*        </div>*/}
                                {/*    </CSSTransition>*/}
                                {/*)}*/}
                        </Switch>
                    </div>}

                    {business &&
                    <Floating
                        countdown={countdown}
                        visible={!is_modal_open}
                        isCallWaiterEnabled={isCallWaiterEnabled}
                        handleCallWaiter={() => this.callWaiter()}/>}

                    {!!(business && !matchProductPage && !is_delivery_enabled) &&
                    <FloatingReviewButton />}

                </div>

                {showDisabledMessage &&
                <MenuUnavailable
                    text={t("menu-disabled-message", {name: business.name})}/>}
            </>

        );
    }

}

const mapStateToProps = state => ({
    menus: state.client.menus,
    business: state.client.business,
    wishlist: state.client.wishlist,
    daily_menu: state.client.daily_menu,
    event: state.client.event,
    image: state.client.image,
    modal_type: state.client.modal_type,
    is_modal_open: state.client.is_modal_open,
    is_popup_open: state.client.is_popup_open,
    is_delivery_enabled: state.client.is_delivery_enabled
});
const mapDispatchToProps = dispatch => ({
    openModal: (type) => dispatch(openModal(type)),
    closeModal: () => dispatch(closeModal()),
    openPopup: (type) => dispatch(openPopup(type)),
    closePopup: () => dispatch(closePopup()),
    clientExists: () => dispatch(clientExists()),
    writeCookies: () => dispatch(writeCookies()),
    getProductRatings: (id) => dispatch(getProductRatings(id)),
    authenticateClient: (data) => dispatch(authenticateClient(data)),
    callWaiter: (businessId, data) => dispatch(callWaiter(businessId, data)),
    getBusinessMenus: (businessId) => dispatch(getBusinessMenus(businessId)),
    getBusinessEvents: (businessId) => dispatch(getBusinessEvents(businessId)),
    getBusinessBanners: (businessId) => dispatch(getBusinessBanners(businessId)),
    getBusinessDetails: (businessId) => dispatch(getBusinessDetails(businessId)),
    getBusinessDetailsById: bindActionCreators(getBusinessDetailsById, dispatch),
    getBusinessDetailsByUsername: (businessId) => dispatch(getBusinessDetailsByUsername(businessId)),
    getBusinessDailyMenus: (businessId) => dispatch(getBusinessDailyMenus(businessId)),
    setDeliveryEnabled: (businessId) => dispatch(setDeliveryEnabled(businessId)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
const translation = withTranslation();
export default withRouter(connector(translation(Client)));
