import React from "react";
import styles from "./Statistic.module.scss";
import {ReactComponent as ProviderIcon} from "../../../assets/icons/provider.svg"

export default function Statistic({ text, number }) {
    return (
        <div className={styles.statistic}>
            <div className={styles.statisticIcon}>
                <ProviderIcon />
            </div>
            <div className={styles.statisticSubtitle}>
                {text}
            </div>
            <div className={styles.statisticTitle}>
                { number }
            </div>
        </div>
    )
}
