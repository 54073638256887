import React from "react";
import styles from "./Card.module.scss";
import classNames from "classnames";

const Card = ({onClick, children, className}) => (
    <div className={classNames(styles.card, className)}
        onClick={() => onClick && onClick()}>
        {children}
    </div>
)

export default Card;
