import React from "react";
import styles from "./MenusSliderItem.module.scss";
import classNames from "classnames";
import {BLANK_IMAGE} from "../../utils/constants";
import MenuImage from "../../assets/icons/menu.png";
import {useTranslation} from "react-i18next";
import {getTitleByLanguage} from "../../utils";
import {useSelector} from "react-redux";

const MenusSliderItem = ({menu, onClick, className}) => {

    const image = menu.thumbnail || menu.photoUrl;
    const {t, i18n} = useTranslation();
    const business = useSelector(state => state.client.business);

    return <div className={classNames(styles.menu, className)} onClick={() => onClick && onClick()} role="button">
        <div style={{backgroundImage: "url('" + (image || MenuImage) + "')"}} className={styles.menuCardImage} />
        <p>{getTitleByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, menu)}</p>
    </div>
}

export default MenusSliderItem;
