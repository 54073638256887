import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../Button/Button";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";
import {validate} from "../../../utils/validation";
import {inputValidations} from "../../../utils/validation/configurations";
import {askForConfirmation} from "../../../utils";
import {setLoading} from "../../../actions";
import {createTable, generateTables, getTables} from "../../../actions/tables";
import {connect} from "react-redux";
import Input, {InputStyles} from "../../Input/Input";

class GenerateTablesSidebar extends Component {

    state = {
        showValidation: false,
        numberOfTables: ""
    }

    componentDidMount() {
        //
    }

    generateTables = async() => {
        const {numberOfTables} = this.state;
        const numberOfTablesInt = parseInt(numberOfTables);
        const {t} = this.props;

        if(validate(inputValidations.numberOfTablesInput, numberOfTables)) {
            this.setState({showValidation: true});
            return;
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.generateTables(numberOfTablesInt).then(res => {
            this.props.getTables();
            this.props.onCloseButtonClick();
            this.props.setLoading(false);
        })
    }

    render() {
        const {t} = this.props;
        const {numberOfTables, showValidation} = this.state;
        return (
            <div>
                <div className="d-flex justify-content-between mb-16">
                    <h2>{t("Generate QR Codes")}</h2>
                    <div className="d-flex">
                        <Button
                            type={ButtonTypes.Icon}
                            onClick={this.props.onCloseButtonClick}>
                            <CloseIcon/>
                        </Button>
                    </div>
                </div>
                <Input
                    label={t("Number of tables")}
                    id="numberOfTablesInput"
                    name="numberOfTablesInput"
                    type="number"
                    value={numberOfTables}
                    style={InputStyles.Material}
                    validations={[inputValidations.numberOfTablesInput, showValidation]}
                    onChange={(e) => this.setState({numberOfTables: e.target.value})}/>
                <Button
                    className="mt-24"
                    size={ButtonSizes.Full}
                    color={ButtonColors.Green}
                    type={ButtonTypes.Filled}
                    onClick={() => this.generateTables()}>
                    {t("Generate QR Codes")}
                </Button>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    getTables: data => dispatch(getTables()),
    generateTables: data => dispatch(generateTables(data))
});
const connector = connect(null, mapDispatchToProps);
export default connector(withTranslation()(GenerateTablesSidebar));

