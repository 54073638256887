import axios from "axios";
import imageCompression from 'browser-image-compression';
import {compression_options} from "../utils/constants";

const url = process.env.REACT_APP_BACKEND_URL;

export const uploadMediaImage = (data) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return imageCompression(data, compression_options)
            .then(function (compressedFile) {
                const filename = new Date().getTime().toString();
                const formData = new FormData();
                formData.append('file', compressedFile, filename + ".png");
                return axios.post(url + `media/images`, formData, {
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                }).then(res => {
                    console.log(res);
                    return res;
                });
            })
            .catch(function (error) {
                console.log(error.message);
            });
    }
}

export const uploadMediaVideo = (data) => {
    const token = localStorage.getItem("access_token");
    const formData = new FormData();
    formData.append('file', data);
    return function (dispatch) {
        return axios.post(url + `media/videos`, formData, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

