import axios from "axios";
import * as constants  from "../utils/constants";

const url = process.env.REACT_APP_BACKEND_URL;

export const generateTables = (numberOfTables) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.post(url + `tables`, {
            number: numberOfTables
        },{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const createTable = (data) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.post(url + `tables/one`, data,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const updateTable = (tableId, data) => {
    console.log(data);
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.put(url + `tables/${tableId}`, data,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const deleteTable = (tableId) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.delete(url + `tables/${tableId}`,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const generateQrCode = (tableId) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return fetch(url + `tables/${tableId}/qr-codes`,{
            method: "GET",
            headers: {
                // responseType: 'blob',
                "Authorization": "Bearer " + token
            }
        }).then(response => {
            response.arrayBuffer().then(function(buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "image.png"); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        }).catch(err => {
            console.log(err)
        })
    }
}

export const getTables = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.get(url + "tables", {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            dispatch({ type: constants.GET_TABLES, payload: res.data });
            return res;
        });
    }
}

export const getTableNotifications = () => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.get(url + `notifications/`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const deleteTableNotifications = (tableId) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.put(url + `notifications/${tableId}`, {}, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const deleteTables = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.delete(url + `tables`,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const blacklist = (value) => {
    const token = localStorage.getItem("access_token");
    const data = { value };
    return function(dispatch) {
        return axios.post(url + `black-list-addresses`, data,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}
