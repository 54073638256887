import axios from "axios";
import * as constants from "../utils/constants";
import imageCompression from "browser-image-compression";
import {compression_options, thumbnail_compression_options} from "../utils/constants";

const url = process.env.REACT_APP_BACKEND_URL;

export const setSubmenus = data => ({
    type: constants.SET_SUBMENUS,
    payload: data
})

export const getSubmenus = menuId => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.get(url + `menus/${menuId}/sub-menus`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            dispatch({type: constants.GET_SUBMENUS, payload: res.data});
            return res;
        });
    }
}

export const getSubmenu = (menuId, submenuId) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.get(url + `menus/${menuId}/sub-menus/${submenuId}`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const createSubmenu = (menuId, data) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.post(url + `menus/${menuId}/sub-menus`, data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const updateSubmenu = (menuId, submenuId, data) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.put(url + `menus/${menuId}/sub-menus/${submenuId}`, data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const deleteSubmenu = (menuId, submenuId) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.delete(url + `menus/${menuId}/sub-menus/${submenuId}`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const updateSubmenuOrdering = (menuId, data) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return axios.put(url + `menus/${menuId}/sub-menus/ordering`, {
            orders: data
        }, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const activateSubmenu = (menuId, data) => {
    const token = localStorage.getItem("access_token");
    const submenu = {
        ...data,
        active: true
    }
    return function (dispatch) {
        return axios.put(url + `menus/${menuId}/sub-menus/${submenu.id}`, submenu, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const deactivateSubmenu = (menuId, data) => {
    const token = localStorage.getItem("access_token");
    const submenu = {
        ...data,
        active: false
    }
    return function (dispatch) {
        return axios.put(url + `menus/${menuId}/sub-menus/${submenu.id}`, submenu, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const addSubmenuImage = (menuId, submenuId, data) => {
    const token = localStorage.getItem("access_token");
    return function (dispatch) {
        return imageCompression(data, thumbnail_compression_options)
            .then(function (compressedFile) {
                const filename = new Date().getTime().toString();
                const formData = new FormData();
                formData.append('file', compressedFile, filename + ".png");
                return axios.post(url + `menus/${menuId}/sub-menus/${submenuId}/logos`, formData, {
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                }).then(res => {
                    return res;
                });
            })
            .catch(function (error) {
                console.log(error.message);
            });
    }
}
