import React from "react";
import "./Stars.scss";
import {STAR_TYPES} from "../../utils/constants";
import {ReactComponent as FilledStar} from "../../assets/icons/star.svg";
import {ReactComponent as HalfStar} from "../../assets/icons/star-half.svg";

const star_icons = {
    [STAR_TYPES.EMPTY]: <div />,
    [STAR_TYPES.HALF]: <HalfStar />,
    [STAR_TYPES.FULL]: <FilledStar />
}

const Stars = ({stars, handleClick}) => (
    <div className="stars"
         onClick={handleClick}>
        {stars.map(star => (
            star_icons[star]
        ))}
    </div>
)

export default Stars;
