import React from "react";
import styles from "./EventModal.module.scss";
import {useTranslation} from "react-i18next";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../Button/Button";
import {getDescriptionByLanguage, getTitleByLanguage} from "../../../utils";
import {useSelector} from "react-redux";

const EventModal = ({ event }) => {

    const { t, i18n } = useTranslation();
    const business = useSelector(state => state.client.business);

    return (
        <div className={styles.eventModal}>
            <div className={styles.eventModalImage} style={{backgroundImage: "url('" + event.pictureUrl + "')"}} />
            <div className="big-title mt-32">
                {getTitleByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, event)}
            </div>
            <p className="mt-32">Description</p>
            <p className="text-tertiary">
                {getDescriptionByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, event)}
            </p>
            {!!event.link && <Button
                className="mt-32"
                type={ButtonTypes.Filled}
                color={ButtonColors.Green}
                size={ButtonSizes.Full}
                onClick={() => window.open(event.link, "_blank")}>
                {t("Visit link")}
            </Button>}
        </div>
    )
}

export default EventModal;
