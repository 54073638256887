import React, {Component} from "react";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import styles from "./Search.module.scss";
import Input, {InputStyles} from "../../../components/Input/Input";
import Logo from "../../../assets/icons/logo-text.svg";
import BusinessCard from "../../../components/Cards/BusinessCard/BusinessCard";
import {clientExists, getBusinesses, writeCookies} from "../../../actions/client";
import {authenticateClient} from "../../../actions/user";
import {setCookie} from "../../../utils";
import Empty from "../../../components/Empty/Empty";
import EmptySearch from "../../../components/EmptySearch/EmptySearch";

class Search extends Component {

    state = {
        searchText: "",
        businesses: [],
        loading: false,
        accessToken: null
    }

    componentDidMount() {
        this.props.authenticateClient().then(res => {
            this.setState({ accessToken: res.data.access_token });
            localStorage.setItem("client_access_token", res.data.access_token);

            this.props.clientExists().then(res => {
                if (!res.data) {
                    this.props.writeCookies().then(({data}) => {
                        // alert("SETTING COOKIE")
                        setCookie(data.name, data.value, data.maxAge);
                    });
                }
            });
        });
    }

    debounce = null;
    onSearchInputChange = (e) => {
        const {accessToken} = this.state;

        if(!accessToken) {
            return;
        }

        const value = e.target.value;
        this.setState({ searchText: value, loading: true });

        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
            this.props.getBusinesses(value, accessToken)
                .then(res => this.setState({ loading: false }));
        }, 500);
    }

    goToBusiness = (business) => {
        this.props.history.push(`/menu/${business.id}/delivery`);
    }

    render() {
        const {t, i18n, businesses} = this.props;
        const {searchText, loading} = this.state;


        return (
            <div className={styles.searchContainer}>

                <img src={Logo} alt="logo" className={styles.logo}/>

                <Input
                    type="text"
                    value={searchText}
                    id="searchTextInput"
                    placeholder={t("Search")}
                    style={InputStyles.Standard}
                    onChange={(e) => this.onSearchInputChange(e)}/>


                {!!searchText ? <div>
                        {!loading ?
                            businesses.length ? <div className={styles.businessesContainer}>
                                {businesses.map(business =>
                                    <BusinessCard
                                        className="mb-16"
                                        business={business}
                                        onClick={() => this.goToBusiness(business)} />
                                )}
                            </div>
                                : <div className="mt-94 text-center">
                                    <EmptySearch noResults />
                                    <p className="mt-16">{t("Sorry we couldn't find any matches")}</p>
                                    <p className="text-tertiary">{t("Please try searching with another term")}</p>
                                </div>
                            : <div className="text-center mt-94">
                                <div className="spinner-border text-primary"/>
                            </div>}
                    </div> :
                        <div className="mt-94 text-center">
                            <EmptySearch />
                            <p className="mt-16">{t("Search for restaurants")}</p>
                            <p className="text-tertiary">{t("Start searching by restaurant name to start ordering!")}</p>
                        </div>}

            </div>
        );
    }
}

const mapStateToProps = state => ({
    businesses:  state.client.businesses
});
const mapDispatchToProps = dispatch => ({
    clientExists: () => dispatch(clientExists()),
    writeCookies: () => dispatch(writeCookies()),
    getBusinesses: (name, token) => dispatch(getBusinesses(name, token)),
    authenticateClient: (data) => dispatch(authenticateClient(data)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
const translation = withTranslation();
export default withRouter(connector(translation(Search)));
