import React, {Component} from "react";
import "./Products.scss";
import {connect} from "react-redux";
import {
    addProductImage,
    createProduct,
    deleteProduct,
    getProductImages, getProductsRatings,
    getProducts,
    removeProductImage,
    setProducts,
    updateProduct, getProductRating, addProductImageLink
} from "../../../actions/products";
import Modal from "../../../components/Modal/Modal";
import {
    askForConfirmation,
    editingTitle,
    getProductStarTypes,
    prepareRatingPercentages,
    reorder,
    search,
    sort
} from "../../../utils";
import {getSubmenu} from "../../../actions/submenus";
import {withRouter} from "react-router-dom";
import DragAndDrop, {DraggableTypes} from "../../../components/Draggable/DragAndDrop";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from '@fortawesome/free-solid-svg-icons'
import {
    EMPTY_PRODUCT_OBJECT,
    PRODUCT_MODAL_TYPES,
    PRODUCT_SORTING_TYPES,
    PRODUCTS_SIDEBAR_CONTENT, SUBMENU_SIDEBAR_CONTENT
} from "../../../utils/constants";
import {withTranslation} from "react-i18next";
import {setLoading} from "../../../actions";
import Bars from "../../../components/Rating/Bars/Bars";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { ReactComponent as SortIcon } from "../../../assets/icons/sort.svg";
import Stars from "../../../components/Stars/Stars";
import Button, {ButtonColors, ButtonTypes} from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import {inputValidations} from "../../../utils/validation/configurations";
import {validate} from "../../../utils/validation";
import Drawer from "../../../components/Drawer/Drawer";
import CreateProductSidebar from "../../../components/Sidebars/CreateProductSidebar/CreateProductSidebar";
import EditProductSidebar from "../../../components/Sidebars/EditProductSidebar/EditProductSidebar";
import ProductRatingsSidebar from "../../../components/Sidebars/ProductRatingsSidebar/ProductRatingsSidebar";

class Products extends Component {
    popUpQuestion = "A jeni të sigurt të vazhdoni?";

    constructor(props) {
        super(props);

        this.state = {
            showValidation: false,
            editing: false,
            isModalOpen: false,
            product: {
                name: "",
                nameEn: "",
                price: "",
                description: "",
                descriptionEn: "",
                preparingTime: "",
                images: []
            },
            image: null,
            search_text: "",
            rating: null,
            modal_content: PRODUCT_MODAL_TYPES.FORM,
            sorting: PRODUCT_SORTING_TYPES.RATING_HIGHEST,
            sidebar_content: PRODUCTS_SIDEBAR_CONTENT.CREATE_PRODUCT
        }
    }

    imageInputRef = null;
    importInputRef = null;

    sortingDropdownOptions = [
        {
            value: PRODUCT_SORTING_TYPES.RATING_HIGHEST,
            label: this.props.t("Rating (highest)")
        },
        {
            value: PRODUCT_SORTING_TYPES.RATING_LOWEST,
            label: this.props.t("Rating (lowest)")
        }
    ]

    componentDidMount() {
        this.props.getProducts();
        this.props.getProductRatings();
    }

    openModal = () => {
        this.setState({isModalOpen: true});
    }

    closeModal = () => {
        this.setState({
            showValidation: false,
            isModalOpen: false,
            modal_content: PRODUCT_MODAL_TYPES.FORM,
            editing: false,
            product: EMPTY_PRODUCT_OBJECT,
            sidebar_opened: false
        });
    }

    clearProduct = () => {
        this.setState({
            product: EMPTY_PRODUCT_OBJECT,
        });
    }

    onClickCreate = () => {
        this.setState({modal_content: PRODUCT_MODAL_TYPES.FORM, editing: false, imagesModal: false});
        this.openModal()
    }

    onClickImages = (item, e) => {
        e.stopPropagation();
        this.setState({modal_content: PRODUCT_MODAL_TYPES.IMAGES, editing: false, product: item});
        this.openModal();
    }

    onClickEdit = (item, e) => {
        e.stopPropagation();
        this.setState({modal_content: PRODUCT_MODAL_TYPES.FORM, editing: true, product: item});
        this.openModal()
    }

    onClickRating = (item, e) => {
        this.props.setLoading(true);
        this.props.getProductRating(item.id).then(res => {
            this.props.setLoading(false);
            const rating = prepareRatingPercentages(res.data);
            console.log(rating);
            this.setState({modal_content: PRODUCT_MODAL_TYPES.RATING, product: item, rating});
            this.openModal()
        })

    }

    onClickDelete = async(item, e) => {
        e.stopPropagation();
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.deleteProduct(item.id).then(res => {
            this.props.getProducts();
            this.closeModal();
            this.props.setLoading(false);
        })
    }

    handleCreateProduct = async() => {
        const {t} = this.props;

        if(this.formIsInvalid()) {
            this.setState({showValidation: true});
            return;
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.createProduct(this.state.product).then(res => {
            this.props.getProducts();
            this.closeModal();
            this.props.setLoading(false);
        });
    }

    handleUpdateProduct = async() => {
        const {t} = this.props;

        if(this.formIsInvalid()) {
            this.setState({showValidation: true});
            return;
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.updateProduct(this.state.product.id, this.state.product).then(res => {
            this.props.getProducts();
            this.closeModal();
            this.props.setLoading(false);
        });
    }

    handleUploadImage = e => {
        if (!(e && e.target && e.target.files && e.target.files[0])) {
            return;
        }

        let file = e.target.files[0];
        let productId = this.state.product.id;
        this.props.setLoading(true);
        this.props.addProductImage(productId, file).then(res => {
            this.props.getProducts();
            this.props.getProductImages(productId).then(res => {
                this.setState({product: {...this.state.product, images: res.data}});
                this.props.setLoading(false);
            })
        })
    }

    handleDeleteImage = image => {
        let productId = this.state.product.id;
        this.props.setLoading(true);
        this.props.removeProductImage(productId, image.id).then(res => {
            this.props.getProducts();
            this.props.getProductImages(productId).then(res => {
                this.setState({product: {...this.state.product, images: res.data}})
                this.props.setLoading(false);
            })
        })
    }


    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        console.log("result", result);
        const products = reorder(
            this.props.products,
            result.source.index,
            result.destination.index
        );
        this.props.setProducts(products);
    }

    getRatingOfProduct = (productId) => {
        const {ratings} = this.props;
        if (ratings.length) {
            const rating = ratings.find(rating => rating.productId == productId);
            return rating ? (rating.averageRate).toFixed(1) : 0;
        }
    }

    getRatingsCountProduct = (productId) => {
        const {ratings} = this.props;
        if (ratings.length) {
            const rating = ratings.find(rating => rating.productId == productId);
            return rating ? rating.count : 0;
        }
    }

    handleSortingChange = (value) => {
        this.setState({ sorting: value });
    }
    
    handleImportProducts = (e) => {
        const files = e.target.files;
        if(!files.length) {
            return;
        }
        
        if(!window.confirm("Are you sure you want to import products?")) {
            return;
        }
    
        this.props.setLoading(false);
        let fr = new FileReader();
        fr.onload = (e) => {
            try {
                const result = JSON.parse(e.target.result);
                if(result.data && result.data.length) {
                    for(let i = 0; i < result.data.length; i++) {
                        console.log(result.data[i]);
                        const product = result.data[i];
                        this.props.createProduct(product).then(res => {
                            if(product.images && product.images.length) {
                                this.props.addProductImageLink(res.data.id, product.images[0].link)
                                    .then(res => {
                                        if(i == (result.data.length - 1)) {
                                            this.props.getProducts();
                                            this.props.setLoading(false);
                                        }
                                    });
                            } else {
                                if(i == (result.data.length - 1)) {
                                    this.props.getProducts();
                                    this.props.setLoading(false);
                                }
                            }
                        });
                    }
                } else {
                    alert("Not valid data!");
                    this.props.setLoading(false);
                }
            } catch (e) {
                alert("Not valid json!");
                this.props.setLoading(false);
            }
        };
        fr.readAsText(files.item(0));
    };
    
    handleExportProducts = (e) => {
    
        if(!window.confirm("Are you sure you want to export products?")) {
            return;
        }
        
        const filename = 'data.json';
        const data = {
            data: this.props.products
        }
        const jsonStr = JSON.stringify(data);
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr));
        element.setAttribute('download', filename);
    
        element.style.display = 'none';
        document.body.appendChild(element);
    
        element.click();
    
        document.body.removeChild(element);
    };

    formIsInvalid = () => {
        const {product} = this.state;
        return validate(inputValidations.productNameInput, product.name) ||
            validate(inputValidations.productNameEnInput, product.nameEn) ||
            validate(inputValidations.productDescriptionInput, product.description) ||
            validate(inputValidations.productDescriptionEnInput, product.descriptionEn) ||
            validate(inputValidations.productPriceInput, product.price) ||
            validate(inputValidations.productDeliveryInput, product.preparingTime);
    }

    openCreateProductSidebar = () => {
        this.clearProduct()
        this.setState({
            sidebar_content: PRODUCTS_SIDEBAR_CONTENT.CREATE_PRODUCT,
            sidebar_opened: true,
        });
    }

    openEditProductSidebar = (product, e) => {
        e.stopPropagation();
        this.clearProduct()
        this.setState({
            product: product,
            sidebar_content: PRODUCTS_SIDEBAR_CONTENT.EDIT_PRODUCT,
            sidebar_opened: true,
        });
    }

    openRatingsSidebar = (product, e) => {
        e.stopPropagation();
        this.props.setLoading(true);
        this.props.getProductRating(product.id).then(res => {
            this.props.setLoading(false);
            const rating = prepareRatingPercentages(res.data);
            this.setState({
                product: product,
                rating: rating,
                sidebar_opened: true,
                sidebar_content: PRODUCTS_SIDEBAR_CONTENT.RATINGS,
            });
        });
    }

    updateData = () => {
        this.props.getProducts();
        this.setState({sidebar_opened: false});
        this.props.setLoading(false);
    }

    render() {
        const {search_text, isModalOpen, product, editing, modal_content, rating, sorting, showValidation, sidebar_content, sidebar_opened} = this.state;
        const {ratings, products_fetched, submenu, t} = this.props;
        const products = sort(search(this.props.products, search_text, "name"), ratings, sorting);
        const isImagesModal = modal_content === PRODUCT_MODAL_TYPES.IMAGES;
        const isRatingModal = modal_content === PRODUCT_MODAL_TYPES.RATING;

        return (
            <div className="products-page">

                <div className="page-header-row">
                    <h1>{t("Products")}</h1>
                    <div className="d-flex">

                        {/*<Button*/}
                        {/*    className="mr-2"*/}
                        {/*    content={t("Import Products")}*/}
                        {/*    type={ButtonTypes.Add}*/}
                        {/*    onClick={() => this.importInputRef.click()} />*/}

                        {/*<input className="d-none"*/}
                        {/*       type="file"*/}
                        {/*       ref={ref => this.importInputRef = ref}*/}
                        {/*       onChange={e => this.handleImportProducts(e)}/>*/}

                        {/*<Button*/}
                        {/*    className="mr-2"*/}
                        {/*    content={t("Export Products")}*/}
                        {/*    type={ButtonTypes.Add}*/}
                        {/*    onClick={(e) => this.handleExportProducts(e)} />*/}
                        <Button
                            color={ButtonColors.Green}
                            onClick={() => this.openCreateProductSidebar()}>
                            + {t("Create Product")}
                        </Button>
                    </div>
                </div>

                <div className="page-search-row">
                    <Input
                        type="text"
                        id="searchTablesInput"
                        name="searchTablesInput"
                        placeholder={t("Search for products")}
                        value={search_text}
                        onChange={(e) => this.setState({search_text: e.target.value})}/>

                    <Dropdown
                        value={sorting}
                        placeholder={t("Sort by")}
                        icon={<SortIcon/>}
                        options={this.sortingDropdownOptions}
                        onChange={(value) => this.handleSortingChange(value)}/>

                </div>
                <div className="box-body">

                    {products_fetched ?
                        <>
                            {products.length > 0 ?
                                <DragAndDrop
                                    isDragDisabled
                                    items={products}
                                    ratings={ratings}
                                    type={DraggableTypes.PRODUCT}
                                    onClickRatings={(item, e) => this.openRatingsSidebar(item, e)}
                                    onClickEdit={(item, e) => this.openEditProductSidebar(item, e)}
                                    onClickDelete={(item, e) => this.onClickDelete(item, e)}
                                    onDragEnd={(result) => this.onDragEnd(result)}/> : t("No products") + "..."}
                        </>
                        : <div className="text-center py-3">
                            <div className="spinner-border text-info"/>
                        </div>
                    }

                </div>

                <Drawer
                    open={sidebar_opened}>

                    {sidebar_content === PRODUCTS_SIDEBAR_CONTENT.CREATE_PRODUCT &&
                    <CreateProductSidebar
                        onSubmit={() => this.updateData()}
                        onCloseButtonClick={this.closeModal}/>}


                    {sidebar_content === PRODUCTS_SIDEBAR_CONTENT.EDIT_PRODUCT &&
                    <EditProductSidebar
                        product={product}
                        onSubmit={() => this.updateData()}
                        onCloseButtonClick={this.closeModal}/>}


                    {sidebar_content === PRODUCTS_SIDEBAR_CONTENT.RATINGS &&
                    <ProductRatingsSidebar
                        rating={rating}
                        product={product}
                        onCloseButtonClick={this.closeModal}/>}

                </Drawer>

            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        products: state.app.products,
        products_fetched: state.app.products_fetched,
        submenu: state.app.submenu,
        ratings: state.app.ratings
    }
}

const mapDispatchToProps = dispatch => ({
    getProductRating: (productId) => dispatch(getProductRating(productId)),
    setLoading: (data) => dispatch(setLoading(data)),
    getProducts: data => dispatch(getProducts()),
    getProductRatings: data => dispatch(getProductsRatings()),
    createProduct: data => dispatch(createProduct(data)),
    updateProduct: (productId, data) => dispatch(updateProduct(productId, data)),
    deleteProduct: productId => dispatch(deleteProduct(productId)),
    setProducts: data => dispatch(setProducts(data)),
    getSubmenu: (menuId, submenuId) => dispatch(getSubmenu(menuId, submenuId)),
    getProductImages: (productId) => dispatch(getProductImages(productId)),
    addProductImage: (productId, data) => dispatch(addProductImage(productId, data)),
    removeProductImage: (productId, imageId) => dispatch(removeProductImage(productId, imageId)),
    addProductImageLink: (productId, data) => dispatch(addProductImageLink(productId, data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Products)));
