import axios from "axios";
import * as constants  from "../utils/constants";

const url = process.env.REACT_APP_BACKEND_URL;

export const setMenus = data => ({
    type: constants.SET_MENUS,
    payload: data
})

export const getBusinessMenus = (businessId) => {
    const token = localStorage.getItem("client_access_token");
    return axios.get(url + `businesses/${businessId}/menus`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
}

export const getBusinessDailyMenus = (businessId) => {
    const token = localStorage.getItem("client_access_token");
    return axios.get(url + `businesses/${businessId}/daily-menus`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    }).then(res => {
        console.log(res);
        return res;
    });
}

export const getBusinessBanners = (businessId) => {
    const token = localStorage.getItem("client_access_token");
    return axios.get(url + `businesses/${businessId}/banners`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    }).then(res => {
        console.log(res);
        return res;
    });
}

export const getBusinessEvents = (eventId) => {
    const token = localStorage.getItem("client_access_token");
    return axios.get(url + `businesses/${eventId}/events`, {
        headers: {
            "Authorization": "Bearer " + token
        }
    }).then(res => {
        console.log(res);
        return res;
    });
}

export const getMenus = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.get(url + "menus", {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            dispatch({ type: constants.GET_MENUS, payload: res.data });
            return res;
        });
    }
}

export const createMenu = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.post(url + "menus", data,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const updateMenu = (menuId, data) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.put(url + `menus/${menuId}`, data,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const deleteMenu = menuId => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.delete(url + `menus/${menuId}`,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const activateMenu = (data) => {
    const token = localStorage.getItem("access_token");
    const menu = {
        ...data,
        active: true
    };
    return function(dispatch) {
        return axios.put(url + `menus/${menu.id}`, menu,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const deactivateMenu = (data) => {
    const token = localStorage.getItem("access_token");
    const menu = {
        ...data,
        active: false
    };
    return function(dispatch) {
        return axios.put(url + `menus/${menu.id}`, menu,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const updateMenuOrdering = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.put(url + "menus/ordering", {
            orders: data
        },{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}


