import React from "react";
import styles from "./MenuRow.module.scss";
import {ReactComponent as DragIcon} from "../../../assets/icons/6-dots.svg";
import {ReactComponent as EditIcon} from "../../../assets/icons/edit.svg";
import {ReactComponent as DeleteIcon} from "../../../assets/icons/trash.svg";
import {ReactComponent as ActivateIcon} from "../../../assets/icons/tick.svg";
import {ReactComponent as DeactivateIcon} from "../../../assets/icons/block-1.svg";
import {BLANK_IMAGE} from "../../../utils/constants";
import Button, {ButtonTypes} from "../../Button/Button";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";
import {coloredIcons, submenuIcons} from "../../SelectIcons/SelectIcons";

const MenuRow = ({menu, isSubmenu, onActivateClick, onDeactivateClick, onEditClick, onDeleteClick}) => {
    const {t} = useTranslation();

    const menuImage = (
        <div className={styles.image}
             style={{backgroundImage: "url('" + (((menu.photoUrl || menu.pictureUrl) || menu.logo) || BLANK_IMAGE) + "')"}}/>
    )

    const submenuImage = (
        <div className={styles.image}>{menu.icon ? coloredIcons[menu.icon] : coloredIcons[submenuIcons.Food]}</div>
    )

    return (
        <div className={styles.menuRow}>
            <DragIcon className={styles.dragIcon}/>
            {isSubmenu ? submenuImage : menuImage}
            <div className={styles.content}>
                <p className={styles.title}>{menu.title}</p>
                {/*<p className={styles.subtitle}>1 product</p>*/}
            </div>
            <div className={styles.actions}
                onClick={e => e.stopPropagation()}>
                {menu.active ?
                    <div data-tip={t("Deactivate")}>
                        <Button
                            type={ButtonTypes.Icon}
                            onClick={onDeactivateClick}>
                            <DeactivateIcon/>
                        </Button>
                    </div> :
                    <div data-tip={t("Activate")}>
                        <Button
                            type={ButtonTypes.Icon}
                            onClick={onActivateClick}>
                            <ActivateIcon/>
                        </Button>
                    </div>}
                    <div data-tip={t("Edit")}>
                    <Button
                        type={ButtonTypes.Icon}
                        onClick={onEditClick}>
                        <EditIcon/>
                    </Button>
                </div>
                <div data-tip={t("Delete")}>
                    <Button
                        icon={<DeleteIcon/>}
                        type={ButtonTypes.Icon}
                        onClick={onDeleteClick}>
                        <DeleteIcon/>
                    </Button>
                </div>
            </div>
            <ReactTooltip place="top" type="dark" effect="float"/>
        </div>
    )
}

export default MenuRow;
