import React from "react";
import styles from "./BusinessHeader.module.scss";
import {useTranslation} from "react-i18next";
import {ReactComponent as SearchIcon} from "../../assets/icons/search.svg";
import {ReactComponent as SettingsIcon} from "../../assets/icons/gear.svg";
import {ReactComponent as LeftArrowIcon} from "../../assets/icons/left.svg";
import {ReactComponent as BellICon} from "../../assets/icons/order.svg";
import {ReactComponent as HomeIcon} from "../../assets/icons/home.svg";
// import HomeIcon from "../../assets/icons/home.png";


const BusinessHeader = ({ business, homeButtonVisible, backButtonVisible, onBackButtonClick, onSearchButtonClick, onSettingsIconClick, onBusinessLogoClick, onShowMoreButtonClick }) => {

    const { t, i18n } = useTranslation();

    return (
        <div className={styles.businessHeader}>
            {backButtonVisible ?
                <button onClick={onBackButtonClick}>
                    <LeftArrowIcon />
                </button>
                : business.logo ?
                    <img src={business.logo} alt="business-logo" className={styles.businessLogo} onClick={onBusinessLogoClick}/>
                        : <h4>{business.name}</h4>}

            <div className={styles.businessHeaderActions}>
                <button onClick={onSearchButtonClick}>
                    <SearchIcon />
                </button>
                {!backButtonVisible &&
                <button onClick={onShowMoreButtonClick}>
                    <BellICon />
                </button>}
                {!backButtonVisible &&
                <button onClick={onSettingsIconClick}>
                    <SettingsIcon />
                </button>}
            </div>
        </div>
    )

}

export default BusinessHeader;
