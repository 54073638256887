import * as constants from "../utils/constants/index";

const initialState = {
    menus: [],
    daily_menus: [],
    submenus: [],
    products: [],
    tables: [],
    events: [],
    banners: [],
    ratings: [],
    feedbacks: [],
    orders: [],
    delivery_orders: [],
    delivery_orders_fetched: false,
    orders_fetched: false,
    menus_fetched: false,
    feedbacks_fetched: false,
    more_feedbacks: true,
    events_fetched: false,
    daily_menus_fetched: false,
    submenus_fetched: false,
    products_fetched: false,
    tables_fetched: false,
    banners_fetched: false,
    firebase_token: localStorage.getItem("firebase_token"),
    access_token: localStorage.getItem("access_token"),
    user_details: null,
    user_account_details: null,
    user_details_fetched: false,
    loading: false
}

export const app = (state = initialState, action) => {
    switch (action.type) {

        case constants.LOGIN:
            localStorage.setItem("access_token", action.payload.access_token);
            return {
                ...state,
                access_token: action.payload.access_token
            };

        case constants.LOGOUT:
            localStorage.removeItem("access_token");
            return {
                ...state,
                user_details: {},
                access_token: null
            };

        case constants.GET_ORDERS:
            return {
                ...state,
                orders: action.payload,
                orders_fetched: true,
            };

        case constants.GET_DELIVERY_ORDERS:
            return {
                ...state,
                delivery_orders: action.payload,
                delivery_orders_fetched: true,
            };

        case constants.GET_BUSINESS_DETAILS:
            return {
                ...state,
                user_details: action.payload,
            };

        case constants.FETCHED_USER_DETAILS:
            return {
                ...state,
                user_details_fetched: true,
            };

        case constants.GET_DAILY_MENUS:
            return {
                ...state,
                daily_menus: action.payload,
                daily_menus_fetched: true
            };

        case constants.SET_DAILY_MENUS:
            return {
                ...state,
                daily_menus: action.payload
            };

        case constants.GET_MENUS:
            return {
                ...state,
                menus: action.payload,
                menus_fetched: true
            };

        case constants.SET_MENUS:
            return {
                ...state,
                menus: action.payload
            };

        case constants.GET_SUBMENUS:
            return {
                ...state,
               submenus: action.payload,
                submenus_fetched: true
            };

        case constants.GET_SUBMENU:
            return {
                ...state,
                products: action.payload.products
            };

        case constants.SET_SUBMENUS:
            return {
                ...state,
                submenus: action.payload,

            };

        case constants.GET_PRODUCTS:
            let products = action.payload;
            if(action.payload.length) {
                products = products.filter(product => product.active)
                products = products.sort((a,b) => {
                    return new Date(b.createdOn) - new Date(a.createdOn);
                });
            }
            return {
                ...state,
                products: products,
                products_fetched: true
            };

        case constants.SET_PRODUCTS:
            return {
                ...state,
                products: action.payload
            };

        case constants.GET_BANNERS:
            return {
                ...state,
                banners: action.payload,
                banners_fetched: true
            };

        case constants.SET_BANNERS:
            return {
                ...state,
                banners: action.payload
            };

        case constants.GET_EVENTS:
            return {
                ...state,
                events: action.payload,
                events_fetched: true
            };
    
        case constants.GET_FEEDBACKS:
            let feedback = [...state.feedbacks, ...action.payload.data];
            return {
                ...state,
                feedbacks: feedback,
                feedbacks_fetched: true,
                more_feedbacks: action.payload.total > feedback.length
            };

        case constants.GET_RATINGS:
            return {
                ...state,
                ratings: action.payload
            };

        case constants.GET_TABLES:
            const tables = action.payload.sort((a, b) => a.number - b.number);
            console.log("SORTED TABLES", tables);
            return {
                ...state,
                tables: tables,
                tables_fetched: true
            };

        case constants.CREATE_MENU:
            return state;

        case constants.CREATE_SUBMENU:
            return state;

        case constants.FIREBASE_REGISTER:
            localStorage.setItem("firebase_token", action.payload);
            return {
                ...state,
                firebase_token: action.payload
            };

        case constants.FIREBASE_UNREGISTER:
            localStorage.removeItem("firebase_token");
            return {
                ...state,
                firebase_token: null
            };

        case constants.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };


        default:
            return state
    }
}
