import axios from "axios";
import * as constants  from "../utils/constants";

const url = process.env.REACT_APP_BACKEND_URL;

export const setMenus = data => ({
    type: constants.SET_MENUS,
    payload: data
})

export const getBusinessDailyMenus = (businessId) => {
    return axios.get(url + `businesses/${businessId}/daily-menus`)
        .then(res => {
            console.log(res);
            return res;
        });

}

export const getDailyMenus = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.get(url + "daily-menus", {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            dispatch({ type: constants.GET_DAILY_MENUS, payload: res.data });
            return res;
        });
    }
}

export const getDailyMenu = daily_menu_id => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.get(url + `daily-menus/${daily_menu_id}`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const createDailyMenu = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.post(url + "daily-menus", data,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const updateDailyMenu = (menuId, data) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.put(url + `daily-menus/${menuId}`, data,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const deleteDailyMenu = menuId => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.delete(url + `daily-menus/${menuId}`,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const activateDailyMenu = (data) => {
    const token = localStorage.getItem("access_token");
    const menu = {
        ...data,
        active: true
    };
    return function(dispatch) {
        return axios.put(url + `daily-menus/${menu.id}`, menu,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const deactivateDailyMenu = (data) => {
    const token = localStorage.getItem("access_token");
    const menu = {
        ...data,
        active: false
    };
    return function(dispatch) {
        return axios.put(url + `daily-menus/${menu.id}`, menu,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const addDailyMenuProducts = (dailyMenuId, data) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.post(url + `daily-menus/${dailyMenuId}/products`, {
            productIds: data
        },{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const removeProductFromDailyMenu = (dailyMenuId, productId) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.delete(url + `daily-menus/${dailyMenuId}/products/${productId}`,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

export const updateDailyMenuProductsOrdering = (dailyMenuId, data) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.put(url + `daily-menus/${dailyMenuId}/products/ordering`, {
            products: data
        },{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            return res;
        });
    }
}

