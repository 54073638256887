import React, {useState} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import styles from "./SearchModal.module.scss";
import Input, {InputStyles} from "../../Input/Input";
import {getAllProductsFromMenus, getAllSubmenusFromMenus} from "../../../utils";
import ProductCard from "../../Cards/Product/ProductCard";
import {withRouter} from "react-router-dom";
import {closeModal} from "../../../actions/client";
import {useHistory, useParams} from "react-router";

const SearchModal = ({menus, closeModal}) => {

    const history = useHistory();
    const params = useParams();
    const {t, i18n} = useTranslation();
    const [searchText, setSearchText] = useState("");

    const submenus = () => {
        // const allProducts = getAllProductsFromMenus(menus);
        // console.log(allProducts);
        // const searchProducts = allProducts.filter(product => product.name.toLowerCase().includes(searchText.toLowerCase()));

        const allSubmenus = getAllSubmenusFromMenus(menus);
        const searchSubmenus = allSubmenus.filter(submenu => {
            const submenuMatches = submenu.title.toLowerCase().includes(searchText.toLowerCase());
            const submenuProductsMatch = submenu.products.some(product => product.name.toLowerCase().includes(searchText.toLowerCase()));
            return submenuMatches ||  submenuProductsMatch;
        });


        console.log(searchSubmenus);
        return searchSubmenus;
    }

    const openProduct = (product) => {
        history.push(`/menu/${params.businessId}/${params.table}/products/${product.id}`);
        closeModal();
    }

    return (
        <div className={styles.searchModal}>
            {/*<p className="big-title">{t("Search")}</p>*/}
            <Input
                type="text"
                value={searchText}
                id="searchTextInput"
                placeholder={t("Search")}
                style={InputStyles.Standard}
                onChange={(e) => setSearchText(e.target.value)}/>


            {submenus().map(submenu => {
                const hasProducts = !!submenu.products.length;
                const submenuMatches = submenu.title.toLowerCase().includes(searchText.toLowerCase());
                return (
                    hasProducts && <div className="mt-24">
                        <p className="font-20 mb-1">{i18n.language === "en" ? submenu.titleEn || submenu.title : submenu.title}</p>
                        {submenu.products.map((product, index) => {
                            const productMatches = product.name.toLowerCase().includes(searchText.toLowerCase());
                            return (
                                !!(submenuMatches || productMatches) &&
                                <ProductCard
                                    addToWishlist
                                    className="mb-12"
                                    product={product}
                                    onClick={() => openProduct(product)}/>
                            )
                        })}
                    </div>)
            })}
        </div>
    )
}

const mapStateToProps = state => ({
    menus: state.client.menus
});
const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch(closeModal())
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(SearchModal));

