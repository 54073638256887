import React, {useRef} from "react";
import styles from "./Switch.module.scss";
import classNames from "classnames";

export default function Switch(props) {

    const {
        id,
        name,
        type,
        value,
        className,
        placeholder,
        onChange,
    } = props;

    const classes = classNames(styles.switchInput, className);
    return (
        <div id={id}
             className={classes}
             onClick={(e) => onChange(!value)}>
            {value ? "ON" : "OFF"}
            <div className={classNames(styles.switch, {[styles.active]: value})}>
                <div className={styles.switchCircle}/>
            </div>
        </div>
    )
}
