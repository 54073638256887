import React, {Component} from "react";
import "./Feedback.scss";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Card from "../../../components/Cards/Card/Card";
import {getFeedbacks} from "../../../actions/feedback";
import moment from "moment";
import Button, {ButtonColors, ButtonTypes} from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import DragAndDrop, {DraggableTypes} from "../../../components/Draggable/DragAndDrop";
import {sort} from "../../../utils";

class Feedback extends Component {
    popUpQuestion = "A jeni të sigurt të vazhdoni?";
    
    constructor(props) {
        super(props);
        
        this.state = {
            page: 0
        }
    }
    
    componentDidMount() {
        const {page} = this.state;
        this.props.getFeedbacks(page);
    }

    loadMore = () => {
        const {page} = this.state;
        const next_page = page + 1;
        this.setState({next_page});
        this.props.getFeedbacks(next_page);
    }
    
    render() {
        const {feedbacks, feedbacks_fetched, more_feedbacks, t} = this.props;
        
        return (
            <div className="tables-page pb-5">

                <div className="page-header-row">
                    <h1>{t("Feedbacks")}</h1>
                </div>

                {feedbacks_fetched ?
                    feedbacks.length > 0 ?
                        <DragAndDrop
                            isDragDisabled
                            items={feedbacks}
                            type={DraggableTypes.FEEDBACK}
                            onClickEdit={(item, e) => this.onClickEdit(item, e)}
                            onClickActivate={(item, e) => this.onClickActivate(item, e)}
                            onClickDeactivate={(item, e) => this.onClickDeactivate(item, e)}
                            onDragEnd={(result) => this.onDragEnd(result)}/> : t("No feedback") + "..."
                    : <div className="text-center py-3">
                        <div className="spinner-border text-info"></div>
                    </div>
                }

                {more_feedbacks &&
                <div className="text-center mt-24">
                    <Button
                        color={ButtonColors.Green}
                        type={ButtonTypes.Filled}
                        onClick={this.loadMore}>
                        {t("Load more")}
                    </Button>
                </div>}
                
            </div>
        );
    }
    
}

const mapStateToProps = state => {
    return {
        feedbacks: state.app.feedbacks,
        feedbacks_fetched: state.app.feedbacks_fetched,
        more_feedbacks: state.app.more_feedbacks
    }
}

const mapDispatchToProps = dispatch => ({
    getFeedbacks: (data) => dispatch(getFeedbacks(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Feedback));
