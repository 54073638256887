import React, {Component} from "react";
import "./Register.scss";
import {connect} from "react-redux";
import {authenticateClient, getBusinessDetails, register} from "../../../actions/user";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {VERSION} from "../../../utils/constants";
import {setLoading} from "../../../actions";
import Input from "../../../components/Input/Input";
import Button, {ButtonColors, ButtonTypes} from "../../../components/Button/Button";
import {inputValidations, validationTests} from "../../../utils/validation/configurations";
import {validate} from "../../../utils/validation";
import Step from "../../../components/Step/Step";
import moment from "moment";
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import Drawer from "../../../components/Drawer/Drawer";
import ReactDrawer from "rc-drawer";
import classNames from "classnames";
import RegisterSuccess from "../../../components/RegisterSuccess/RegisterSuccess";

class Register extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            currentStep: 1,
            showValidation: false,
            email: "",
            password: "",
            firstName: "",
            lastName: "",
            businessUsername: "",
            businessType: "RESTAURANTS",
            businessName: "",
            businessPhoneNumber: "",
            businessAddressLine: "",
            businessCity: "",
            businessCountry: "",
            businessLatitude: 42.659580,
            businessLongitude: 21.160420,
            startDayTime: "07:00:00",
            endDayTime: "23:00:00",
            radiusInMeters: 20
        }
    }
    
    componentDidMount() {
        // document.querySelector("body").classList.add("login-page");
    }
    
    componentWillUnmount() {
        // document.querySelector("body").classList.remove("login-page");
    }

    incrementStep = () => {
        this.setState({ currentStep: this.state.currentStep + 1, showValidation: false });
    }
    
    handleSubmit = e => {
        e.preventDefault()

        if(this.formIsInvalid()) {
            this.setState({showValidation: true});
            return;
        }

        this.props.setLoading(true);
        const {username, password} = this.state;

    }

    handleBack = () => {
        if(this.state.currentStep > 1) {
            this.setState({ currentStep: this.state.currentStep - 1 });
        }
    }

    handleNext = () => {
        const {currentStep} = this.state;
        if(currentStep > 3) {
            return;
        }

        if(currentStep === 1) {
            if(this.firstStepIsInvalid()) {
                this.setState({showValidation: true});
                return;
            } else {
                this.incrementStep();
            }
            return;
        }

        if(currentStep === 2) {
            if(this.secondStepIsInvalid()) {
                this.setState({showValidation: true});
                return;
            } else {
                this.incrementStep();
            }
            return;
        }
        console.log("here1")
        if(currentStep === 3) {
            if(this.formIsInvalid()) {
                console.log("here2")
                this.setState({showValidation: true});
                return;
            }
        }
        console.log("success")
        this.props.setLoading(true);
        this.props.authenticateClient().then(async(res) => {
            localStorage.setItem("client_access_token", res.data.access_token);
            this.props.register(this.state)
                .then(res => {
                    console.log(res);
                    this.props.setLoading(false);
                    this.incrementStep();
                });

        })

    }

    firstStepIsInvalid = () => {
        const {
            firstName,
            lastName,
            email,
            password,
        } = this.state;
        return  validate(inputValidations.registerFirstNameInput, firstName) ||
            validate(inputValidations.registerLastNameInput, lastName) ||
            validate(inputValidations.registerEmailInput, email) ||
            validate(inputValidations.registerPasswordInput, password);
    }

    secondStepIsInvalid = () => {
        const {
            businessName,
            businessAddressLine,
            businessPhoneNumber,
            businessUsername,
            businessCountry,
            businessCity,
        } = this.state;
        return validate(inputValidations.registerBusinessNameInput, businessName) ||
            validate(inputValidations.registerBusinessAddressInput, businessAddressLine) ||
            validate(inputValidations.registerBusinessPhoneInput, businessPhoneNumber) ||
            validate(inputValidations.registerBusinessUsernameInput, businessUsername) ||
            validate(inputValidations.registerBusinessCountryInput, businessCountry) ||
            validate(inputValidations.registerBusinessCityInput, businessCity);
    }

    thirdStepIsInvalid = () => {
        const {
            startDayTime,
            endDayTime,
            businessLatitude,
            businessLongitude,
        } = this.state;
        return validate(inputValidations.registerBusinessStartTimeInput, startDayTime) ||
            validate(inputValidations.registerBusinessEndTimeInput, endDayTime) ||
            validate(inputValidations.registerBusinessLatitudeInput, businessLatitude) ||
            validate(inputValidations.registerBusinessLongitudeInput, businessLongitude);
    }

    formIsInvalid = () => {
        const {
            firstName,
            lastName,
            email,
            password,
            businessName,
            businessAddressLine,
            businessPhoneNumber,
            businessUsername,
            businessCountry,
            businessCity,
            startDayTime,
            endDayTime,
            businessLatitude,
            businessLongitude,
        } = this.state;
        return  validate(inputValidations.registerFirstNameInput, firstName) ||
                validate(inputValidations.registerLastNameInput, lastName) ||
                validate(inputValidations.registerEmailInput, email) ||
                validate(inputValidations.registerPasswordInput, password) ||
                validate(inputValidations.registerBusinessNameInput, businessName) ||
                validate(inputValidations.registerBusinessAddressInput, businessAddressLine) ||
                validate(inputValidations.registerBusinessPhoneInput, businessPhoneNumber) ||
                validate(inputValidations.registerBusinessUsernameInput, businessUsername) ||
                validate(inputValidations.registerBusinessCountryInput, businessCountry) ||
                validate(inputValidations.registerBusinessCityInput, businessCity) ||
                validate(inputValidations.registerBusinessStartTimeInput, startDayTime) ||
                validate(inputValidations.registerBusinessEndTimeInput, endDayTime) ||
                validate(inputValidations.registerBusinessLatitudeInput, businessLatitude) ||
                validate(inputValidations.registerBusinessLongitudeInput, businessLongitude);
    }

    renderFirstStep = () => {
        const {t} = this.props;
        const {showValidation} = this.state;
        return (
            <div className="row">
                <div className="col-12 col-md-6">
                    <Input
                        label={t("First name")}
                        wrapperClassName="mb-24"
                        key="registerFirstNameInput"
                        id="registerFirstNameInput"
                        name="registerFirstNameInput"
                        placeholder={t("First name")}
                        value={this.state.firstName}
                        validations={[inputValidations.registerFirstNameInput, showValidation]}
                        onChange={(e) => this.setState({ firstName: e.target.value })}/>
                </div>
                <div className="col-12 col-md-6">
                    <Input
                        label={t("Last name")}
                        wrapperClassName="mb-24"
                        key="registerLastNameInput"
                        id="registerLastNameInput"
                        name="registerLastNameInput"
                        placeholder={t("Last name")}
                        value={this.state.lastName}
                        validations={[inputValidations.registerLastNameInput, showValidation]}
                        onChange={(e) => this.setState({ lastName: e.target.value })}/>
                </div>
                <div className="col-12 col-md-6">
                    <Input
                        label={t("Email")}
                        wrapperClassName="mb-24"
                        key="registerEmailInput"
                        id="registerEmailInput"
                        name="registerEmailInput"
                        placeholder={t("Email")}
                        value={this.state.email}
                        validations={[inputValidations.registerEmailInput, showValidation]}
                        onChange={(e) => this.setState({ email: e.target.value })}/>
                </div>
                <div className="col-12 col-md-6">
                    <Input
                        type="password"
                        label={t("Password")}
                        wrapperClassName="mb-24"
                        key="registerPasswordInput"
                        id="registerPasswordInput"
                        name="registerPasswordInput"
                        placeholder={t("Password")}
                        value={this.state.password}
                        validations={[inputValidations.registerPasswordInput, showValidation]}
                        onChange={(e) => this.setState({ password: e.target.value })}/>
                </div>
            </div>
        )
    }

    renderSecondStep = () => {
        const {t} = this.props;
        const {showValidation} = this.state;
        return (
            <div className="row">
                <div className="col-12 col-md-6">
                    <Input
                        label={t("Business name")}
                        wrapperClassName="mb-24"
                        key="registerBusinessNameInput"
                        id="registerBusinessNameInput"
                        name="registerBusinessNameInput"
                        placeholder={t("Business name")}
                        value={this.state.businessName}
                        validations={[inputValidations.registerBusinessNameInput, showValidation]}
                        onChange={(e) => this.setState({ businessName: e.target.value })}/>
                </div>
                <div className="col-12 col-md-6">
                    <Input
                        label={t("Business address")}
                        wrapperClassName="mb-24"
                        key="registerBusinessAddressInput"
                        id="registerBusinessAddressInput"
                        name="registerBusinessAddressInput"
                        placeholder={t("Business address")}
                        value={this.state.businessAddressLine}
                        validations={[inputValidations.registerBusinessAddressInput, showValidation]}
                        onChange={(e) => this.setState({ businessAddressLine: e.target.value })}/>
                </div>
                <div className="col-12 col-md-6">
                    <Input
                        label={t("Business phone")}
                        wrapperClassName="mb-24"
                        key="registerBusinessPhoneInput"
                        id="registerBusinessPhoneInput"
                        name="registerBusinessPhoneInput"
                        placeholder={t("Business phone")}
                        value={this.state.businessPhoneNumber}
                        validations={[inputValidations.registerBusinessPhoneInput, showValidation]}
                        onChange={(e) => this.setState({ businessPhoneNumber: e.target.value })}/>
                </div>
                <div className="col-12 col-md-6">
                    <Input
                        label={t("Business username")}
                        wrapperClassName="mb-24"
                        key="registerBusinessUsernameInput"
                        id="registerBusinessUsernameInput"
                        name="registerBusinessUsernameInput"
                        placeholder={t("Business username")}
                        value={this.state.businessUsername}
                        validations={[inputValidations.registerBusinessUsernameInput, showValidation]}
                        onChange={(e) => this.setState({ businessUsername: e.target.value })}/>
                </div>
                <div className="col-12 col-md-6">
                    <Input
                        label={t("Business country")}
                        wrapperClassName="mb-24"
                        key="registerBusinessCountryInput"
                        id="registerBusinessCountryInput"
                        name="registerBusinessCountryInput"
                        placeholder={t("Business country")}
                        value={this.state.businessCountry}
                        validations={[inputValidations.registerBusinessCountryInput, showValidation]}
                        onChange={(e) => this.setState({ businessCountry: e.target.value })}/>
                </div>
                <div className="col-12 col-md-6">
                    <Input
                        label={t("Business city")}
                        wrapperClassName="mb-24"
                        key="registerBusinessCityInput"
                        id="registerBusinessCityInput"
                        name="registerBusinessCityInput"
                        placeholder={t("Business city")}
                        value={this.state.businessCity}
                        validations={[inputValidations.registerBusinessCityInput, showValidation]}
                        onChange={(e) => this.setState({ businessCity: e.target.value })}/>
                </div>
                {/*<div className="col-12">*/}
                {/*    <Input*/}
                {/*        type="textarea"*/}
                {/*        label={`${t("Description")}`}*/}
                {/*        wrapperClassName="mb-24"*/}
                {/*        key="registerDescriptionInput"*/}
                {/*        id="registerDescriptionInput"*/}
                {/*        name="registerDescriptionInput"*/}
                {/*        placeholder={t("Description")}*/}
                {/*        value={this.state.description}*/}
                {/*        validations={[inputValidations.registerDescriptionInput, showValidation]}*/}
                {/*        onChange={(e) => this.setState({ description: e.target.value })}/>*/}
                {/*</div>*/}
            </div>
        )
    }

    renderThirdStep = () => {
        const {t} = this.props;
        const {showValidation} = this.state;
        return (
            <div className="row">
                <div className="col-12 col-md-6">
                    <Input
                        label={t("Business start time")}
                        type="time"
                        wrapperClassName="mb-24"
                        key="registerBusinessStartTimeInput"
                        id="registerBusinessStartTimeInput"
                        name="registerBusinessStartTimeInput"
                        placeholder={t("Business start time")}
                        value={this.state.startDayTime}
                        validations={[inputValidations.registerBusinessStartTimeInput, showValidation]}
                        onChange={(e) => this.setState({ startDayTime: e })}/>
                </div>
                <div className="col-12 col-md-6">
                    <Input
                        label={t("Business end time")}
                        type="time"
                        wrapperClassName="mb-24"
                        key="registerBusinessEndTimeInput"
                        id="registerBusinessEndTimeInput"
                        name="registerBusinessEndTimeInput"
                        placeholder={t("Business end time")}
                        value={this.state.endDayTime}
                        validations={[inputValidations.registerBusinessEndTimeInput, showValidation]}
                        onChange={(e) => this.setState({ endDayTime: e })}/>
                </div>
            </div>
        )
    }

    renderSteps = () => {
        switch (this.state.currentStep) {
            case 1: {
                return this.renderFirstStep()
            }
            case 2: {
                return this.renderSecondStep()
            }
            case 3: {
                return this.renderThirdStep()
            }
            case 4: {
                return <RegisterSuccess />
            }
        }
    }

    render() {
        
        const {currentStep} = this.state;
        const {t} = this.props;
        
        return (
            <div className={classNames("register-box")}>
                {/*<p className="text-center">Welcome to</p>*/}
                {currentStep !== 4 && <h1 className="text-center mb-48">{t("Sign Up")}</h1>}
                <div className="login-box-body">
                    
                    <form onSubmit={e => e.preventDefault()} method="post">

                        {currentStep !== 4 && <div className="d-flex justify-content-between align-items-center w-50 mx-auto mb-32">
                            <Step text={1} current={currentStep === 1} finished={currentStep > 1}/>
                            <div className="steps-line" />
                            <Step text={2} current={currentStep === 2} finished={currentStep > 2}/>
                            <div className="steps-line" />
                            <Step text={3} current={currentStep === 3} finished={currentStep > 3}/>
                        </div>}


                        {this.renderSteps()}

                        <div className="d-flex justify-content-between align-items-center mt-24">
                            <Link className={currentStep === 4 ? "mx-auto" : "" } to="/login">{t("Log in")}</Link>

                            {currentStep !== 4 && <div>
                                {currentStep > 1 && <Button
                                    type={ButtonTypes.Filled}
                                    color={ButtonColors.Green}
                                    onClick={(e) => this.handleBack(e)}>
                                    {t("Back")}
                                </Button>}
                                <Button
                                    className="ml-3"
                                    type={ButtonTypes.Filled}
                                    color={ButtonColors.Green}
                                    onClick={(e) => this.handleNext(e)}>
                                    {t("Next")}
                                </Button>
                            </div>}
                        </div>
                    </form>

                </div>

                {/*<ReactDrawer*/}
                {/*    className="maps-drawer"*/}
                {/*    open={this.state.currentStep === 3}*/}
                {/*    handler={false}*/}
                {/*    placement="right"*/}
                {/*    level={null}*/}
                {/*    getContainer={document.getElementById("root")}>*/}

                    {/*<Map*/}
                    {/*    zoom={14}*/}
                    {/*    google={this.props.google}*/}
                    {/*    initialCenter={{ lat: 42.659580, lng: 21.160420 }}*/}
                    {/*    onCenterChanged={(props, map) => {*/}
                    {/*        this.setState({ businessLatitude: map.center.lat(), businessLongitude: map.center.lng() })*/}
                    {/*    }}>*/}
                    {/*    <Marker*/}
                    {/*        name="marker"*/}
                    {/*        position={{*/}
                    {/*            lat: this.state.businessLatitude,*/}
                    {/*            lng: this.state.businessLongitude*/}
                    {/*        }}  />*/}
                    {/*</Map>*/}

                {/*</ReactDrawer>*/}
            </div>
        );
    }
    
}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    register: data => dispatch(register(data)),
    getUserDetails: data => dispatch(getBusinessDetails(data)),
    authenticateClient: (data) => dispatch(authenticateClient(data)),
});

const translation = withTranslation();
const connector = connect(null, mapDispatchToProps);
const googleApiWrapper = GoogleApiWrapper({apiKey: "AIzaSyDUjYV920crnjF-BlA1cCapw9yyT4DUXjk"});
export default withRouter(connector(translation(googleApiWrapper(Register))));
