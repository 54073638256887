import React from "react";
import {withTranslation} from "react-i18next";
import {CLIENT_MODAL_PAGES} from "../../../utils/constants";
import {getDeliveryOrders, getOrders, updateWishlist} from "../../../actions/client";
import {connect} from "react-redux";
import {
    clearWishlist,
    decreaseQuantityOfWishlistProduct,
    getAllProductsFromMenus,
    getWishlistProducts,
    increaseQuantityOfWishlistProduct,
    prepareOrderProducts,
    removeProductFromWishlist
} from "../../../utils";
import {createDeliveryOrder, createOrder, sendOrder} from "../../../actions/orders";
import classNames from "classnames";
import styles from "./WishlistModal.module.scss";
import WishlistModalCart from "./WishlistModalCart/WishlistModalCart";
import WishlistModalOrders from "./WishlistModalOrders/WishlistModalOrders";
import WishlistModalOrderDetails from "./WishlistModalOrderDetails/WishlistModalOrderDetails";
import WishlistModalWishlist from "./WishlistModalWishlist/WishlistModalWishlist";
import WishlistModalForm from "./WishlistModalForm/WishlistModalForm";

class WishlistModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            comment: "",
            order: null,
            loading: false,
            page: CLIENT_MODAL_PAGES.CART,
            createOrderPayload: null
        }
    }

    componentDidMount() {
        const {business_id} = this.props;
        this.props.getOrders(business_id);
        this.props.getDeliveryOrders(business_id);
    }

    handleIncreaseClick = (e, product) => {
        e.stopPropagation();
        increaseQuantityOfWishlistProduct(product);
        this.props.updateWishlist();
    }

    handleDecreaseClick = (e, product) => {
        e.stopPropagation();
        decreaseQuantityOfWishlistProduct(product);
        this.props.updateWishlist();
    }

    handleRemoveFromWishlistButtonClick = (product) => {
        removeProductFromWishlist(product);
        this.props.updateWishlist();
    }

    openOrders = () => {
        this.setState({page: CLIENT_MODAL_PAGES.ORDERS})
    }

    openOrderDetails = (order) => {
        this.setState({order: order, page: CLIENT_MODAL_PAGES.ORDER_DETAILS})
    }

    openForm = (order) => {
        console.log(order);
        this.setState({page: CLIENT_MODAL_PAGES.FORM, createOrderPayload: order})
    }

    setLoading = (loading) => {
        this.setState({ loading: loading });
    }

    createOrder = (createOrderPayload, sendOrderPayload) => {
        this.setState({ createOrderPayload });
        console.log(createOrderPayload);
        if(this.props.is_delivery_enabled) {
            return;
        }
        this.props.createOrder(createOrderPayload)
            .then(res => {
                this.props.sendOrder(res.data.id, sendOrderPayload)
                    .then(res => {
                        this.props.getOrders(this.props.business_id).then(res => {
                            this.setState({ loading: false });
                            clearWishlist();
                            updateWishlist();
                            this.openOrders();
                        });
                    }).catch(err => {
                    this.setState({ loading: false });
                    alert("There was a problem, please try again later!")
                });
            }).catch(err => {
                this.setState({ loading: false });
                alert("There was a problem, please try again later!")
        })
    }

    createDeliveryOrder = (deliveryOrderPayload) => {
        this.setState({ loading: true });
        const payload = { ...deliveryOrderPayload, ...this.state.createOrderPayload };
        this.props.createDeliveryOrder(payload)
            .then(res => {
                this.props.getDeliveryOrders(this.props.business_id).then(res => {
                    this.setState({ loading: false });
                    clearWishlist();
                    updateWishlist();
                    this.openOrders();
                });
            }).catch(err => {
                this.setState({ loading: false });
                alert("There was a problem, please try again later!")
        })
    }


    // handleOrderClick = order => {
    //     this.setState({order: order, page: CLIENT_MODAL_PAGES.ORDER_DETAILS_PAGE});
    // }

    render() {
        const {t, is_ordering_enabled, is_delivery_enabled} = this.props;
        const {page, order, loading} = this.state;
        const isCartTabActive = page === CLIENT_MODAL_PAGES.CART;
        const isFormTabActive = page === CLIENT_MODAL_PAGES.FORM;
        const isOrdersTabActive = page === CLIENT_MODAL_PAGES.ORDERS;
        const isOrderDetailsPageActive = page === CLIENT_MODAL_PAGES.ORDER_DETAILS;

        return (
            <div className={styles.wishlistModal}>

                {(!isOrderDetailsPageActive && (is_ordering_enabled || is_delivery_enabled)) &&
                <div className={styles.wishlistModalTabs}>
                    <div className={classNames(styles.wishlistModalTab, {[styles.active]: isCartTabActive})}
                         onClick={() => this.setState({page: CLIENT_MODAL_PAGES.CART})}>
                        {t("Cart")}
                    </div>
                    <div className={classNames(styles.wishlistModalTab, {[styles.active]: isOrdersTabActive})}
                         onClick={() => this.setState({page: CLIENT_MODAL_PAGES.ORDERS})}>
                        {t("Orders")}
                    </div>
                </div>}

                {(isCartTabActive && (is_ordering_enabled || is_delivery_enabled)) &&
                    <WishlistModalCart
                        loading={loading}
                        business_id={this.props.business_id}
                        table_number={this.props.table_number}
                        onGoToForm={this.openForm}
                        setLoading={(loading) => this.setLoading(loading)}
                        onSendOrder={(createPayload, sendPayload) => this.createOrder(createPayload, sendPayload)}/>}

                {(isCartTabActive && !is_ordering_enabled && !is_delivery_enabled) &&
                    <WishlistModalWishlist
                        business_id={this.props.business_id}
                        table_number={this.props.table_number}
                    />}

                {isOrdersTabActive &&
                    <WishlistModalOrders
                        business_id={this.props.business_id}
                        onOrderClick={this.openOrderDetails}/> }

                {isFormTabActive &&
                <WishlistModalForm
                    loading={loading}
                    business_id={this.props.business_id}
                    onCreateOrder={this.createDeliveryOrder}/> }

                {isOrderDetailsPageActive &&
                    <WishlistModalOrderDetails
                        order={order}
                        onBackButtonClick={this.openOrders}/> }

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        menus: state.client.menus,
        wishlist: state.client.wishlist,
        orders: state.client.orders,
        daily_menus: state.client.daily_menus,
        is_ordering_enabled: state.client.is_ordering_enabled,
        is_delivery_enabled: state.client.is_delivery_enabled
    }
}

const mapDispatchToProps = dispatch => ({
    updateWishlist: () => dispatch(updateWishlist()),
    createOrder: (data) => dispatch(createOrder(data)),
    createDeliveryOrder: (data) => dispatch(createDeliveryOrder(data)),
    sendOrder: (orderId, data) => dispatch(sendOrder(orderId, data)),
    getOrders: (businessId) => dispatch(getOrders(businessId)),
    getDeliveryOrders: (businessId) => dispatch(getDeliveryOrders(businessId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WishlistModal));
