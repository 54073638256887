import React, {Component} from "react";
import styles from "./ClientModal.module.scss";
import ReactModal from 'react-modal';
import {withTranslation} from "react-i18next";

ReactModal.setAppElement("#root");

class ClientModal extends Component {
    componentDidMount() {
        //
    }

    render() {
        const {children, isModalOpen, handleClose, t} = this.props;
        return (
            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={handleClose}
                shouldCloseOnOverlayClick={false}
                className={styles.clientModal}
                overlayClassName={styles.clientModalOverlay}>

                {children}

            </ReactModal>
        );
    }
}

const translation = withTranslation();
export default translation(ClientModal);

