import React, {Component} from "react";
import "./Tables.scss";
import {connect} from "react-redux";
import Modal from "../../../components/Modal/Modal";
import {
    createTable,
    deleteTable,
    deleteTables,
    generateQrCode,
    generateTables,
    getTables,
    updateTable
} from "../../../actions/tables";
import {register, unregister} from "../../../actions/notifications";
import firebase from "../../../firebaseInit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faQrcode} from "@fortawesome/free-solid-svg-icons";
import {withTranslation} from "react-i18next";
import {askForConfirmation, copyToClipboard, editingTitle} from "../../../utils";
import {TABLE_MODAL_PAGES} from "../../../utils/constants";
import {setLoading} from "../../../actions";
import TablesList from "../../../components/Lists/Tables/Tables";
import Button, {ButtonTypes} from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import {inputValidations, validationConfigurations} from "../../../utils/validation/configurations";
import {validationTests} from "../../../utils/validation/tests";
import {errorValidationMessage, validationMessages} from "../../../utils/validation/messages";
import {validate} from "../../../utils/validation";

// import {onMessageListener, requestFirebaseNotificationPermission} from "../../../firebaseInit";

class Tables extends Component {
    popUpQuestion = "A jeni të sigurt të vazhdoni?";

    constructor(props) {
        super(props);

        this.state = {
            modalContent: TABLE_MODAL_PAGES.GENERATE,
            isModalOpen: false,
            numberOfTables: "",
            activeTables: [],
            showValidation: false,
            table: {
                name: ""
            }
        }
    }

    componentDidMount() {
        const {numberOfTables} = this.state;
        this.props.getTables();

        if (firebase.messaging.isSupported()) {
            const messaging = firebase.messaging();
            messaging.onMessage((message) => {
                console.log(message);
                this.setActiveTables(message.data.tableNumber)
            })
        }

    }

    setActiveTables = (tableNumber) => {
        let activeTables = [...this.state.activeTables];
        if (!activeTables.includes(tableNumber)) {
            activeTables.push(tableNumber);
            this.setState({activeTables},
                () => {
                    setTimeout(() => {
                        let activeTables = [...this.state.activeTables];
                        let indexOfActiveTable = activeTables.indexOf(tableNumber);
                        activeTables.splice(indexOfActiveTable, 1);
                        this.setState({activeTables});
                    }, 5000)
                });
        }
    }

    openModal = (content) => {
        this.setState({isModalOpen: true, modalContent: content});
    }

    closeModal = () => {
        this.setState({isModalOpen: false, table: {name: ""}, showValidation: false});
    }

    onClickCreate = (item) => {
        this.setState({table: item});
        this.openModal(TABLE_MODAL_PAGES.CREATE);
    }

    onClickEdit = (item) => {
        this.setState({table: item});
        this.openModal(TABLE_MODAL_PAGES.EDIT);
    }

    onClickDelete = async(item) => {
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.deleteTable(item.id).then(res => {
            this.props.getTables();
            this.props.setLoading(false);
        });
    }

    handleGenerateTables = async() => {
        const {numberOfTables} = this.state;
        const numberOfTablesInt = parseInt(numberOfTables);
        const {t} = this.props;

        if(validate(inputValidations.numberOfTablesInput, numberOfTables)) {
            this.setState({showValidation: true});
            return;
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.generateTables(numberOfTablesInt).then(res => {
            this.props.getTables();
            this.closeModal();
            this.props.setLoading(false);
        })
    }

    handleGenerateQrCode = (tableId) => {
        this.props.setLoading(true);
        this.props.generateQrCode(tableId).then(res => {
            this.props.setLoading(false);
        });
    }

    handleDeleteTables = async() => {
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.deleteTables().then(res => {
            this.props.getTables();
            this.props.setLoading(false);
        })
    }

    handleCreateTable = async() => {
        const {table} = this.state;
        const {t} = this.props;

        if(validate(inputValidations.tableNameInput, table.name)) {
            this.setState({showValidation: true});
            return;
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.createTable(table).then(res => {
            this.props.getTables();
            this.closeModal();
            this.props.setLoading(false);
        })
    }

    handleUpdateTable = async() => {
        const {table} = this.state;
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.updateTable(table.id, table).then(res => {
            this.props.getTables();
            this.closeModal();
            this.props.setLoading(false);
        })
    }

    tableIsActive = (tableNumber) => {
        return this.state.activeTables.includes((tableNumber).toString());
    }

    copyToClipboard = (text) => {
        copyToClipboard(text);
    }


    render() {
        const {isModalOpen, numberOfTables, modalContent, table, showValidation} = this.state;
        const {tables, tables_fetched, t} = this.props;
        const creating = modalContent === TABLE_MODAL_PAGES.CREATE;
        const editing = modalContent === TABLE_MODAL_PAGES.EDIT;
        const generating = modalContent === TABLE_MODAL_PAGES.GENERATE;

        return (
            <div className="tables-page">

                <div className="page-header-row">
                    <span className="page-title">{t("QR Codes")}</span>

                    <div className="page-buttons">
                        <Button
                            className="mr-3"
                            type={ButtonTypes.Add}
                            content={t("Generate One QR Code")}
                            onClick={() => this.openModal(TABLE_MODAL_PAGES.CREATE)}/>

                        <Button
                            type={ButtonTypes.Add}
                            content={t("Generate QR Codes")}
                            onClick={() => this.openModal(TABLE_MODAL_PAGES.GENERATE)}/>
                    </div>
                </div>

                {tables_fetched ?
                    <TablesList
                        tables={tables}
                        onClickEdit={(table) => this.onClickEdit(table)}
                        // onClickDelete={(table) => this.onClickDelete(table)}
                        onClickDownload={(table) => this.handleGenerateQrCode(table.id)}/>
                    : <div className="text-center py-3">
                        <div className="spinner-border text-info"></div>
                    </div>}

                <Modal
                    isModalOpen={isModalOpen}
                    handleClose={this.closeModal}
                    handleSubmit={() => generating ? this.handleGenerateTables() : editing ? this.handleUpdateTable() : this.handleCreateTable()}
                    title={generating ? t("Generate QR Codes") : editing ? t("Edit Table") + editingTitle(table.name) : t("Create Table")}>


                    {generating
                        ? <Input
                            label={t("Number of tables")}
                            id="numberOfTablesInput"
                            name="numberOfTablesInput"
                            type="number"
                            value={numberOfTables}
                            validations={[inputValidations.numberOfTablesInput, showValidation]}
                            onChange={(e) => this.setState({numberOfTables: e.target.value})}/>
                        : <Input
                            label={t("Table name")}
                            id="tableNameInput"
                            name="tableNameInput"
                            value={table.name}
                            validations={[inputValidations.tableNameInput, showValidation]}
                            onChange={(e) => this.setState({table: {...table, name: e.target.value}})}/>}
                </Modal>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        tables: state.app.tables,
        tables_fetched: state.app.tables_fetched,
        firebase_token: state.app.firebase_token
    }
}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    getTables: data => dispatch(getTables()),
    deleteTables: data => dispatch(deleteTables()),
    generateTables: numberOfTables => dispatch(generateTables(numberOfTables)),
    generateQrCode: tableId => dispatch(generateQrCode(tableId)),
    register: data => dispatch(register(data)),
    unregister: data => dispatch(unregister(data)),
    createTable: data => dispatch(createTable(data)),
    updateTable: (id, data) => dispatch(updateTable(id, data)),
    deleteTable: (id) => dispatch(deleteTable(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Tables));
