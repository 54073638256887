import React, {useState} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import styles from "./RatingModal.module.scss";
import Input, {InputStyles} from "../../Input/Input";
import {withRouter} from "react-router-dom";
import {closeModal, getProductRatings, rateProduct} from "../../../actions/client";
import {ReactComponent as Star} from "../../../assets/icons/star.svg";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../Button/Button";

const RatingModal = ({product, history, closeModal, rateProduct, getProductRatings, business_id}) => {

    const {t, i18n} = useTranslation();
    const [name, setName] = useState("");
    const [rating, setRating] = useState(0);
    const [loading, setLoading] = useState(false);

    const openProduct = (product) => {
        history.push(`/menu/product/${product.id}`);
        closeModal();
    }

    const setRatingValue = (value) => {
        setRating(value);
    }

    const sendRating = () => {
        if(loading) return;
        setLoading(true);

        console.log("rate", product, rating);
        const payload = {
            productId: product.id,
            rate: rating
        }

        rateProduct(payload)
            .then(res => {
                setLoading(false);
                if (res.response?.data?.code == "50000") {
                    alert(t("You have already rated this product!"))
                } else {
                    closeModal()
                    getProductRatings(business_id);
                }
            });
    }

    return (
        <div className={styles.ratingModal}>
            <p className="big-title">{t("Rate")}</p>

            {/*<p className="mt-24">{t("Full name")}</p>*/}
            {/*<Input*/}
            {/*    type="text"*/}
            {/*    value={name}*/}
            {/*    id="ratingTextInput"*/}
            {/*    wrapperClassName="mt-16"*/}
            {/*    style={InputStyles.Standard}*/}
            {/*    onChange={(e) => setName(e.target.value)}/>*/}

            <p className="mb-24 mt-24">{t("Rating")}</p>
            <div className={styles.stars}>
                {[1, 2, 3, 4, 5].map(star => (
                    <div className={styles.star}
                         onClick={() => setRatingValue(star)}>
                        <Star className={rating >= star ? styles.filledStar : styles.emptyStar}/>
                    </div>
                ))}
            </div>

            <Button
                key={`rate-product-button-${product.id}`}
                className="mt-48"
                type={ButtonTypes.Filled}
                color={ButtonColors.Green}
                size={ButtonSizes.Full}
                onClick={() => sendRating()}>
                {loading ? <div className="spinner-border text-white" /> : t("Send")}
            </Button>

        </div>
    )
}

const mapStateToProps = state => ({
    product: state.client.product
});
const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch(closeModal()),
    rateProduct: (data) => dispatch(rateProduct(data)),
    getProductRatings: (id) => dispatch(getProductRatings(id)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(RatingModal));

