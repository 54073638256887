import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import "antd/dist/antd.css";
import './index.css';
import './styles/index.scss';
import App from './App';
import {applyMiddleware, combineReducers, createStore} from "redux";
import {app} from "./reducers/admin";
import {client} from "./reducers/client";
import thunk from "redux-thunk";
import {Provider} from "react-redux";
import {registerServiceWorker} from "./serviceWorker";
import {setupInterceptors} from "./networkService";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as Router,} from "react-router-dom";
import "./i18n";
import 'react-gallery-carousel/dist/index.css';

const reducers = combineReducers({ app, client });
const store = createStore(reducers, applyMiddleware(thunk));

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <Router>
              <App />
          </Router>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
registerServiceWorker();
setupInterceptors(store);
toast.configure();
smoothscroll.polyfill();

