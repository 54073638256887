import React, {useEffect} from 'react';
import {confirmable} from 'react-confirm';
import classNames from "classnames";
import Modal from "react-modal";
import styles from "./ConfirmationDialog.module.scss";
import Button, {ButtonColors, ButtonTypes} from "../Button/Button";
import {useTranslation} from "react-i18next";


const ConfirmationDialog = ({show, proceed, confirmation, options}) => {

    const {t, i18n} = useTranslation();

    useEffect(() => {
        document.addEventListener("keyup", handleEnter);

        return () => {
            document.removeEventListener("keyup", handleEnter);
        }
    }, []);

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            proceed(true);
        }
    }

    return (
        <Modal
            isOpen={show}
            onRequestClose={() => proceed(false)}
            shouldCloseOnOverlayClick={false}
            className={styles.confirmationModal}
            overlayClassName={styles.confirmationModalOverlay}>
            <h3 className="text-center">{confirmation}</h3>
            <div className={styles.confirmationModalButtons}>
                <Button
                    className="mr-2"
                    type={ButtonTypes.Filled}
                    color={ButtonColors.Red}
                    onClick={() => proceed(false)}>
                    {t("No")}
                </Button>
                <Button
                    className="ml-2"
                    type={ButtonTypes.Filled}
                    color={ButtonColors.Green}
                    onClick={() => proceed(true)}>
                    {t("Yes")}
                </Button>
            </div>
        </Modal>
    )
}

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(ConfirmationDialog);
