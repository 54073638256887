import React from "react";
import styles from "./Checkbox.module.scss";
import classNames from "classnames";
import {ReactComponent as CheckmarkIcon} from "../../assets/icons/tick.svg";

const Checkbox = ({checked, className}) => {
    return (
        <div className={classNames(styles.checkbox, className)}>
            {checked && <CheckmarkIcon/>}
        </div>
    )
}

export default Checkbox;
