import React from "react";
import {RATING_LEVELS, RATING_VALUES} from "../../../utils/constants";
import Bar from "../Bar/Bar";
import "./Bars.scss";
import {faStar} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Star from "../../Icons/Star/Star";

const Bars = ({ ratings }) => (
    <div className="bars">
        {Object.keys(RATING_LEVELS).map(level => {
            const rating = ratings[level];
            const percentage = rating.percentage;
            const count = rating.count;
            return (
                <div className="bar-container">
                    <div className="bar-level">
                        { RATING_VALUES[level] } stars
                    </div>
                    <div className="bar-progress">
                        <Bar percentage={rating.percentage} count={rating.count} />
                    </div>
                    <div className="bar-info">
                        { percentage }%
                    </div>
                </div>
            )
        })}
    </div>
)

export default Bars;
