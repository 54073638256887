import React from "react";
import styles from "./OrderCard.module.scss";
import {calculateTotal} from "../../../utils";
import moment from "moment";
import Button, {ButtonColors, ButtonSizes} from "../../Button/Button";
import Card from "../../Card/Card";
import {ReactComponent as ClockIcon} from "../../../assets/icons/time.svg";
import {ReactComponent as ArrowRightIcon} from "../../../assets/icons/right.svg";
import {useTranslation} from "react-i18next";

const OrderCard = ({order, onDetailsButtonClick, onApproveButtonClick, onDeclineButtonClick, children}) => {

    const {t, i18n} = useTranslation();

    return (
        <Card className="mb-12">
            <div className={styles.top}>
                <div>
                    <p className={styles.subtitle}>
                        #{order.orderNumber.substr(order.orderNumber.length - 5)}
                    </p>
                    <p className={styles.title}>
                        <ClockIcon className="mr-2"/> {moment.utc(order.createdDateTime).local().format("hh:mm")}
                    </p>
                </div>
                <div>
                    <p className={styles.subtitle}>
                        {t("Total")}
                    </p>
                    <p className={styles.title}>
                        {calculateTotal(order)}€
                    </p>
                </div>
            </div>
            <div className={styles.bottom}>
                <Button
                    className={styles.detailsButton}
                    size={ButtonSizes.Small}
                    color={ButtonColors.White}
                    onClick={onDetailsButtonClick}>
                    <ArrowRightIcon/> {t("See details")}
                </Button>
                <Button
                    size={ButtonSizes.Small}
                    color={ButtonColors.Green}
                    onClick={onApproveButtonClick}>
                    {t("Approve")}
                </Button>
                <Button
                    size={ButtonSizes.Small}
                    color={ButtonColors.Red}
                    onClick={onDeclineButtonClick}>
                    {t("Decline")}
                </Button>
            </div>
        </Card>
    )

}

export default OrderCard;
