import axios from "axios";
import * as constants from "../utils/constants";
import {COOKIE_NAME} from "../utils/constants";
import {getCookie} from "../utils";

const url = process.env.REACT_APP_BACKEND_URL;

export const setDeliveryEnabled = (payload) => {
    return function(dispatch) {
        dispatch({ type: constants.SET_DELIVERY_ENABLED, payload: payload });
    }
}

export const updateWishlist = () => {
    return function(dispatch) {
        dispatch({ type: constants.UPDATE_WISHLIST, payload: null });
    }
}

export const setDailyMenu = daily_menu => {
    return function(dispatch) {
        dispatch({ type: constants.SET_DAILY_MENU, payload: daily_menu });
    }
}

export const setEvent = event => {
    return function(dispatch) {
        dispatch({ type: constants.SET_EVENT, payload: event });
    }
}

export const setImage = image => {
    return function(dispatch) {
        dispatch({ type: constants.SET_IMAGE, payload: image });
    }
}

export const setProduct = product => {
    return function(dispatch) {
        dispatch({ type: constants.SET_PRODUCT, payload: product });
    }
}

export const openModal = (type) => {
    return function(dispatch) {
        dispatch({ type: constants.OPEN_MODAL, payload: type });
    }
}

export const closeModal = () => {
    return function(dispatch) {
        dispatch({ type: constants.CLOSE_MODAL});
    }
}

export const openPopup = (type) => {
    return function(dispatch) {
        dispatch({ type: constants.OPEN_POPUP, payload: type });
    }
}

export const closePopup = () => {
    return function(dispatch) {
        dispatch({ type: constants.CLOSE_POPUP });
    }
}

export const setLayoutType = (type) => {
    return function(dispatch) {
        dispatch({ type: constants.SET_LAYOUT_TYPE, payload: type });
    }
}

export const clientExists = () => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.get(url + `open/cookies/exists`, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                [COOKIE_NAME]: getCookie(COOKIE_NAME)
            }
        }).then(res => {
            return res;
        });
    }
}

export const writeCookies = () => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.get(url + `open/cookies/write`, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const callWaiter = (businessId, data) => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.post(url + `open/businesses/${businessId}/waiters`, data, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                [COOKIE_NAME]: getCookie(COOKIE_NAME)
            }
        }).then(res => {
            return res;
        });
    }
}

export const rateProduct = (data) => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.post(url + `open/clients/product-rates`, data, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                [COOKIE_NAME]: getCookie(COOKIE_NAME)
            }
        }).then(res => {
            return res;
        }).catch(err => {
            return err;
        })
    }
}

export const addFeedback = (data) => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.post(url + `open/clients/feedback`, data, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                [COOKIE_NAME]: getCookie(COOKIE_NAME)
            }
        }).then(res => {
            return res;
        });
    }
}

export const getProductRatings = (businessId) => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.get(url + `open/clients/product-rates?businessId=${businessId}`, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                [COOKIE_NAME]: getCookie(COOKIE_NAME)
            }
        }).then(res => {
            dispatch({ type: constants.GET_RATINGS, payload: res.data });
            return res;
        });
    }
}

export const getBusinesses = (name, token) => {
    return function(dispatch) {
        return axios.get(url + `businesses?name=${name}`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            dispatch({ type: constants.GET_BUSINESSES, payload: res.data });
            return res;
        });
    }
}

export const getBusinessMenus = (businessId, tableNumber) => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.get(url + `businesses/${businessId}/menus`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            dispatch({ type: constants.GET_MENUS, payload: res.data });
            return res;
        });
    }
}

export const getBusinessDailyMenus = (businessId) => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.get(url + `businesses/${businessId}/daily-menus`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            dispatch({ type: constants.GET_DAILY_MENUS, payload: res.data });
            return res;
        });
    }
}

export const getBusinessBanners = (businessId) => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.get(url + `businesses/${businessId}/banners`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            dispatch({ type: constants.GET_BANNERS, payload: res.data });
            return res;
        });
    }
}

export const getBusinessEvents = (eventId) => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.get(url + `businesses/${eventId}/events`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            dispatch({ type: constants.GET_EVENTS, payload: res.data });
            return res;
        });
    }
}

export const getBusinessDetails = businessId => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.get(url + `out/businesses/${businessId}`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            dispatch({ type: constants.GET_BUSINESS, payload: res.data });
            return res;
        });
    }
}

export const getBusinessDetailsById = (id) => {
    const token = localStorage.getItem("client_access_token");
    return async function(dispatch) {
        return axios.get(url + `out/businesses/${id}`,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        }).catch(err => {
            return err;
        })
    }
}

export const getBusinessDetailsByUsername = (username) => {
    const token = localStorage.getItem("client_access_token");
    return async function(dispatch) {
        return axios.get(url + `out/businesses/username/${username}`,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        }).catch(err => {
            return err;
        })
    }
}

export const getOrders = businessId => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.get(url + `open/clients/orders?page=0&size=100&statuses=SENT,APPROVED,DECLINED&businessId=${businessId}`, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                [COOKIE_NAME]: getCookie(COOKIE_NAME)
            }
        }).then(res => {
            res.data.data.sort((a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime));
            dispatch({ type: constants.GET_ORDERS, payload: res.data.data });
            return res;
        });
    }
}


export const getDeliveryOrders = businessId => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.get(url + `open/clients/delivery-orders?page=0&size=100&statuses=SENT,APPROVED,DECLINED&businessId=${businessId}`, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                [COOKIE_NAME]: getCookie(COOKIE_NAME)
            }
        }).then(res => {
            res.data.data.sort((a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime));
            dispatch({ type: constants.GET_DELIVERY_ORDERS, payload: res.data.data });
            return res;
        });
    }
}

