import React from "react";
import {connect} from "react-redux";
import styles from "./LayoutModal.module.scss";
import {closePopup, openPopup, setLayoutType} from "../../../actions/client";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";
import {ReactComponent as ArrowLeftIcon} from "../../../assets/icons/left.svg";
import {CLIENT_MODAL_TYPES, LAYOUT_TYPES} from "../../../utils/constants";
import Checkbox from "../../Checkbox/Checkbox";
import {useTranslation} from "react-i18next";
import {ReactComponent as Grid} from "../../../assets/icons/6-dots.svg";
import {ReactComponent as List} from "../../../assets/icons/list-view-2.svg";
import {ReactComponent as UKIcon} from "../../../assets/icons/united-kingdom.svg";

const LayoutModal = ({openPopup, closePopup, business, layout_type, setLayoutType}) => {

    const {t, i18n} = useTranslation();

    return (
        <div className={styles.languageModal}>
            <div className="d-flex justify-content-between">
                <div className="big-title">
                    <button
                        onClick={() => openPopup(CLIENT_MODAL_TYPES.SETTINGS)}
                        className="client-icon-button mr-3">
                        <ArrowLeftIcon/>
                    </button>
                    {t("Layout")}
                </div>
                <button
                    onClick={closePopup}
                    className="client-icon-button">
                    <CloseIcon/>
                </button>
            </div>

            <div className="list-menu">
                <li onClick={() => {
                    setLayoutType(LAYOUT_TYPES.GRID);
                    closePopup();
                }}>
                    <span><Grid className="mr-4" /> {t("Grid")}</span>
                    <Checkbox checked={layout_type === LAYOUT_TYPES.GRID} />
                </li>
                <li onClick={() => {
                    setLayoutType(LAYOUT_TYPES.LIST);
                    closePopup();
                }}>
                    <span><List className="mr-4" /> {t("List")}</span>
                    <Checkbox checked={layout_type === LAYOUT_TYPES.LIST} />
                </li>
            </div>

        </div>
    )
}

const mapStateToProps = state => ({
    business: state.client.business,
    layout_type: state.client.layout_type
});
const mapDispatchToProps = dispatch => ({
    setLayoutType: (type) => dispatch(setLayoutType(type)),
    openPopup: (type) => dispatch(openPopup(type)),
    closePopup: () => dispatch(closePopup()),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(LayoutModal);

