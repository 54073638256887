import { createConfirmation } from 'react-confirm';
import ConfirmationDialog from "./ConfirmationDialog";

// create confirm function
const confirmationHOC = createConfirmation(ConfirmationDialog);

// This is optional. But wrapping function makes it easy to use.
export default function confirm(confirmation, options = {}) {
    return confirmationHOC({ confirmation, options });
}
