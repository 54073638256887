import React from "react";
import styles from "./Quantity.module.scss";
import Button, {ButtonTypes} from "../Button/Button";
import {ReactComponent as MinusIcon} from "../../assets/icons/minus.svg";
import {ReactComponent as PlusIcon} from "../../assets/icons/plus-1.svg";
import classNames from "classnames";

const Quantity = ({quantity, inverse, small, onMinusButtonClick, onPlusButtonClick}) => (
    <div className={classNames(styles.quantity, {[styles.inverse]: inverse, [styles.small]: small})}>
        <Button type={ButtonTypes.Icon} onClick={onMinusButtonClick}>
            <MinusIcon />
        </Button>
        <span className={styles.quantityNumber}>{quantity}</span>
        <Button type={ButtonTypes.Icon} onClick={onPlusButtonClick}>
            <PlusIcon />
        </Button>
    </div>
)

export default Quantity;
