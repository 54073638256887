import React, {useState} from "react";
import "./Subscribe.scss";
import axios from "axios";
import {errorToast, successToast} from "../../../utils";
import {useTranslation} from "react-i18next";

const Subscribe = () => {
    
    const [email, setEmail] = useState("");
    const { t, i18n } = useTranslation();
    
    const handleSubmit = () => {
        const url = "https://menyja.us7.list-manage.com/subscribe/post?u=eff435e9f4925f5706a48d68a&amp;id=3698f32892";
        const body = [{ key: 'EMAIL', value: email }];
    
        if(!email) {
            return;
        }
    
        const hiddenIFrame = document.createElement('iframe');
        hiddenIFrame.style.display = 'none';
    
        const hiddenForm = document.createElement('form');
        hiddenForm.setAttribute("action", url);
        hiddenForm.setAttribute("method", "post");
    
        body.forEach(({ key, value }) => {
            const hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('name', key);
            hiddenInput.setAttribute('value', value);
            hiddenInput.setAttribute('type', 'hidden');
            hiddenForm.appendChild(hiddenInput);
        });
    
        document.body.appendChild(hiddenIFrame);
        hiddenIFrame.contentDocument.body.appendChild(hiddenForm);
    
        return new Promise((resolve) => {
            hiddenIFrame.addEventListener('load', resolve);
            hiddenIFrame.addEventListener('load', () => document.body.removeChild(hiddenIFrame));
            hiddenForm.submit();
        }).then(res => {
            setEmail("");
            successToast(t("Thanks for registering"));
        });
    }
    
    return (
        <section id="subscribe">
            <div className="title">
                {t("Subscribe to get updates")}
            </div>
            
            <div className="subscribe-wrapper">
                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
                <button onClick={() => handleSubmit()}>{t("Subscribe")}</button>
            </div>
        </section>
    )
}

export default Subscribe;
