import axios from "axios";
import * as constants  from "../utils/constants";

const url = process.env.REACT_APP_BACKEND_URL;

export const register = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.post(url + "tokens", {
            value: data
        },{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            dispatch({ type: constants.FIREBASE_REGISTER, payload: data });
            return res;
        });
    }
}

export const unregister = data => {
    const token = localStorage.getItem("access_token");
    const firebase_token = localStorage.getItem("firebase_token");
    return function(dispatch) {
        if(!firebase_token || Notification.permission !== "granted") return;
        return axios.post(url + "tokens/unregister", {
            value: firebase_token
        },{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            console.log(res);
            dispatch({ type: constants.FIREBASE_UNREGISTER, payload: data });
            return res;
        });
    }
}

