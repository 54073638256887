import React from "react";
import {useTranslation, withTranslation} from "react-i18next";
import styles from "./PostCard.module.scss";
import {BLANK_IMAGE} from "../../../utils/constants";
import Button, {ButtonTypes} from "../../Button/Button";
import {ReactComponent as ArrowRightIcon} from "../../../assets/icons/right.svg";
import classNames from "classnames";
import {getTitleByLanguage} from "../../../utils";
import {useSelector} from "react-redux";

const PostCard = ({post, className, onClick}) => {

    const {t, i18n} = useTranslation();
    const image = post.thumbnail || post.pictureUrl;
    const business = useSelector(state => state.client.business);

    return (
        // isTimeBetween(event.startTime, event.endTime) &&
        <div className={classNames(styles.postCard, className)}
             onClick={onClick}>
            <div className={styles.postCardImage}
                 style={{backgroundImage: "url('" + (image || BLANK_IMAGE) + "')"}}/>
            <div className={styles.postCardcontent}>
                <p className={styles.postCardTitle}>{getTitleByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, post)}</p>
            </div>
            <Button
                type={ButtonTypes.Icon}>
                <ArrowRightIcon/>
            </Button>
        </div>
    )
}

export default withTranslation()(PostCard);
