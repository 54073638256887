import React, {useEffect} from "react";
import styles from "./Time.module.scss";
import {TimePicker} from "antd";
import moment from "moment";
import classNames from "classnames";
import {InputStyles} from "../Input";

export default function Text(props) {

    useEffect(() => {
        console.log(props.value);
    })

    const {
        id,
        name,
        type,
        value,
        style,
        className,
        placeholder,
        onChange,
        onBlur
    } = props;

    const classes = classNames(styles.timeInput, className,
            {[styles.materialTimeInput]: style === InputStyles.Material});


    return (
        <TimePicker
            id={id}
            name={name}
            format={"HH:mm"}
            className={classes}
            placeholder={placeholder}
            onBlur={onBlur}
            value={value ? moment(value, 'HH:mm') : moment("00:00:00", 'HH:mm')}
            defaultValue={value ? moment(value, 'HH:mm') : moment("00:00:00", 'HH:mm')}
            onChange={(time) => {
                onChange(time ? time.format("HH:mm") + ":00" : "")
            }}
            onSelect={(time) => {
                onChange(time ? time.format("HH:mm") + ":00" : "")
            }}/>
    )
}
