import React from "react";
import ReactDrawer from "rc-drawer";
import "./Drawer.scss";

const Drawer = ({children, open}) => {

    return (
        <ReactDrawer
            onClose={() => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }}
            open={open}
            level={null}
            handler={false}
            placement="right"
            getContainer={document.querySelector(".wrapper")}>
            {children}
        </ReactDrawer>
    )
}

export default Drawer;
