import React, {Component} from "react";
import styles from "./Product.module.scss";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    addProductToWishlist, decreaseQuantityOfWishlistProduct, getDescriptionByLanguage, getNameByLanguage,
    getProductOnMenu, getProductStarTypes, getProductStarTypesClient, getProductWishlistQuantity,
    getRatingByProductId,
    getSubmenuOfProduct, increaseQuantityOfWishlistProduct,
    isAddedToWishlist,
    removeProductFromWishlist
} from "../../../utils";
import {openModal, rateProduct, setImage, setProduct, updateWishlist} from "../../../actions/client";
import {CLIENT_MODAL_TYPES} from "../../../utils/constants";
import {Carousel} from "react-responsive-carousel";
import Dots from "../../../components/Dots/Dots";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../../components/Button/Button";
import {ReactComponent as PlusIcon} from "../../../assets/icons/plus-1.svg";
import {ReactComponent as MinusIcon} from "../../../assets/icons/minus.svg";
import {ReactComponent as HeartIcon} from "../../../assets/icons/favorite.svg";
import Quantity from "../../../components/Quantity/Quantity";
import Stars from "../../../components/Stars/Stars";
import classNames from "classnames";
import ImgsViewer from "react-images-viewer";
import ImageViewer from 'react-simple-image-viewer';

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product: null,
            submenu: null,
            button_animation: false,
            rating_animation: false,
            rating: 0,
            rated: false,
            name: "",
            comment: "",
            selected_product: 0,
            currentImage: null,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const product_id = this.props.match.params.productId;
        const {menus, ratings} = this.props;
        const product = getProductOnMenu(menus, product_id);
        const submenu = getSubmenuOfProduct(menus, product_id);
        const rating = getRatingByProductId(ratings, product_id);
        if (rating) {
            this.setState({rated: true});
        }
        this.setState({product, submenu, rating});
    }

    handleWishlistButtonClick = (product) => {
        if (isAddedToWishlist(product)) {
            removeProductFromWishlist(product)
        } else {
            addProductToWishlist(product)
        }
        this.handleButtonAnimation();
        this.props.updateWishlist();
    }

    handleButtonAnimation = () => {
        this.setState({button_animation: true},
            () => {
                setTimeout(() => {
                    this.setState({button_animation: false});
                }, 500);
            });
    }

    handleRatingAnimation = () => {
        this.setState({rating_animation: true},
            () => {
                setTimeout(() => {
                    this.setState({rating_animation: false});
                }, 500);
            });
    }

    handleProductImageClick = (image) => {
        const {setImage, openModal} = this.props;
        setImage(image);
        openModal(CLIENT_MODAL_TYPES.IMAGE);
    }

    increaseProductQuantity = (product) => {
        increaseQuantityOfWishlistProduct(product)
        this.props.updateWishlist();
    }

    decreaseProductQuantity = (product) => {
        decreaseQuantityOfWishlistProduct(product);
        this.props.updateWishlist();
    }

    onWishlistButtonClick = (product) => {
        if (isAddedToWishlist(product)) {
            removeProductFromWishlist(product);
        } else {
            addProductToWishlist(product);
        }
        this.props.updateWishlist();
    }

    render() {
        const {product, submenu, selected_product, currentImage} = this.state;
        const {t, i18n, ratings, is_ordering_enabled, is_delivery_enabled, business} = this.props;
        const rating = ratings ? ratings.find(rate => rate.product?.id == product?.id) : null;

        return (
            !!(product && submenu) &&
            <div className={styles.productDetails}>
                <Carousel
                    showStatus={false}
                    showArrows={false}
                    showThumbs={false}
                    showIndicators={false}
                    className={styles.carousel}
                    onChange={(index) => this.setState({ selected_product: index })}>
                    {product.images.map((image, index) =>
                        <div
                            onClick={(Z) => this.setState({ currentImage: index })}
                            key={image.link}
                            className={styles.sliderImage}
                            style={{backgroundImage: "url('" + image.link + "')"}}/>)}
                </Carousel>
                <Dots className="mt-16" selected={selected_product} count={product.images.length}/>

                <div className="big-title">{getNameByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, product)}</div>
                <div className="medium-title text-primary">{product.price}€</div>

                <p className="font-20 mt-16 mb-2">{is_ordering_enabled || is_delivery_enabled ? t("Order") : t("Wishlist")}</p>
                {is_ordering_enabled || is_delivery_enabled ?
                    <Quantity
                    quantity={getProductWishlistQuantity(product)}
                    onMinusButtonClick={() => this.decreaseProductQuantity(product)}
                    onPlusButtonClick={() => this.increaseProductQuantity(product)}/>
                    : <Button color={ButtonColors.White}
                              type={ButtonTypes.Icon}
                              className={classNames(styles.wishlistButton, {[styles.active]: isAddedToWishlist(product)})}
                              onClick={() => this.onWishlistButtonClick(product)}>
                        <HeartIcon/>
                    </Button>}

                <p className="font-20 mt-24 mb-2">{t("Description")}</p>
                <p className="font-17 text-tertiary">{getDescriptionByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, product)}</p>

                {!!product.preparingTime &&
                <p className="mt-24">{t("Estimated time of preparation")}</p>}
                {!!product.preparingTime &&
                <div className="d-flex align-items-end">
                    <h1>{product.preparingTime}</h1><p className="text-tertiary ml-12 mb-1">{t("minutes")}</p>
                </div>}

                <p className="font-20 mt-24 mb-12 ">{t("Rating")}</p>
                {rating ?
                    <div className={styles.stars}>
                        <Stars stars={getProductStarTypesClient(rating)}/>
                    </div>
                    : <Button
                        className={styles.ratingButton}
                        color={ButtonColors.White}
                          type={ButtonTypes.Icon}
                          onClick={() => {
                              this.props.setProduct(product);
                              this.props.openModal(CLIENT_MODAL_TYPES.RATING)
                          }}>
                    <PlusIcon/>
                </Button>}

                {currentImage !== null &&
                <ImageViewer
                    backgroundStyle={{ backgroundColor: "rgba(0,0,0, .6)" }}
                    src={product.images.map(image => image.link)}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={() => this.setState({ currentImage: null }) }
                />}

            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        menus: state.client.menus,
        wishlist: state.client.wishlist,
        ratings: state.client.ratings,
        is_ordering_enabled: state.client.is_ordering_enabled,
        is_delivery_enabled: state.client.is_delivery_enabled,
        business: state.client.business
    }
}

const mapDispatchToProps = dispatch => ({
    // addProductToWishlist: () => dispatch(),
    // removeProductFromWishlist: () => dispatch(),
    setImage: image => dispatch(setImage(image)),
    openModal: (type) => dispatch(openModal(type)),
    updateWishlist: () => dispatch(updateWishlist()),
    rateProduct: (data) => dispatch(rateProduct(data)),
    setProduct: (data) => dispatch(setProduct(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Product)));
