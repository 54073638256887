import axios from 'axios';
import { toast } from "react-toastify";
import {logout} from "./actions/user";
import {unregister} from "./actions/notifications";
import {setLoading} from "./actions";
import i18n from "./i18n";

export const setupInterceptors = (store) => {
    // Add a response interceptor

    axios.interceptors.request.use(function (config) {
        // spinning start to show
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {

        if(error.response && (error.response.status == 401 || error.response.status == 403)) {
            store.dispatch(logout());
        }

        store.dispatch(setLoading(false));

        let error_text =  error.response && error.response.data && error.response.data.error ? error.response.data.error : "Error!";

        if(error_text === "unauthorized") {
            error_text = i18n.t("invalid-credentials-message");
        }

        const path = window.location.pathname;
        if(!path.includes("/menu/") && !path.includes("/menu?") && !path.includes("/search")) {
            toast.error(error_text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        return Promise.reject(error);
    });

}
