import React, {Component} from "react";
import styles from "./DeliveryOrders.module.scss";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import DragAndDrop, {DraggableTypes} from "../../../components/Draggable/DragAndDrop";
import {withTranslation} from "react-i18next";
import Input from "../../../components/Input/Input";
import Drawer from "../../../components/Drawer/Drawer";
import {getDeliveryOrders, getOrders} from "../../../actions/orders";
import OrderDetailsSidebar from "../../../components/Sidebars/OrderDetailsSidebar/OrderDetailsSidebar";
import Button, {ButtonColors} from "../../../components/Button/Button";
import {search} from "../../../utils";
import Empty from "../../../components/Empty/Empty";
import {DELIVERY_SIDEBAR_CONTENT, ORDER_STATUSES} from "../../../utils/constants";
import DeliveryConfigurationsSidebar
    from "../../../components/Sidebars/DeliveryConfigurationsSidebar/DeliveryConfigurationsSidebar";

class DeliveryOrders extends Component {
    popUpQuestion = "A jeni të sigurt të vazhdoni?";

    constructor(props) {
        super(props);

        this.state = {
            order: null,
            isSidebarOpen: false,
            sidebarContent: DELIVERY_SIDEBAR_CONTENT.CONFIGURATIONS
        }
    }

    componentDidMount() {
        this.props.getDeliveryOrders();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {user_details} = this.props;
        if(user_details && user_details.configuration.enableDelivery === false) {
            this.props.history.push("/menus");
        }
    }

    openSidebar = () => {
        this.setState({isSidebarOpen: true});
    }

    closeSidebar = () => {
        this.setState({isSidebarOpen: false});
    }

    openOrder = (order) => {
        this.setState({
            sidebarContent: DELIVERY_SIDEBAR_CONTENT.ORDER_DETAILS,
            order: order,
            isSidebarOpen: true,
        })
    }

    openDeliveryConfigurationsSidebar = () => {
        this.setState({
            sidebarContent: DELIVERY_SIDEBAR_CONTENT.CONFIGURATIONS,
            isSidebarOpen: true,
        })
    }

    render() {
        const {search_text, isSidebarOpen, order, sidebarContent} = this.state;
        const {delivery_orders_fetched, user_details, t} = this.props;
        const delivery_orders = search(this.props.delivery_orders, search_text, "orderNumber");

        return (
            <div className="orders-page">

                <div className="page-header-row">
                    <h1>{t("Delivery Orders")}</h1>
                    <div className="d-flex">
                        <Button
                            color={ButtonColors.Green}
                            onClick={() => this.openDeliveryConfigurationsSidebar()}>
                            + {t("Configurations")}
                        </Button>
                    </div>
                </div>

                <div className="page-search-row">
                    <Input
                        type="text"
                        id="searchTablesInput"
                        name="searchTablesInput"
                        placeholder={t("Search for orders")}
                        value={search_text}
                        onChange={(e) => this.setState({search_text: e.target.value})}/>
                </div>

                <div className={styles.listHeader}>
                    <div className={styles.column}>
                        {t("Order ID")}
                    </div>
                    <div className={styles.column}>
                        {t("Table")}
                    </div>
                    <div className={styles.column}>
                        {t("Date and time")}
                    </div>
                    <div className={styles.column}>
                        {t("Status")}
                    </div>
                    <div className={styles.column}>
                        {t("Total items")}
                    </div>
                    <div className={styles.column}>
                        {t("Total price")}
                    </div>
                </div>
                <div className="box-body">

                    {delivery_orders_fetched ?
                        <>
                            {delivery_orders.length > 0 ?
                                <DragAndDrop
                                    isDragDisabled
                                    items={delivery_orders}
                                    type={DraggableTypes.ORDER}
                                    onClickItem={(item, e) => this.openOrder(item, e)}
                                    onDragEnd={(result) => this.onDragEnd(result)}/>
                                    : <div className="mt-94 text-center">
                                            <Empty />
                                            <p className="mt-16">{t("No orders to display!")}</p>
                                        </div>}
                        </>
                        : <div className="text-center py-3">
                            <div className="spinner-border text-info"/>
                        </div>
                    }

                </div>

                <Drawer
                    open={isSidebarOpen}>
                    {(order && sidebarContent === DELIVERY_SIDEBAR_CONTENT.ORDER_DETAILS) &&
                    <OrderDetailsSidebar
                        isDelivery
                        order={order}
                        completed={order && (order.status !== ORDER_STATUSES.SENT)}
                        updateOrders={() => this.props.getDeliveryOrders()}
                        onCloseButtonClick={() => this.closeSidebar()}/>}

                    {(sidebarContent === DELIVERY_SIDEBAR_CONTENT.CONFIGURATIONS && user_details?.configuration) &&
                    <DeliveryConfigurationsSidebar
                        onCloseButtonClick={() => this.closeSidebar()}/>}
                </Drawer>

            </div>
        );
    }

}

const mapStateToProps = state => ({
    delivery_orders: state.app.delivery_orders,
    delivery_orders_fetched: state.app.delivery_orders_fetched,
    user_details: state.app.user_details
});
const mapDispatchToProps = dispatch => ({
    getDeliveryOrders: () => dispatch(getDeliveryOrders())
});
const translation = withTranslation();
const redux = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(redux(translation(DeliveryOrders)));
